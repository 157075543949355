import * as actionTypes from '../types/actions/incubation-time-actions';

/**
 * Set new first symptoms date
 *
 * @param newDate New first symptoms date
 */
export function setSymptomsDate(newDate: Date | null): actionTypes.SetSymptomsDateAction {
  return {
    type: actionTypes.SET_SYMPTOMS_DATE,
    payload: { date: newDate },
  };
}
