import { DialogContent, DialogContentText } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import { useTranslation } from 'react-i18next';
import styles from './privacy-dialog.css';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers';
import { setPrivacyDialogVisible } from '../../actions/privacy-dialog-actions';

const PrivacyDialog: FunctionComponent = () => {
  const { t } = useTranslation();
  const isOpen = useSelector((state: RootState) => state.privacyDialog);
  const dispatch = useDispatch();

  return (
    <ConfirmDialog
      maxWidth="md"
      open={isOpen}
      handleClose={(): void => {
        dispatch(setPrivacyDialogVisible(false));
      }}
      dialogTitle={t('privacyPolicyDialog.title')}
      agree={{
        onAgree: (): void => {
          dispatch(setPrivacyDialogVisible(false));
        },
        text: t('startPage.privacyPolicy.agreePrivacyPolicy'),
      }}
    >
      <DialogContent className={styles.container}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('privacyPolicyDialog.content', { interpolation: { escapeValue: false } }),
          }}
        />
      </DialogContent>
    </ConfirmDialog>
  );
};

export default PrivacyDialog;
