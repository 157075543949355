import React, { FunctionComponent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addTimelineEntry, setEditingTimelineEntry } from '../../actions/timeline-actions';

import AddLocationButton from '../add-location-button/add-location-button';
import AddLocationCompactButton from '../add-location-compact-button/add-location-compact-button';

import PlaceVisitForm from '../place-visit-form/place-visit-form';

import PlaceVisit from '../../types/place-visit';
import { TimelineEntryType } from '../../types/timeline';
import { ActivitySegmentStatusType } from '../../types/activity-segment-status';
import { RootState } from '../../reducers';
import { nanoid } from 'nanoid';
import { startOfDay } from 'date-fns/esm';

interface Props {
  mode?: 'compact' | 'default';
  status: ActivitySegmentStatusType;
}

/**
 * Component showing the edit mode of a place visit
 */
const PlaceVisitAdd: FunctionComponent<Props> = ({ mode, status }: Props) => {
  const dispatch = useDispatch();
  const { selectedDate, editingEntryId } = useSelector((state: RootState) => ({
    selectedDate: state.timeline.selectedDate,
    editingEntryId: state.timeline.editingEntry?.id,
  }));

  const barePlaceVisit = useMemo(
    () => ({
      type: TimelineEntryType.PlaceVisit,
      id: nanoid(),
      time: {
        start: startOfDay(selectedDate ? selectedDate : new Date()),
        end: startOfDay(selectedDate ? selectedDate : new Date()),
      },
    }),
    [selectedDate],
  );

  const addingIsDisabled = useMemo(() => {
    return editingEntryId ? editingEntryId !== barePlaceVisit.id : false;
  }, [editingEntryId, barePlaceVisit]);

  /**
   * Handle the cancelling of an edit
   */
  const handleAddClick = (): void => {
    dispatch(setEditingTimelineEntry(barePlaceVisit));
  };

  /**
   * Save changes of a place visit edit
   */
  const handleSave = (placeVisit: Omit<PlaceVisit, 'id'>): void => {
    dispatch(addTimelineEntry(placeVisit));
    dispatch(setEditingTimelineEntry(null));
  };

  /**
   * Handle the cancelling of an edit
   */
  const handleCancel = (): void => {
    dispatch(setEditingTimelineEntry(null));
  };

  if (barePlaceVisit.id === editingEntryId) {
    return <PlaceVisitForm scrollIntoViewOnMount={true} onSave={handleSave} onCancel={handleCancel} status={status} />;
  }

  if (mode === 'compact') {
    return <AddLocationCompactButton disabled={addingIsDisabled} onClick={handleAddClick} status={status} />;
  }

  return <AddLocationButton disabled={addingIsDisabled} onClick={handleAddClick} />;
};

export default PlaceVisitAdd;
