import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import HelpPageStep from '../help-page-step/help-page-step';

import ScreenShotMobile from '../../../../assets/images/help-page/send-location-history-mobile.png';
import ScreenShot from '../../../../assets/images/help-page/send-location-history.png';
import TransferMethod from '../../../../assets/images/help-page/transfer-method.png';
import Export from '../../../../assets/images/help-page/export.png';
import LinkViaMail from '../../../../assets/images/help-page/link-via-mail.png';
import CreateExport from '../../../../assets/images/help-page/create-export.png';

import styles from './select-export-option-section.css';

const SelectExportOptionsSection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box component="section" display="flex" flexDirection="column" className={styles.container}>
      <HelpPageStep
        step={3}
        title={t('helpPage.selectExportOption.title')}
        description={t('helpPage.selectExportOption.description')}
      />
      <div className={styles.imageContainer}>
        <picture>
          <source media="(max-width: 749px)" srcSet={`${ScreenShotMobile} 300w`} />
          <source media="(min-width: 750px)" srcSet={`${ScreenShot} 800w`} />
          <img
            className={styles.image}
            alt="Screenshot of a dialog box on the Google Takeout website showing different export options"
          />
        </picture>
        <img
          className={clsx(styles.highlightedScreenshotPart, styles.imageLinkViaMail)}
          src={LinkViaMail}
          alt="Send link via E-mail option on the Google Takeout page"
        />
        <div className={styles.svgContainer}>
          {/* container to size the svg image responsive in all browsers */}
          <div className={styles.svgFrame}>
            <ArrowRightAltIcon className={styles.icon} />
          </div>
        </div>
        <img
          className={clsx(styles.highlightedScreenshotPart, styles.imageTransferMethod)}
          src={TransferMethod}
          alt="Screenshot of different transfer methods on the Google Takeout page"
        />
        <img
          className={clsx(styles.highlightedScreenshotPart, styles.imageExport)}
          src={Export}
          alt="Screenshot of Google Takeout options to select between a one-time or a recurring data export"
        />
        <img
          className={clsx(styles.highlightedScreenshotPart, styles.imageCreateExport)}
          src={CreateExport}
          alt="Screenshot of a 'create export' button on the Google Takeout website"
        />
      </div>
    </Box>
  );
};

export default SelectExportOptionsSection;
