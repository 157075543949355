/**
 * The activity types
 */
enum ActivityType {
  Car = 'CAR',
  PublicTransport = 'PUBLIC_TRANSPORT',
  CyclingWalking = 'CYCLING/WALKING',
}

export default ActivityType;
