import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';

import PredefinedPlace, { PredefinedPlaceType } from '../../../../types/predefined-place';

import clsx from 'clsx';

import styles from './predefined-place-option.css';

interface Props {
  predefinedPlace: PredefinedPlace;
  onEdit?(predefinedPlace: PredefinedPlace): void;
}

const PredefinedPlaceOption: FunctionComponent<Props> = ({ predefinedPlace, onEdit }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isTypeHome = predefinedPlace.type === PredefinedPlaceType.Home;
  const isTypeWork = predefinedPlace.type === PredefinedPlaceType.Work;
  const hasPlace = predefinedPlace.place;

  return (
    <Grid container classes={{ root: styles.container }}>
      <Grid className={clsx(!hasPlace && styles.disabled)} item>
        {isTypeHome && <HomeIcon className={styles.icon} />}
        {isTypeWork && <WorkIcon className={styles.icon} />}
      </Grid>
      <Grid className={clsx(styles.titleContainer, !hasPlace && styles.disabled)} item xs>
        <span style={{ fontWeight: 700 }}>
          {isTypeHome && t('placeVisit.home')}
          {isTypeWork && t('placeVisit.work')}
        </span>
        {predefinedPlace.place?.address && (
          <Typography color="textSecondary" noWrap>
            {predefinedPlace.place?.address}
          </Typography>
        )}
      </Grid>
      {onEdit && (
        <Button
          variant="text"
          className={styles.editButton}
          onClick={(event): void => {
            event.stopPropagation();
            onEdit(predefinedPlace);
          }}
        >
          {predefinedPlace.place ? t('edit') : t('create')}
        </Button>
      )}
    </Grid>
  );
};

export default PredefinedPlaceOption;
