import * as actionTypes from '../types/actions/privacy-dialog-actions';

export const SET_PRIVACY_DIALOG_VISIBLE = 'privacyDialog/SET_DIALOG_VISIBLE';

/**
 * Set state for the visibility of the global privacy dialog
 *
 * @param visible Whether the dialog is visible or not
 */
export function setPrivacyDialogVisible(visible: boolean): actionTypes.SetPrivacyDialogVisibleAction {
  return {
    type: actionTypes.SET_PRIVACY_DIALOG_VISIBLE,
    payload: { visible },
  };
}
