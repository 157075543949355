import ActionTypes from '../types/actions';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../types/actions/notifications-actions';
import { IMPORT_TIMELINE } from '../types/actions/timeline-actions';

import Notification, {
  NotificationType,
  importFileErrorNotificationId,
  emptyImportNotificationId,
} from '../types/notification';

export type NotificationsState = Notification[];

const initialState: NotificationsState = [];

/**
 * Handle the notifications state
 */
function notificationsReducer(state: NotificationsState = initialState, action: ActionTypes): NotificationsState {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return [...state, action.payload.notification];
    }

    case IMPORT_TIMELINE: {
      if (action.payload instanceof Error) {
        const newNotification: Notification = {
          id: importFileErrorNotificationId,
          messageKey: 'timelineEntries.importFileError.notReadable',
          type: NotificationType.Error,
          dontShowNotifier: true,
        };
        return [...state, newNotification];
      }

      if (action.payload.data.length === 0) {
        const newNotification: Notification = {
          id: emptyImportNotificationId,
          messageKey: 'timelineEntries.emptyImportError.noEntries',
          type: NotificationType.Error,
          dontShowNotifier: true,
        };
        return [...state, newNotification];
      }

      return state;
    }

    case REMOVE_NOTIFICATION: {
      return state.filter(({ id }) => id !== action.payload.id);
    }

    default:
      return state;
  }
}

export default notificationsReducer;
