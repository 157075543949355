import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import TimelineNotification from './timeline-notification';

import { removeNotification } from '../../../../actions/notifications-actions';
import { importFileErrorNotificationId } from '../../../../types/notification';

/**
 * A message on file import errors in the timeline
 */
const TimelineNotificationFileError: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const remove = useCallback(() => {
    dispatch(removeNotification(importFileErrorNotificationId));
  }, []);

  const importAgainLink = useMemo(
    () => (
      <Link onClick={remove} to="/configuration/location-history">
        import
      </Link>
    ),
    [],
  );
  const message = useMemo(
    () => (
      <Trans i18nKey="timelineEntries.importFileError.tryAgainOrContinueManually">
        You can {importAgainLink} or not.
      </Trans>
    ),
    [],
  );

  return (
    <TimelineNotification title={t('timelineEntries.importFileError.notReadable')} message={message} onClose={remove} />
  );
};

export default TimelineNotificationFileError;
