import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useGoogleMap } from '@ubilabs/google-maps-react-hooks';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../reducers';
import { isPlaceVisit } from '../../types/timeline';

import getMarkerIcon from './place-visit-marker-icon';
import { updateEditingTimelineEntry } from '../../actions/timeline-actions';
import { trimAddress } from '../../utils/format-address';

/**
 * Marker for a place visit to edit
 */
const EditingMarker: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { map } = useGoogleMap();
  const [editingMarker, setEditingMarker] = useState<google.maps.Marker | null>(null);
  const { editingEntry, predefinedPlaces } = useSelector((state: RootState) => ({
    editingEntry: state.timeline.editingEntry,
    predefinedPlaces: state.predefinedPlaces,
  }));

  // Update the editing timeline entry when the marker's position changed
  const handleMarkerDragEnd = (event: google.maps.MouseEvent): void => {
    dispatch(
      updateEditingTimelineEntry({
        location: {
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
        },
      }),
    );
  };

  const removeMarker = useCallback(() => {
    if (editingMarker) {
      editingMarker.setMap(null);
      google.maps.event.clearListeners(editingMarker, 'dragend');
      setEditingMarker(null);
    }
  }, [editingMarker]);

  // Update the marker on changes
  useEffect(() => {
    if (!map) {
      return;
    }

    if (!editingEntry || !isPlaceVisit(editingEntry) || !editingEntry.location) {
      removeMarker();
      return;
    }

    const { location, address } = editingEntry;

    const position: google.maps.LatLngLiteral = {
      lat: location.latitude,
      lng: location.longitude,
    };

    const predefinedPlaceType = predefinedPlaces.find(
      (predefinedPlace) =>
        predefinedPlace.place?.address &&
        address &&
        trimAddress(predefinedPlace.place.address) === trimAddress(address),
    )?.type;

    if (!editingMarker) {
      const markerOptions: google.maps.MarkerOptions = {
        map,
        position,
        optimized: false,
        icon: getMarkerIcon('editing', predefinedPlaceType),
        draggable: true,
        clickable: false,
        zIndex: 3,
      };

      const marker = new google.maps.Marker(markerOptions);
      google.maps.event.addListener(marker, 'dragend', handleMarkerDragEnd);
      setEditingMarker(marker);
      return;
    }

    editingMarker.setPosition(position);
    editingMarker.setIcon(getMarkerIcon('editing', predefinedPlaceType));
  }, [map, editingEntry, editingMarker, predefinedPlaces]);

  // Removes marker from the map and removes marker event listener
  // when component unmounts
  useEffect(
    () => (): void => {
      if (map) {
        removeMarker();
      }
    },
    [],
  );

  return null;
};

export default EditingMarker;
