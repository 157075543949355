/**
 * Reads a file as text
 * @param file
 */
export function readFile(file: File): Promise<string> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (event): void => {
      resolve(event.target?.result as string);
    };
    reader.onerror = (event): void => {
      reject(event);
    };
    reader.readAsText(file);
  });
}
