import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import HelpPageStep from '../help-page-step/help-page-step';

import ScreenShotMobile from '../../../../assets/images/help-page/select-location-history-mobile.png';
import ScreenShot from '../../../../assets/images/help-page/select-location-history.png';
import NextStep from '../../../../assets/images/help-page/next-step.png';
import CheckBox from '../../../../assets/images/help-page/checkbox.png';

import styles from './select-location-history-section.css';

interface Props {
  onClose?: () => void;
}

const SelectLocationHistorySection: FunctionComponent<Props> = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Box component="section" display="flex" flexDirection="column" className={styles.container}>
      <section>
        <HelpPageStep
          step={2}
          title={t('helpPage.selectLocationHistory.title')}
          description={t('helpPage.selectLocationHistory.description')}
        />
        <div className={styles.imageContainer}>
          <picture>
            <source media="(max-width: 749px)" srcSet={`${ScreenShotMobile} 300w`} />
            <source media="(min-width: 750px)" srcSet={`${ScreenShot} 800w`} />
            <img
              className={styles.image}
              alt="Screenshot of how to select the location history to be exported from the Google Takeout website"
            />
          </picture>
          <img
            className={clsx(styles.highlightedScreenshotPart, styles.imageNextStep)}
            src={NextStep}
            alt="Screenshot of the next step button on the Google Takeout website"
          />
          <img className={clsx(styles.highlightedScreenshotPart, styles.imageCheckbox)} src={CheckBox} alt="checkbox" />
        </div>
      </section>
      <section className={styles.importantNoticeContainer}>
        <Box display="flex" alignItems="center">
          <InfoOutlinedIcon className={styles.importantNoticeIcon} />
          <Typography variant="h3" className={styles.importantNoticeTitle}>
            {t('helpPage.selectLocationHistory.important.title')}
          </Typography>
        </Box>

        <Typography className={styles.importantNoticeText}>
          {t('helpPage.selectLocationHistory.important.text')}
        </Typography>
        <Link to="/configuration/location-history" className={styles.importantNoticeLink} onClick={onClose}>
          {t('helpPage.selectLocationHistory.important.link')}
        </Link>
      </section>
    </Box>
  );
};

export default SelectLocationHistorySection;
