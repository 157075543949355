import React, { FunctionComponent, ReactNode, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import Person, { PersonId } from '../../types/person';

import ContactPersonChips from './contact-person-chips';
import ContactPersonEdit from '../contact-person-edit/contact-person-edit';

import { RootState } from '../../reducers';
import { getContactsByIds } from '../../selectors/contacts';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/EditOutlined';

import styles from './contacts-edit.css';

interface Props {
  contactIds: PersonId[];
  onChange: (contacts: PersonId[]) => void;
}

interface PartialName {
  name: string;
  caption: string;
}

const filter = createFilterOptions<Person | PartialName>();

// Split a name into givenName and familyName on the last space character
const splitName = (name: string): [string, string] => {
  const names = name.split(' ');

  // All names except the last one
  const givenNames = names.slice(0, -1).join(' ');

  // Only the last name
  const familyName = names.slice(-1)[0];

  return [givenNames, familyName];
};

// Get the proper label, depending whether it's an actual contact or just a partial name
const getLabelForOption = (option: Person | PartialName): string => {
  if ('caption' in option) {
    return option.caption;
  }
  return `${option.givenName} ${option.familyName}`;
};

/**
 * Component to add existing contacts or open the form to add new contacts
 */
const EditContacts: FunctionComponent<Props> = ({ contactIds, onChange }: Props) => {
  const { t } = useTranslation();

  const { allContacts, contactsOfPlace } = useSelector((state: RootState) => ({
    // All contacts -> options for the autocomplete
    allContacts: state.contacts,
    // Already assigned contacts for this place -> value for the autocomplete
    contactsOfPlace: getContactsByIds(state, contactIds),
  }));

  // A partial (or complete) contact, which might be edited or created in the modal
  const [editableContact, setEditableContact] = useState<Partial<Person> | null>(null);

  // Whether the "Contact Add/Edit" form is visible
  const [editContactOpen, setEditContactOpen] = useState(false);

  const handleChange = (selectedContacts: Person[]): void => {
    onChange(selectedContacts.map((contact) => contact.id));
  };

  useEffect(() => {
    // Show the edit modal, if there is data to edit
    if (editableContact) {
      setEditContactOpen(true);
    }
  }, [editableContact]);

  return (
    <>
      <ContactPersonEdit
        {...(editableContact && { contactPerson: editableContact })}
        open={editContactOpen}
        onDone={(): void => {
          setEditableContact(null);
          setEditContactOpen(false);
        }}
      />

      <Autocomplete
        freeSolo
        multiple
        forcePopupIcon={true}
        disableCloseOnSelect
        id="contacts-autocomplete"
        noOptionsText={t('placeVisit.noContacts')}
        options={allContacts as Array<Person | PartialName>}
        getOptionLabel={getLabelForOption}
        value={contactsOfPlace}
        onChange={(_, selectedEntries): void => {
          const partialEntry = selectedEntries.find((entry) => (entry as PartialName).caption);

          if (partialEntry) {
            // User has clicked on the "Add" entry
            const splitNameArray = splitName((partialEntry as PartialName).name);
            setEditableContact({ familyName: splitNameArray[1], givenName: splitNameArray[0] });
          } else {
            // User has clicked on any of the "regular" options
            handleChange(selectedEntries as Array<Person>);
          }
        }}
        renderInput={(params): JSX.Element => (
          <TextField {...params} variant="standard" label={t('placeVisit.contacts')} />
        )}
        filterSelectedOptions
        filterOptions={(options, params): Array<Person | PartialName> => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              caption: t('placeVisit.dropdownAddContact', { name: params.inputValue }),
              name: params.inputValue,
            });
          }
          return filtered;
        }}
        renderOption={(option): ReactNode => {
          const label = getLabelForOption(option);

          const isPartialName = 'caption' in option;
          return (
            <Box className={styles.fullWidthOption}>
              <Box className={styles.addPersonOption}>
                {isPartialName && <PersonAddIcon color="disabled" fontSize="small" className={styles.addPersonIcon} />}
                {label}
              </Box>
              {!isPartialName && (
                <Box className={styles.editButton}>
                  <IconButton
                    onClick={(event): void => {
                      event.preventDefault();
                      event.stopPropagation();
                      setEditableContact(option as Person);
                    }}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          );
        }}
        renderTags={(contacts: Array<Person | PartialName>, getTagProps): ReactNode => (
          <ContactPersonChips contacts={contacts as Array<Person>} getTagProps={getTagProps} isEdit={true} />
        )}
      />
    </>
  );
};

export default EditContacts;
