import React, { FunctionComponent } from 'react';
import WaveSvg from '../../../assets/images/wave.svg';
import styles from './wave.css';

const Wave: FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <WaveSvg />
    </div>
  );
};

export default Wave;
