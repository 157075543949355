import ActionTypes from '../types/actions';
import { SET_PREDEFINED_PLACE } from '../types/actions/predefined-places-actions';
import PredefinedPlace, { PredefinedPlaceType } from '../types/predefined-place';

export type PredefinedPlacesState = PredefinedPlace[];

const initialState = [
  { type: PredefinedPlaceType.Home, place: null },
  { type: PredefinedPlaceType.Work, place: null },
];

/**
 * Sort the entries by type
 */
const sortByType = (a: PredefinedPlace, b: PredefinedPlace): number => {
  if (a.type < b.type) {
    return -1;
  }
  if (a.type > b.type) {
    return 1;
  }
  return 0;
};

function predefinedPlaceReducer(
  state: PredefinedPlacesState = initialState,
  action: ActionTypes,
): PredefinedPlacesState {
  switch (action.type) {
    case SET_PREDEFINED_PLACE: {
      return [
        ...state.filter((predefinedPlace) => predefinedPlace.type !== action.payload.predefinedPlace.type),
        action.payload.predefinedPlace,
      ].sort(sortByType);
    }
    default:
      return state;
  }
}

export default predefinedPlaceReducer;
