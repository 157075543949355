import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import LanguageSelect from '../language-select/language-select';
import NavigationMenu from '../select-view-menu/navigation-menu/navigation-menu';
import Navigation from './navigation';

import styles from './header.css';

const Header: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const contactsSelected = location.pathname.includes('/contacts');

  const handleInfoClick = (): void => {
    if (!location.pathname.includes('/info')) {
      if (location.pathname.includes('/configuration/symptoms-date')) {
        history.push('/configuration/symptoms-date/info');
      } else if (location.pathname.includes('/configuration/location-history')) {
        history.push('/configuration/location-history/info');
      } else if (location.pathname.includes('/timeline')) {
        history.push('/timeline/info');
      }
    }
  };

  return (
    <AppBar position="static" className={styles.appBar}>
      <Toolbar className={styles.toolbar}>
        <Typography variant="h1" className={styles.title}>
          {t('app.title')}
        </Typography>
        <Typography variant="h2" className={styles.subtitle}>
          {t('app.subtitle')}
        </Typography>
        <NavigationMenu />
        {!contactsSelected && (
          <IconButton onClick={handleInfoClick} className={styles.infoIcon} size="medium">
            <InfoIcon />
          </IconButton>
        )}
        <LanguageSelect />
        <Navigation />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
