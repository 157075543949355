import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Header from './header/header';
import HeaderSegment from './header-segment/header-segment';
import SelectLocationHistorySection from './select-location-history-section/select-location-history-section';
import SelectExportOptionsSection from './select-export-option-section/select-export-option-section';
import ReceiveLocationHistorySection from './receive-location-history-section/receive-location-history-section';
import VisitTakeoutWebsiteSection from './visit-takeout-website-section/visit-takeout-website-section';

import clsx from 'clsx';

import styles from './help-page.css';

interface Props {
  isOverlay: boolean;
  onClose?: () => void;
}

const HelpPage: FunctionComponent<Props> = ({ isOverlay, onClose }: Props) => {
  const { t } = useTranslation();
  const containerClassNames = clsx(styles.container, isOverlay && styles.overlay);
  const mainContentClassNames = clsx(styles.mainContainer, isOverlay && styles.overlayContent);

  const [overlayIsOpen, setOverlayIsOpen] = useState(Boolean(isOverlay));

  const handleClose = (): void => {
    setOverlayIsOpen(false);
    onClose && onClose();
  };

  return (
    <Box className={containerClassNames} display="flex" flexDirection="column">
      {overlayIsOpen ? (
        <header className={styles.overlayHeader}>
          <Typography variant="h1" className={styles.overlayTitle}>
            {t('helpPage.title')}
          </Typography>
          <IconButton className={styles.closeButton} onClick={(): void => handleClose()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </header>
      ) : (
        <>
          <Header />
          <HeaderSegment />
        </>
      )}
      <main className={mainContentClassNames}>
        <VisitTakeoutWebsiteSection />
        <SelectLocationHistorySection onClose={onClose} />
        <SelectExportOptionsSection />
        <ReceiveLocationHistorySection />
      </main>
    </Box>
  );
};

export default HelpPage;
