import { useTranslation } from 'react-i18next';
import ActivityType from '../types/activity-type';

/**
 * Get the label for the activity segment
 */
export const getActivitySegmentLabel = (activityType: ActivityType | null): string => {
  const { t } = useTranslation();

  switch (activityType) {
    case ActivityType.Car:
      return t('activitySegment.withCar');
    case ActivityType.PublicTransport:
      return t('activitySegment.withPublicTransport');
    case ActivityType.CyclingWalking:
      return t('activitySegment.cyclingWalking');
    default:
      return t('activitySegment.noneSelected');
  }
};
