import React, { FunctionComponent, forwardRef, Ref, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';

import Wave from '../../wave/wave';

import HeroScreenMobileSvg from '../../../../assets/images/start-page/hero-screen-mobile.svg';
import HeroScreenSvg from '../../../../assets/images/start-page/hero-screen.svg';

import MapBackground from '../../../../assets/images/start-page/map.png';

import styles from './header-segment.css';
import clsx from 'clsx';
import { noImport } from '../../../configs/no-import';

interface Props {
  ref?: Ref<HTMLDivElement>;
}

const HeaderSegment: FunctionComponent<Props> = forwardRef((_: Props, ref) => {
  const { t } = useTranslation();
  const [textIsExpanded, setTextIsExpanded] = useState(false);

  const handleSetFullTextView = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setTextIsExpanded(!textIsExpanded);
  };

  const mapBackgroundImage = {
    backgroundImage: `url(${MapBackground})`,
  };

  return (
    <header ref={ref}>
      <div style={mapBackgroundImage} className={styles.layeredImg}>
        <Box display="flex" flexDirection="column" className={styles.headerContainer}>
          <Box
            display="flex"
            justifyContent="space-between"
            className={clsx(styles.heroContainer, textIsExpanded && styles.heroContainerTextIsExpanded)}
          >
            <Box
              display="flex"
              flexDirection="column"
              className={clsx(styles.heroTextContainer, textIsExpanded && styles.heroTextContainerTextIsExpanded)}
            >
              <Typography variant="h2" classes={{ h2: styles.subtitle }}>
                {t('startPage.introduction.title')}
              </Typography>

              <p className={clsx(styles.content, textIsExpanded && styles.contentTextIsExpanded)}>
                {t('startPage.introduction.collapsedContent')}
                {textIsExpanded && (
                  <span>
                    <Trans
                      i18nKey={`startPage.introduction.${noImport ? 'expandedContentNoImport' : 'expandedContent'}`}
                    />
                  </span>
                )}
              </p>
              <Link onClick={handleSetFullTextView}>
                <span>
                  {!textIsExpanded ? t('startPage.introduction.readMore') : t('startPage.introduction.readLess')}
                </span>
              </Link>
            </Box>
            <div className={clsx(styles.heroSvgContainer, textIsExpanded && styles.positionAtBottom)}>
              <Hidden xsDown>
                {/* container to size the svg image responsive in all browsers */}
                <div className={styles.svgFrame}>
                  <HeroScreenSvg className={styles.heroSvg} />
                </div>
              </Hidden>
              <Hidden smUp>
                {/* container to size the svg image responsive in all browsers */}
                <div className={styles.svgFrame}>
                  <HeroScreenMobileSvg />
                </div>
              </Hidden>
            </div>
          </Box>
        </Box>
        <Hidden xsDown>
          <Wave />
        </Hidden>
      </div>
    </header>
  );
});

HeaderSegment.displayName = 'HeaderSegment';

export default HeaderSegment;
