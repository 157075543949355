import { TimelineEntry, isActivitySegment, isPlaceVisit } from '../types/timeline';

/**
 * Sort function for two timeline entries
 */
export default function sortTimelineEntries(a: TimelineEntry, b: TimelineEntry): number {
  if (a.time.start < b.time.start) {
    return -1;
  }
  if (a.time.start > b.time.start) {
    return 1;
  }

  if (isActivitySegment(a) && isPlaceVisit(b)) {
    return -1;
  }

  if (isPlaceVisit(a) && isActivitySegment(b)) {
    return 1;
  }

  if (a.time.end < b.time.end) {
    return -1;
  }

  if (a.time.end > b.time.end) {
    return 1;
  }

  return 0;
}
