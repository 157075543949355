import React, { FunctionComponent, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle } from '@material-ui/core';

import ContactPersonEdit from '../contact-person-edit/contact-person-edit';
import ContactsList from './contacts-list/contacts-list';

import MenuTabs from '../select-view-menu/navigation-menu-tabs/navigation-menu-tabs';

import styles from './contacts-list-view.css';

/**
 * Component to show the view of the contacts list
 */
const ContactsListView: FunctionComponent = () => {
  const { t } = useTranslation();

  /**
   * Opening of the form to add contacts
   */
  const [editContactOpen, setEditContactOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);

  return (
    <>
      <MenuTabs />
      <Grid container direction="column" justify="flex-start" className={styles.container}>
        <Box component="div">
          <Typography variant="h3">{t('contactsList.title')}</Typography>
          <Box className={styles.contactsListIntroduction}>
            <Typography className={styles.text}>{t('contactsList.introductionText')}</Typography>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                className={styles.contactViewButton}
                disableElevation
                onClick={(): void => setImportDialogOpen(true)}
              >
                <Typography variant="h3">{t('contactPerson.import')}</Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={styles.contactViewButton}
                disableElevation
                onClick={(): void => setEditContactOpen(true)}
              >
                <Typography variant="h3">{t('contactPerson.addContact')}</Typography>
              </Button>
            </Box>
            <ContactPersonEdit open={editContactOpen} onDone={(): void => setEditContactOpen(false)} />
            <Dialog
              onClose={(): void => {
                setImportDialogOpen(false);
              }}
              open={importDialogOpen}
            >
              <DialogTitle>{t('contactPerson.importDialogTitle')}</DialogTitle>
            </Dialog>
          </Box>
          <Box component="div">
            <ContactsList />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default ContactsListView;
