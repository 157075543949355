import React, { FunctionComponent, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import { format } from 'date-fns';

import EditContactEntry from '../../../contact-person-edit/contact-person-edit';
import ConfirmDialog from '../../../confirm-dialog/confirm-dialog';

import { removeContactPerson } from '../../../../actions/contacts-actions';

import Person from '../../../../types/person';

import styles from './contact-list-entry.css';

interface Props {
  contact: Person;
}

/**
 * Component to show a contact in the contact list
 */
const ContactsListEntry: FunctionComponent<Props> = ({ contact }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, familyName, givenName, birthDate, address, telephone, reachabilityNote, languageNote } = contact;

  /**
   * Formatting functions
   */

  // format address function
  const formattedContactAddress = (address: Person['address']): string => {
    if (!address.street && !address.postalCode && !address.city) {
      return 'N/A';
    } else if (address.street && !address.postalCode && !address.city) {
      return `${address?.street} ${address?.houseNumber}`.trim();
    } else if (!address.street) {
      return `${address.postalCode} ${address.city}`;
    }
    return `${address.street} ${address?.houseNumber}, ${address.postalCode} ${address.city}`;
  };

  /**
   * Opening of the form to edit a contact entry
   */
  const [editContactOpen, setEditContactOpen] = useState(false);

  /**
   * Handle delete contact entry
   */
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDeleteClick = useCallback((): void => {
    setShowDeleteConfirm(true);
  }, []);

  const handleDeleteConfirm = (): void => {
    dispatch(removeContactPerson(id));
  };

  return (
    <Card className={styles.contactEntry} classes={{ root: styles.contactEntry }} elevation={0}>
      <CardContent className={styles.contactEntryContent} classes={{ root: styles.contactEntryContent }}>
        <Grid container justify="space-evenly" alignItems="center" spacing={2}>
          <Grid container item xs={6} sm={2} alignItems="center">
            <Typography>{`${givenName} ${familyName}`}</Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography>{(birthDate && format(birthDate, 'dd.MM.yyyy')) || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography>{formattedContactAddress(address) || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography>{telephone?.main || telephone?.secondary || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography>{reachabilityNote || 'N/A'} </Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography>{languageNote || 'N/A'} </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <IconButton className={styles.editIconButton} onClick={(): void => setEditContactOpen(true)}>
          <EditIcon />
        </IconButton>
        <EditContactEntry
          contactPerson={contact}
          open={editContactOpen}
          onDone={(): void => setEditContactOpen(false)}
        />
        <IconButton className={styles.deleteIconButton} onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>

        <ConfirmDialog
          open={showDeleteConfirm}
          handleClose={(): void => setShowDeleteConfirm(false)}
          dialogTitle={t('contactsList.deleteContact')}
          dialogContentText={t('contactsList.deleteText')}
          agree={{ onAgree: handleDeleteConfirm, text: t('delete') }}
          disagree={{ onDisagree: (): void => setShowDeleteConfirm(false), text: t('cancel') }}
        />
      </CardActions>
    </Card>
  );
};

export default ContactsListEntry;
