export const SET_PRIVACY_DIALOG_VISIBLE = 'privacyDialog/SET_DIALOG_VISIBLE';

export interface SetPrivacyDialogVisibleAction {
  type: typeof SET_PRIVACY_DIALOG_VISIBLE;
  payload: {
    visible: boolean;
  };
}

export type PrivacyDialogActionTypes = SetPrivacyDialogVisibleAction;

export default PrivacyDialogActionTypes;
