import ActionTypes from '../types/actions';
import { SET_SYMPTOMS_DATE } from '../types/actions/incubation-time-actions';
import { createIncubationTimeState } from '../utils/incubation-time';

export type IncubationTimeState = {
  symptomsDate: Date | null;
  start: Date | null;
  end: Date | null;
};

const initialState = createIncubationTimeState(new Date());

function incubationTimeReducer(state: IncubationTimeState = initialState, action: ActionTypes): IncubationTimeState {
  switch (action.type) {
    case SET_SYMPTOMS_DATE: {
      const { date } = action.payload;
      return createIncubationTimeState(date);
    }
    default:
      return state;
  }
}

export default incubationTimeReducer;
