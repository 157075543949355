import React, { FunctionComponent, forwardRef, Ref, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateTimelineEntry, setEditingTimelineEntry } from '../../../actions/timeline-actions';
import { RootState } from '../../../reducers';

import ActivitySegmentForm from '../activity-segment-form/activity-segment-form';
import ActivitySegmentType from '../activity-segment-type/activity-segment-type';
import ActivitySegment from '../../../types/activity-segment';

interface Props {
  selectedActivitySegment: ActivitySegment;
  ref?: Ref<HTMLDivElement>;
}

/**
 * Component showing the edit mode of an activity segment
 */
const ActivitySegmentEdit: FunctionComponent<Props> = forwardRef(({ selectedActivitySegment }: Props, ref) => {
  const dispatch = useDispatch();
  const { editingEntry } = useSelector((state: RootState) => ({
    editingEntry: state.timeline.editingEntry,
  }));
  const editingActivitySegment = useRef<Partial<ActivitySegment | null>>(null);

  /**
   * Save changes of an activity segment edit
   */
  const handleSave = (activitySegment: Omit<ActivitySegment, 'id'>): void => {
    dispatch(updateTimelineEntry(selectedActivitySegment.id, activitySegment));
    dispatch(setEditingTimelineEntry(null));
  };

  /**
   * Handle the cancelling of an activity segment edit
   */
  const handleCancel = (): void => {
    editingActivitySegment.current = null;
    dispatch(setEditingTimelineEntry(null));
  };

  /**
   * Update the editingActivitySegment ref when editingEntry changes
   */
  useEffect(() => {
    editingActivitySegment.current = editingEntry;
  }, [editingEntry]);

  return (
    <>
      <ActivitySegmentType ref={ref} activitySegment={selectedActivitySegment} />
      <ActivitySegmentForm onSave={handleSave} onCancel={handleCancel} />
    </>
  );
});

ActivitySegmentEdit.displayName = 'ActivitySegmentEdit';

export default ActivitySegmentEdit;
