import React, { FunctionComponent, Fragment, createRef, Ref, useEffect, RefObject } from 'react';
import { useSelector } from 'react-redux';

import { selectTimelineEntriesBySelectedDate } from '../../../selectors/timeline';
import {
  selectImportFileErrorNotification,
  selectEmptyImportErrorNotification,
} from '../../../selectors/notifications';

import usePrevious from '../../../hooks/use-previous-state';

import PlaceVisitAdd from '../../place-visit-add/place-visit-add';
import PlaceVisitEdit from '../../place-visit-edit/place-visit-edit';
import PlaceVisitView from '../../place-visit-view/place-visit-view';
import PlaceVisitSkeleton from '../../place-visit-skeleton/place-visit-skeleton';

import ActivitySegment from '../../activity-segment/activity-segment';
import ActivitySegmentAdd from '../../activity-segment/activity-segment-add/activity-segment-add';

import TimelineNotificationFileError from './timeline-notification/timeline-notification-file-error';
import TimelineNotificationEmptyImport from './timeline-notification/timeline-notification-empty-import';

import { RootState } from '../../../reducers';
import { isPlaceVisit, isActivitySegment } from '../../../types/timeline';
import { ActivitySegmentStatusType } from '../../../types/activity-segment-status';
import PlaceVisit from '../../../types/place-visit';

import styles from './timeline-entries.css';

/**
 * Component showing multiple place visits and activity segments – either in editable or non-editable view
 */
const TimelineEntries: FunctionComponent = () => {
  const {
    timelineEntriesBySelectedDate,
    editingEntryId,
    timelineIsLoading,
    importFileError,
    emptyImportError,
  } = useSelector((state: RootState) => ({
    timelineEntriesBySelectedDate: selectTimelineEntriesBySelectedDate(state),
    editingEntryId: state.timeline.editingEntry?.id,
    timelineIsLoading: state.timeline.isLoading,
    importFileError: selectImportFileErrorNotification(state),
    emptyImportError: selectEmptyImportErrorNotification(state),
  }));

  const timelineIsEmpty = timelineEntriesBySelectedDate.length === 0;
  const previousTimelineIds = usePrevious(timelineEntriesBySelectedDate.map(({ id }) => id));

  // Save a reference to the DOM nodes of the timeline entries
  const placeVisitElements = new Map<string, Ref<HTMLDivElement>>(
    timelineEntriesBySelectedDate.map(({ id }) => [id, createRef<HTMLDivElement>()]),
  );

  /**
   * Scrolls sidebar to a newly added place Visit
   */
  const scrollNewPlaceVisitIntoView = (): void => {
    const newEntry = timelineEntriesBySelectedDate.find(({ id }) => !previousTimelineIds.includes(id));
    const isNewlyAddedPlaceVisit = newEntry && isPlaceVisit(newEntry) && Boolean(previousTimelineIds.length);
    if (isNewlyAddedPlaceVisit) {
      // Grab DOM node of newly added entry
      const newEntryElement = placeVisitElements.get((newEntry as PlaceVisit).id) as RefObject<HTMLDivElement>;
      const element = newEntryElement?.current;

      if (element) {
        element.scrollIntoView();
      }
    }
  };

  useEffect(scrollNewPlaceVisitIntoView, [timelineEntriesBySelectedDate, previousTimelineIds]);

  if (timelineIsEmpty) {
    return (
      <div className={styles.timelineContainer}>
        {!timelineIsLoading && importFileError && <TimelineNotificationFileError />}
        {!timelineIsLoading && emptyImportError && <TimelineNotificationEmptyImport />}
        <div className={styles.timelineEntry}>
          {timelineIsLoading ? <PlaceVisitSkeleton /> : <PlaceVisitAdd status={ActivitySegmentStatusType.None} />}
        </div>
      </div>
    );
  }

  let previousTimelineEntryWasPlaceVisit = false;
  const lastTimelineIndex = timelineEntriesBySelectedDate.length - 1;

  return (
    <div className={styles.timelineContainer}>
      {timelineEntriesBySelectedDate.map((timelineEntry, index) => {
        const isEditing = timelineEntry.id === editingEntryId;

        const nextTimelineEntry = timelineEntriesBySelectedDate[index + 1];
        const previousTimelineEntry = timelineEntriesBySelectedDate[index - 1];

        const inactiveActivitySegmentLine = (
          <div>
            <div className={styles.timelineEntry}>
              <ActivitySegmentAdd previousTimelineEntry={previousTimelineEntry} nextTimelineEntry={timelineEntry} />
            </div>
            <div className={styles.timelineEntry}>
              <PlaceVisitAdd mode="compact" status={ActivitySegmentStatusType.Inactive} />
            </div>
          </div>
        );

        const getStatusOfActivitySegmentLine = (): ActivitySegmentStatusType => {
          if (index === lastTimelineIndex) {
            return ActivitySegmentStatusType.None;
          }
          if (!isActivitySegment(nextTimelineEntry)) {
            return ActivitySegmentStatusType.Inactive;
          }
          return ActivitySegmentStatusType.Active;
        };

        if (isPlaceVisit(timelineEntry)) {
          const placeVisit = (
            <Fragment key={timelineEntry.id}>
              {previousTimelineEntryWasPlaceVisit && inactiveActivitySegmentLine}
              <div className={styles.timelineEntry} ref={placeVisitElements.get(timelineEntry.id)}>
                {isEditing ? (
                  <PlaceVisitEdit selectedPlaceVisit={timelineEntry} status={getStatusOfActivitySegmentLine()} />
                ) : (
                  <PlaceVisitView placeVisit={timelineEntry} status={getStatusOfActivitySegmentLine()} />
                )}
              </div>
            </Fragment>
          );

          previousTimelineEntryWasPlaceVisit = true;
          return placeVisit;
        } else if (isActivitySegment(timelineEntry)) {
          previousTimelineEntryWasPlaceVisit = false;
          return <ActivitySegment key={timelineEntry.id} isEditing={isEditing} activitySegment={timelineEntry} />;
        }
      })}
      <div className={styles.timelineEntry}>
        {/* Renders either "Add" button or "Add" form */}
        <PlaceVisitAdd status={ActivitySegmentStatusType.None} />
      </div>
    </div>
  );
};

export default TimelineEntries;
