import { startOfDay, endOfDay } from 'date-fns';

import { RootState } from '../reducers';
import { TimelineEntry, isPlaceVisit, isActivitySegment } from '../types/timeline';
import PlaceVisit from '../types/place-visit';
import ActivitySegment from '../types/activity-segment';

/**
 * Select all place visits
 */
export function selectPlaceVisits(state: RootState): PlaceVisit[] {
  return state.timeline.data.filter(isPlaceVisit);
}

/**
 * Select all activity segment
 */
export function selectActivitySegments(state: RootState): ActivitySegment[] {
  return state.timeline.data.filter(isActivitySegment);
}

/**
 * Select the timeline entries filtered by a date
 */
export function selectTimelineEntriesByDate(state: RootState, date: Date): TimelineEntry[] {
  return state.timeline.data.filter(({ time }) => time.end >= startOfDay(date) && time.start <= endOfDay(date));
}

/**
 * Select the timeline entries filtered by the selected date
 */
export function selectTimelineEntriesBySelectedDate(state: RootState): TimelineEntry[] {
  const { data, selectedDate } = state.timeline;

  if (!selectedDate) {
    return data;
  }

  return selectTimelineEntriesByDate(state, selectedDate);
}

/**
 * Select the timeline entry IDs filtered by the selected date
 */
export function selectTimelineEntryIdsBySelectedDate(state: RootState): string[] {
  return selectTimelineEntriesBySelectedDate(state).map(({ id }) => id);
}
