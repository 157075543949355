import * as actionTypes from '../types/actions/predefined-places-actions';
import PredefinedPlace from '../types/predefined-place';

/**
 * Set predefined place
 *
 * @param predefinedPlace Predefined place
 */
export function setPredefinedPlace(predefinedPlace: PredefinedPlace): actionTypes.SetPredefinedPlaceAction {
  return {
    type: actionTypes.SET_PREDEFINED_PLACE,
    payload: { predefinedPlace },
  };
}
