import { RootState } from '../reducers';
import Notification, { importFileErrorNotificationId, emptyImportNotificationId } from '../types/notification';

/**
 * Get the import file error notification
 */
export function selectImportFileErrorNotification(state: RootState): Notification | null {
  return state.notifications.find(({ id }) => id === importFileErrorNotificationId) || null;
}

/**
 * Get the empty import error notification
 */
export function selectEmptyImportErrorNotification(state: RootState): Notification | null {
  return state.notifications.find(({ id }) => id === emptyImportNotificationId) || null;
}

/**
 * Select all notifications for the notifier
 */
export function selectNotificationToShow(state: RootState): Notification | null {
  return state.notifications.find((notification) => !notification.dontShowNotifier) || null;
}
