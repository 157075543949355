import { FunctionComponent, useEffect } from 'react';
import { useGoogleMap } from '@ubilabs/google-maps-react-hooks';
import { useSelector } from 'react-redux';

import usePrevious from '../../hooks/use-previous-state';
import { RootState } from '../../reducers';
import { initialBounds, defaultLocationZoomLevel } from '../../configs/map';
import Bounds from '../../types/bounds';

const MAX_ZOOM = 21;

/**
 * Helper function to determine whether two bounds are the same
 */
const isSameBounds = (boundsA: Bounds, boundsB: Bounds): boolean =>
  boundsA &&
  boundsB &&
  boundsA.east === boundsB.east &&
  boundsA.south === boundsB.south &&
  boundsA.west === boundsB.west &&
  boundsA.north === boundsB.north;

/**
 * Adjust the map view when the state updates with bounds or location to fit to
 */
const MapView: FunctionComponent = () => {
  const { map } = useGoogleMap();
  const { bounds, center } = useSelector((state: RootState) => ({
    bounds: state.map.fitBounds,
    center: state.map.center,
  }));
  const prevBounds = usePrevious(bounds);
  // Updates markers for placeVisits from the timeline
  useEffect(() => {
    if (map && bounds) {
      if (isSameBounds(prevBounds, initialBounds)) {
        // hacky solution to ensure that obsolete tiles get discarded when fitbounds
        // is called initially (See GitHub issue #245)
        map.setZoom(MAX_ZOOM);
        google.maps.event.addListenerOnce(map, 'idle', () => {
          map.fitBounds(bounds);
        });
      } else {
        map.fitBounds(bounds);
      }
    }
  }, [map, bounds]);

  useEffect(() => {
    if (map && center) {
      const { latitude, longitude } = center;
      map.setZoom(defaultLocationZoomLevel);
      map.panTo({ lat: latitude, lng: longitude });
    }
  }, [map, center]);

  return null;
};

export default MapView;
