import React, { useState, FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSymptomsDate } from '../../../../actions/incubation-time-actions';
import { RootState } from '../../../../reducers';
import { noImport } from '../../../../configs/no-import';
import { setCaseId as setCaseIdInStore } from '../../../../actions/case-actions';

import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { format } from 'date-fns';
import de from 'date-fns/locale/de';
import Typography from '@material-ui/core/Typography';
import CalendarIcon from '@material-ui/icons/Event';
import TextField from '@material-ui/core/TextField';
import { StaticDatePicker, LocalizationProvider } from '@material-ui/pickers';

import Stepper from '../stepper/stepper';

import styles from './symptoms-date.css';
import sidebarStyles from '../../sidebar.css';
import useQueryString from '../../../../hooks/use-query-string';

interface Props {
  currentConfigurationStep: number;
  configurationSteps: number;
}

const SymptomsDate: FunctionComponent<Props> = ({ configurationSteps, currentConfigurationStep }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { caseId: caseIdFromUrl, date: dateFromUrl } = useQueryString();

  const incubation = useSelector((state: RootState) => state.incubation);
  const [selectedDate, setSelectedDate] = useState<Date | null>(incubation.symptomsDate);
  const history = useHistory();
  const dateInvalid = (date: Date | null): boolean => !date || date?.toString() === 'Invalid Date';

  const handleChange = useCallback((date: Date | null) => {
    setSelectedDate(date);
  }, []);

  useEffect(() => {
    caseIdFromUrl && dispatch(setCaseIdInStore(caseIdFromUrl));

    // Date query string could be between 1-1-2020 (8 chars) to 12-12-2020 (10 chars)
    if (dateFromUrl?.length >= 8 && dateFromUrl?.length <= 10) {
      const splitDate = dateFromUrl.split('-');

      const [day, month, year] = splitDate.map((datePart) => parseInt(datePart));

      if (day > 0 && day <= 31 && month > 0 && month <= 12 && year >= 1970) {
        // month in date constructor is zero based
        setSelectedDate(new Date(year, month - 1, day));
      }
    }
  }, [caseIdFromUrl, dateFromUrl]);

  return (
    <>
      <div className={sidebarStyles.sidebarContent}>
        <Typography variant="h3">{t('incubationTime.selectDate')}</Typography>
        <p className={styles.infoText}>{t('incubationTime.selectDateDescription')}</p>
        <div className={styles.selectedDate}>
          <Typography className={styles.selectedDateLabel} variant="h3">
            {format(selectedDate || new Date(), 'dd.MM.yyyy')}
          </Typography>
          <CalendarIcon />
        </div>
        <div className={styles.calendarContainer}>
          <aside className={styles.selectedDateAside}>
            <Typography className={styles.selectedDateLabel} variant="h3">
              <span>{format(selectedDate || new Date(), 'EEEE,', { locale: de })}</span>
              {format(selectedDate || new Date(), 'do LLLL, yyyy', { locale: de })}
            </Typography>
          </aside>
          <LocalizationProvider locale={de} dateAdapter={DateFnsAdapter}>
            <StaticDatePicker
              showToolbar={false}
              className={styles.calendar}
              disableFuture
              inputFormat="dd.MM.yyyy"
              label={t('incubationStart')}
              value={selectedDate}
              views={['date']}
              onChange={(date): void => {
                handleChange(date as Date);
              }}
              renderInput={(props): JSX.Element => (
                <TextField
                  helperText={dateInvalid(selectedDate) && t('invalidDate')}
                  error={dateInvalid(selectedDate)}
                  {...props}
                />
              )}
              OpenPickerButtonProps={{
                'aria-label': t('selectDate'),
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <Stepper
        isBlocked={dateInvalid(selectedDate)}
        {...(!noImport && { currentStep: currentConfigurationStep, configurationSteps })}
        onProceed={(): void => {
          dispatch(setSymptomsDate(selectedDate));
          history.push(noImport ? '/timeline' : '/configuration/location-history');
        }}
      />
    </>
  );
};

export default SymptomsDate;
