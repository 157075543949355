import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import RootRef from '@material-ui/core/RootRef';
import { IconButton, Popover, Typography } from '@material-ui/core';
import HintIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Clear';

import styles from './time-range-hint.css';

const TimeRangeHint: FunctionComponent = (): JSX.Element | null => {
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const iconRef = useRef<SVGElement>();

  const handleClick = (): void => {
    setIsPopoverOpen(true);
  };

  const handleClose = (): void => {
    setIsPopoverOpen(false);
  };

  return (
    <>
      <IconButton className={styles.hintIcon} onClick={handleClick}>
        <RootRef rootRef={iconRef}>
          <HintIcon />
        </RootRef>
      </IconButton>
      <Popover
        open={isPopoverOpen}
        anchorEl={iconRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography className={styles.popover}>
          {t('placeVisit.entryAcrossDay')}
          <CloseIcon className={styles.closeIcon} fontSize="small" onClick={handleClose} />
        </Typography>
      </Popover>
    </>
  );
};

export default TimeRangeHint;
