import React, { FunctionComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import parse from 'autosuggest-highlight/parse';

import styles from './autocomplete-prediction-option.css';

interface Props {
  prediction: google.maps.places.AutocompletePrediction;
}

const AutoCompletePreditionOption: FunctionComponent<Props> = ({ prediction }: Props): JSX.Element => {
  const matches = prediction.structured_formatting.main_text_matched_substrings;
  const parts = parse(
    prediction.structured_formatting.main_text,
    matches.map((match) => [match.offset, match.offset + match.length]),
  );

  return (
    <Grid container alignItems="center">
      <Grid item>
        <LocationOnIcon className={styles.markerIcon} />
      </Grid>
      <Grid item xs>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
        <Typography color="textSecondary">{prediction.structured_formatting.secondary_text}</Typography>
      </Grid>
    </Grid>
  );
};

export default AutoCompletePreditionOption;
