import { primaryColor, secondaryColor } from '../../configs/theme';
import { PredefinedPlaceType } from '../../types/predefined-place';

import { getDefaultMarkerIcon } from './default-marker-icon';
import { getWorkMarkerIcon } from './work-marker-icon';
import { getHomeMarkerIcon } from './home-marker-icon';

const colors = {
  default: primaryColor,
  editing: secondaryColor,
  ofSelectedDate: secondaryColor,
  selected: secondaryColor,
};
const sizes = {
  default: [34, 49],
  editing: [44, 63],
  ofSelectedDate: [34, 49],
  selected: [44, 63],
};
const svgViewBoxHeight = 31;
const markerBottomOffset = 4;

export type PlaceVisitMarkerIconType = 'ofSelectedDate' | 'selected' | 'editing' | 'default';

/**
 * The placeVisit marker icon
 */
export default (
  markerType: PlaceVisitMarkerIconType = 'default',
  predefinedPlaceType?: PredefinedPlaceType,
): google.maps.Icon => {
  const color = colors[markerType];
  const [width, height] = sizes[markerType];

  const getMarkerIcon = (): string => {
    switch (predefinedPlaceType) {
      case PredefinedPlaceType.Home:
        return getHomeMarkerIcon(color);
      case PredefinedPlaceType.Work:
        return getWorkMarkerIcon(color);
      default:
        return getDefaultMarkerIcon(color);
    }
  };
  const svg = encodeURIComponent(getMarkerIcon());

  return {
    anchor: new google.maps.Point(width / 2, height - (height / svgViewBoxHeight) * markerBottomOffset),
    url: `data:image/svg+xml;utf-8,${svg}`,
    scaledSize: new google.maps.Size(width, height),
  };
};
