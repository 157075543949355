import { subDays, endOfDay, startOfDay } from 'date-fns';

import { FREE_INTERVAL_DAYS, INCUBATION_DAYS } from '../configs/config';
import { IncubationTimeState } from '../reducers/incubation-time-reducer';

export const createIncubationTimeState = (symptomsDate: Date | null): IncubationTimeState => {
  const end = symptomsDate ? endOfDay(subDays(symptomsDate, FREE_INTERVAL_DAYS)) : null;
  const start = end ? startOfDay(subDays(end, INCUBATION_DAYS - 1)) : null;

  return {
    symptomsDate,
    start,
    end,
  };
};
