/**
 * The confidence status
 */
enum ConfidenceStatus {
  UserConfirmed = 'USER_CONFIRMED',
  HighConfidence = 'HIGH_CONFIDENCE',
  MediumConfidence = 'MEDIUM_CONFIDENCE',
  LowConfidence = 'LOW_CONFIDENCE',
}

export default ConfidenceStatus;
