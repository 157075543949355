import React, { useState, FunctionComponent, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useFormFields from '../../hooks/use-form-fields';

import Person from '../../types/person';

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { LocalizationProvider, DesktopDatePicker } from '@material-ui/pickers';

import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import de from 'date-fns/locale/de';

import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import FormFillingIndicator from './form-filling-indicator';

import clsx from 'clsx';

import styles from './contact-person-form.css';

interface Props {
  open: boolean;
  titleKey: string;
  contactPerson?: Partial<Person>;
  allowToCreateAnother?: boolean;
  allowDelete?: boolean;
  onSave: (contactPerson: Omit<Person, 'id'>, createAnotherAfterSave?: boolean) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

/**
 * A form to add or change a place visit
 */
const ContactPersonForm: FunctionComponent<Props> = ({
  open,
  titleKey,
  contactPerson,
  allowToCreateAnother,
  allowDelete,
  onSave,
  onCancel,
  onDelete,
}: Props) => {
  const { t } = useTranslation();
  const dateInvalid = useCallback((date: Date | null): boolean => date?.toString() === 'Invalid Date', []);

  /**
   * Handle birthDate time picker
   */
  const initialBirthDate = null;
  const [birthDate, setBirthDate] = useState<Date | null>(contactPerson?.birthDate || initialBirthDate);
  const [birthDateError, setBirthDateError] = useState(false);
  useEffect(() => setBirthDateError(dateInvalid(birthDate)), [birthDate]);
  useEffect(() => setBirthDate(contactPerson?.birthDate || null), [contactPerson?.birthDate]);

  const [fields, handleFieldChange, resetFields] = useFormFields({
    familyName: contactPerson?.familyName || '',
    givenName: contactPerson?.givenName || '',
    street: contactPerson?.address?.street || '',
    houseNumber: contactPerson?.address?.houseNumber || '',
    additionalAddress: contactPerson?.address?.additional || '',
    postalCode: contactPerson?.address?.postalCode || '',
    city: contactPerson?.address?.city || '',
    mainTelephone: contactPerson?.telephone?.main || '',
    secondaryTelephone: contactPerson?.telephone?.secondary || '',
    reachabilityNote: contactPerson?.reachabilityNote || '',
    languageNote: contactPerson?.languageNote || '',
    email: contactPerson?.email || '',
  });
  useEffect(() => resetFields(), [open]);

  /**
   * Check for errors in the data
   */
  const [errors, setErrors] = useState(false);
  useEffect(() => {
    birthDateError ||
    !fields.familyName ||
    !fields.givenName ||
    !(fields.mainTelephone || fields.email || fields.secondaryTelephone)
      ? setErrors(true)
      : setErrors(false);
  }, [
    birthDateError,
    fields.familyName,
    fields.givenName,
    fields.mainTelephone,
    fields.email,
    fields.secondaryTelephone,
  ]);

  /**
   * Create a contact person and save
   */
  const save = (createAnotherAfterSave?: 'createAnotherAfterSave'): void => {
    if (errors) {
      return;
    }

    onSave(
      {
        familyName: fields.familyName,
        givenName: fields.givenName,
        birthDate,
        address: {
          street: fields.street,
          houseNumber: fields.houseNumber,
          postalCode: fields.postalCode,
          city: fields.city,
          additional: fields.additionalAddress,
        },
        telephone: {
          main: fields.mainTelephone,
          secondary: fields.secondaryTelephone,
        },
        reachabilityNote: fields.reachabilityNote,
        languageNote: fields.languageNote,
        email: fields.email,
      } as Omit<Person, 'id'>,
      Boolean(createAnotherAfterSave),
    );
  };

  /**
   * Reset the form
   */
  const resetForm = useCallback(() => {
    setBirthDate(initialBirthDate);
    resetFields();
  }, []);

  /**
   * Create a contact person on form submit
   */
  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    save();
    resetForm();
  };

  /**
   * Trigger a submit but set that another one should be created before.
   */
  const handleSaveAndCreateAnother = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    save('createAnotherAfterSave');
    resetForm();
  };

  /**
   * Cancel the creation of a contact person in form
   */
  const handleCancel = (): void => {
    onCancel();
    resetForm();
  };

  /**
   * Handle delete contact entry
   */
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDeleteClick = (): void => {
    setShowDeleteConfirm(true);
  };

  /**
   * Cancel the deletion of a contact person in form
   */
  const handleDeleteConfirm = (): void => {
    setShowDeleteConfirm(false);
    onDelete && onDelete();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" aria-labelledby="add-contact-person-title" disableEnforceFocus>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <DialogTitle id="add-contact-person-title" disableTypography={true} className={styles.dialogTitle}>
          <Typography className={styles.dialogTitleHeadline} variant="h3">
            {t(titleKey)}
          </Typography>
          <Box aria-hidden="true" className={styles.mandatoryHint}>
            * {t('contactPerson.mandatoryHint')}
          </Box>
          <IconButton onClick={(): void => handleCancel()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={4}>
              <TextField
                className={styles.input}
                id="familyName"
                label={t('contactPerson.familyName')}
                onChange={handleFieldChange}
                value={fields.familyName}
                placeholder={t('contactPerson.familyNamePlaceholder')}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={styles.input}
                id="givenName"
                label={t('contactPerson.givenName')}
                onChange={handleFieldChange}
                value={fields.givenName}
                placeholder={t('contactPerson.givenNamePlaceholder')}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider locale={de} dateAdapter={DateFnsAdapter}>
                <DesktopDatePicker
                  showToolbar={false}
                  className={styles.input}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  disableFuture={true}
                  label={t('contactPerson.birthDate')}
                  value={birthDate}
                  onChange={(date) => {
                    setBirthDate(date as Date);
                  }}
                  renderInput={(props): JSX.Element => (
                    <TextField
                      margin="normal"
                      placeholder={t('contactPerson.birthDatePlaceholder')}
                      helperText={dateInvalid(birthDate) && t('invalidTime')}
                      {...props}
                    />
                  )}
                  OpenPickerButtonProps={{
                    'aria-label': t('contactPerson.selectBirthDate'),
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={8}>
              <TextField
                className={styles.input}
                id="street"
                label={t('contactPerson.street')}
                onChange={handleFieldChange}
                value={fields.street}
                placeholder={t('contactPerson.streetPlaceholder')}
                margin="normal"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className={styles.input}
                id="houseNumber"
                label={t('contactPerson.houseNumber')}
                onChange={handleFieldChange}
                value={fields.houseNumber}
                placeholder={t('contactPerson.houseNumberPlaceholder')}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <TextField
              className={styles.input}
              id="postalCode"
              label={t('contactPerson.postalCode')}
              onChange={handleFieldChange}
              value={fields.postalCode}
              placeholder={t('contactPerson.postalCodePlaceholder')}
              margin="normal"
            />
            <TextField
              className={styles.input}
              id="city"
              label={t('contactPerson.city')}
              onChange={handleFieldChange}
              value={fields.city}
              placeholder={t('contactPerson.cityPlaceholder')}
              margin="normal"
            />
          </Grid>
          <Grid container justify="space-between">
            <TextField
              className={styles.input}
              id="additionalAddress"
              label={t('contactPerson.additionalAddress')}
              onChange={handleFieldChange}
              value={fields.additionalAddress}
              placeholder={t('contactPerson.additionalAddressPlaceholder')}
              margin="normal"
            />

            <TextField
              InputProps={{ type: 'email' }}
              className={styles.input}
              id="email"
              label={t('contactPerson.email') + ' *'}
              onChange={handleFieldChange}
              value={fields.email}
              placeholder={t('contactPerson.emailPlaceholder')}
              margin="normal"
            />
          </Grid>
          <Grid container justify="space-between">
            <TextField
              className={styles.input}
              id="mainTelephone"
              label={t('contactPerson.mainTelephone') + ' *'}
              onChange={handleFieldChange}
              value={fields.mainTelephone}
              placeholder={t('contactPerson.mainTelephonePlaceholder')}
              margin="normal"
            />
            <TextField
              className={styles.input}
              id="secondaryTelephone"
              label={t('contactPerson.secondaryTelephone') + ' *'}
              onChange={handleFieldChange}
              value={fields.secondaryTelephone}
              placeholder={t('contactPerson.secondaryTelephonePlaceholder')}
              margin="normal"
            />
          </Grid>
          <Grid container justify="space-between">
            <TextField
              multiline
              rowsMax={4}
              className={styles.input}
              id="reachabilityNote"
              label={t('contactPerson.reachabilityNote')}
              onChange={handleFieldChange}
              value={fields.reachabilityNote}
              placeholder={t('contactPerson.reachabilityNotePlaceholder')}
              margin="normal"
            />
            <TextField
              multiline
              rowsMax={4}
              className={styles.input}
              id="languageNote"
              label={t('contactPerson.languageNote')}
              onChange={handleFieldChange}
              value={fields.languageNote}
              placeholder={t('contactPerson.languageNotePlaceholder')}
              margin="normal"
            />
          </Grid>
        </DialogContent>

        <DialogActions className={clsx(styles.dialogActions, contactPerson && styles.dialogActionsJustifyEnd)}>
          {allowToCreateAnother && (
            <Button onClick={handleSaveAndCreateAnother} disabled={errors}>
              {t('contactPerson.saveAndCreateAnother')}
            </Button>
          )}
          {allowDelete ? (
            <>
              <Button className={styles.deleteButton} onClick={handleDeleteClick} disabled={errors}>
                {t('delete')}
              </Button>
              <ConfirmDialog
                open={showDeleteConfirm}
                handleClose={onCancel}
                dialogTitle={t('contactsList.deleteContact')}
                dialogContentText={t('contactsList.deleteText')}
                agree={{ onAgree: handleDeleteConfirm, text: t('delete') }}
                disagree={{ onDisagree: (): void => setShowDeleteConfirm(false), text: t('cancel') }}
              />
            </>
          ) : (
            <FormFillingIndicator fieldValues={[...Object.values(fields), birthDate?.toString() || '']} />
          )}
          <Button variant="contained" color="primary" type="submit" disableElevation disabled={errors}>
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContactPersonForm;
