import React, { FunctionComponent, Ref, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateTimelineEntry, setEditingTimelineEntry } from '../../actions/timeline-actions';

import PlaceVisitForm from '../place-visit-form/place-visit-form';
import { validatePlaceVisitForm } from '../../utils/validate-forms';

import PlaceVisit from '../../types/place-visit';
import { ActivitySegmentStatusType } from '../../types/activity-segment-status';
import { RootState } from '../../reducers';

interface Props {
  selectedPlaceVisit: PlaceVisit;
  ref?: Ref<HTMLDivElement>;
  status: ActivitySegmentStatusType;
}

/**
 * Component showing the edit mode of a place visit
 */
const PlaceVisitEdit: FunctionComponent<Props> = ({ selectedPlaceVisit, status }: Props) => {
  const dispatch = useDispatch();
  const { editingEntry } = useSelector((state: RootState) => ({
    editingEntry: state.timeline.editingEntry,
  }));
  const editingPlaceVisit = useRef<Partial<PlaceVisit | null>>(null);
  /**
   * Save changes of a place visit edit
   */
  const handleSave = (placeVisit: Omit<PlaceVisit, 'id'>): void => {
    dispatch(updateTimelineEntry(selectedPlaceVisit.id, placeVisit));
    dispatch(setEditingTimelineEntry(null));
  };

  /**
   * Handle the cancelling of an edit
   */
  const handleCancel = (): void => {
    editingPlaceVisit.current = null;
    dispatch(setEditingTimelineEntry(null));
  };

  /**
   * Update the editingPlaceVisit ref when editingEntry changes
   */
  useEffect(() => {
    editingPlaceVisit.current = editingEntry;
  }, [editingEntry]);

  /**
   * Do cleanup when component unmounts
   */
  useEffect(() => {
    return (): void => {
      if (editingPlaceVisit.current) {
        const { payload } = validatePlaceVisitForm(editingPlaceVisit.current, selectedPlaceVisit);
        dispatch(updateTimelineEntry(selectedPlaceVisit.id, payload));
      }

      dispatch(setEditingTimelineEntry(null));
    };
  }, []);

  return <PlaceVisitForm scrollIntoViewOnMount={true} onSave={handleSave} onCancel={handleCancel} status={status} />;
};

export default PlaceVisitEdit;
