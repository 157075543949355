import * as actionTypes from '../types/actions/case-actions';

/**
 * Set the case ID
 */
export function setCaseId(id: string): actionTypes.SetCaseIdAction {
  return {
    type: actionTypes.SET_CASE_ID,
    payload: {
      id,
    },
  };
}
