import React, { FunctionComponent, useCallback } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';
import { useTranslation, Trans } from 'react-i18next';

import styles from './cookie-disclaimer.css';
import { Backdrop, makeStyles, Theme, createStyles } from '@material-ui/core';
import { setPrivacyDialogVisible } from '../../actions/privacy-dialog-actions';
import { useDispatch } from 'react-redux';

const COOKIE_NAME = 'kadoin-cookie-banner-v1';
const COOKIE_MAX_AGE = 31536000;

const cookies = document.cookie.split(';');
export const cookieConsent =
  cookies
    .find((cookie) => cookie.trim().indexOf(COOKIE_NAME) === 0)
    ?.split('=')
    .slice(-1)[0] === '1';

interface Props {
  onAccept: () => void;
  onDecline: () => void;
}

const CookieDisclaimer: FunctionComponent<Props> = ({ onAccept, onDecline }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // need to access theme here, therefore jss is used.
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
      },
    }),
  );
  const classes = useStyles();

  const acceptCookies = useCallback(() => {
    document.cookie = `${COOKIE_NAME}=1; max-age=${COOKIE_MAX_AGE}`;
    onAccept();
  }, []);

  const declineCookies = useCallback(() => {
    document.cookie = `${COOKIE_NAME}=0; max-age=${COOKIE_MAX_AGE}`;
    onDecline();
  }, []);

  return (
    <Backdrop className={classes.backdrop} open>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open>
        <SnackbarContent
          className={styles.container}
          message={
            <Trans i18nKey="cookieDisclaimer.description">
              go to
              <span
                className={styles.link}
                onClick={(): void => {
                  dispatch(setPrivacyDialogVisible(true));
                }}
              >
                privacy policy
              </span>
            </Trans>
          }
          action={
            <React.Fragment>
              <Button
                className={styles.buttonAccept}
                key="accept"
                aria-label="accept"
                variant="contained"
                color="secondary"
                onClick={acceptCookies}
              >
                {t(`cookieDisclaimer.buttonAccept`)}
              </Button>
              <Button
                className={styles.buttonDecline}
                key="decline"
                aria-label="decline"
                variant="outlined"
                color="primary"
                onClick={declineCookies}
              >
                {t(`cookieDisclaimer.buttonDecline`)}
              </Button>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Backdrop>
  );
};

export default CookieDisclaimer;
