import React, { FunctionComponent, useState, forwardRef, Ref } from 'react';
import { useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ActivitySegmentTypeSelect from '../activity-segment-type-select/activity-segment-type-select';

import { updateTimelineEntry, removeTimelineEntry } from '../../../actions/timeline-actions';
import ActivityType from '../../../types/activity-type';
import ActivitySegment from '../../../types/activity-segment';
import { getActivitySegmentLabel } from '../../../utils/get-activity-segment-type-label';

import ActivitySegmentPublicTransport from '../../../../assets/icons/activitiySegment-selected-publicTransport.svg';
import ActivitySegmentCar from '../../../../assets/icons/activitySegment-selected-car.svg';
import ActivitySegmentCyclingWalking from '../../../../assets/icons/activitySegment-selected-cyclingWalking.svg';

import styles from './activity-segment-type.css';

interface Props {
  activitySegment: ActivitySegment;
  ref?: Ref<HTMLDivElement>;
}

/**
 * Displays an activity segment with the selected activitytype
 */
const ActivitySegmentType: FunctionComponent<Props> = forwardRef(({ activitySegment }: Props, ref) => {
  const dispatch = useDispatch();

  const { activityType } = activitySegment;

  /**
   * Handle the selection menu
   */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  /**
   * Handle the selection or removal of an activity segment
   */
  const handleSelect = (activity: ActivityType | null): void => {
    if (!activity) {
      dispatch(removeTimelineEntry(activitySegment.id));
    } else {
      dispatch(updateTimelineEntry(activitySegment.id, { activityType: activity }));
    }
  };

  /**
   * Get the icon for the activity segment view
   */
  const getActivitySegmentIcon = (activityType: ActivityType | null): JSX.Element | null => {
    switch (activityType) {
      case ActivityType.Car:
        return <ActivitySegmentCar id="withCar" />;
      case ActivityType.PublicTransport:
        return <ActivitySegmentPublicTransport id="withPublicTransport" />;
      case ActivityType.CyclingWalking:
        return <ActivitySegmentCyclingWalking id="cyclingWalking" />;
      default:
        return null;
    }
  };

  return (
    <div ref={ref}>
      <Box component="div" display="flex" alignItems="center" className={styles.activitySegment}>
        <IconButton classes={{ root: styles.button }} onClick={handleButtonClick}>
          {getActivitySegmentIcon(activityType)}
        </IconButton>
        <Typography className={styles.activitySegmentText}>{getActivitySegmentLabel(activityType)}</Typography>
      </Box>

      <ActivitySegmentTypeSelect anchorEl={anchorEl} onSelect={handleSelect} onClose={handleClose} />
    </div>
  );
});

ActivitySegmentType.displayName = 'ActivitySegmentType';

export default ActivitySegmentType;
