import ActionTypes from '../types/actions';
import Location from '../types/location';
import { ZOOM_TO_LOCATION, UPDATE_BOUNDS } from '../types/actions/map-actions';
import { IMPORT_STORE } from '../types/actions/global-actions';
import {
  IMPORT_TIMELINE,
  SET_EDITING_TIMELINE_ENTRY,
  SET_SELECTED_TIMELINE_ENTRY,
} from '../types/actions/timeline-actions';
import Bounds from '../types/bounds';

import getBoundsFromTimeline from '../utils/get-bounds-from-timeline';
import { initialBounds } from '../configs/map';
import { isPlaceVisit } from '../types/timeline';

export type MapState = {
  fitBounds: Bounds | null;
  center: Location | null;
};

const initialState: MapState = {
  fitBounds: initialBounds,
  center: null,
};

/**
 * Handle the map state
 */
function mapReducer(state: MapState = initialState, action: ActionTypes): MapState {
  switch (action.type) {
    case IMPORT_TIMELINE: {
      if (action.payload instanceof Error) {
        return state;
      }
      return { ...state, fitBounds: getBoundsFromTimeline(action.payload.data), center: null };
    }

    case ZOOM_TO_LOCATION: {
      return { ...state, fitBounds: null, center: action.payload.location };
    }

    case IMPORT_STORE: {
      const { timeline } = action.payload.snapshot;
      return { ...state, fitBounds: getBoundsFromTimeline(timeline), center: null };
    }

    case UPDATE_BOUNDS: {
      const { timeline } = action.payload;
      return { ...state, fitBounds: getBoundsFromTimeline(timeline), center: null };
    }

    case SET_SELECTED_TIMELINE_ENTRY: {
      if (!action.payload.timelineEntry || !isPlaceVisit(action.payload.timelineEntry)) {
        return state;
      }
      return { ...state, fitBounds: null, center: action.payload.timelineEntry.location };
    }

    case SET_EDITING_TIMELINE_ENTRY: {
      if (!action.payload.timelineEntry || !isPlaceVisit(action.payload.timelineEntry)) {
        return state;
      }
      return { ...state, fitBounds: null, center: action.payload.timelineEntry.location };
    }

    default:
      return state;
  }
}

export default mapReducer;
