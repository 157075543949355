import React, { FunctionComponent, useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ActivitySegmentLine from '../activity-segment/activity-segment-line/activity-segment-line';

import { removeTimelineEntry, setEditingTimelineEntry } from '../../actions/timeline-actions';
import { updateMapBounds } from '../../actions/map-actions';

import { trimAddress } from '../../utils/format-address';

import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import ContactPersonChips from '../contacts/contact-person-chips';
import TimeRangeHint from './time-range-hint';

import { RootState } from '../../reducers';
import { getContactsByIds } from '../../selectors/contacts';

import PlaceVisit from '../../types/place-visit';
import { ActivitySegmentStatusType } from '../../types/activity-segment-status';

import LocationIcon from '../../../assets/icons/location.svg';

import { format, isSameDay } from 'date-fns';

import styles from './place-visit-view.css';

interface Props {
  placeVisit: PlaceVisit;
  status: ActivitySegmentStatusType;
}

/**
 * A form to add or change a place visit
 */
const PlaceVisitView: FunctionComponent<Props> = ({ placeVisit, status }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const elementRef = useRef<HTMLDivElement>(null);
  const { isAlreadyEditing, entryIsSelected, selectedDate } = useSelector((state: RootState) => ({
    isAlreadyEditing: Boolean(state.timeline.editingEntry),
    entryIsSelected: placeVisit.id === state.timeline.selectedEntry?.id,
    selectedDate: state.timeline.selectedDate,
  }));

  const { address, name, time, comment, contacts } = placeVisit;
  const contactsOfPlace = useSelector((state: RootState) => getContactsByIds(state, contacts));
  const visitCrossesDayBoundaries =
    selectedDate && (!isSameDay(selectedDate, time.start) || !isSameDay(selectedDate, time.end));

  // Scroll entry into view when selected
  useEffect(() => {
    if (entryIsSelected) {
      elementRef.current?.scrollIntoView();
    }
  }, [entryIsSelected]);

  /**
   * Handle edit entry
   */
  const handleEditClick = (): void => {
    dispatch(setEditingTimelineEntry(placeVisit));
  };

  /**
   * Handle delete entry
   */
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClick = useCallback((): void => {
    setShowDelete(true);
  }, []);
  const handleDeleteConfirm = (): void => {
    dispatch(removeTimelineEntry(placeVisit.id));
    dispatch(updateMapBounds());
  };

  return (
    <div className={styles.container} ref={elementRef}>
      <div className={styles.locationContainer}>
        <LocationIcon className={styles.locationIcon} />
        <ActivitySegmentLine status={status} type="long" />

        <div className={styles.contentContainer}>
          <Typography className={styles.locationTitle}>{name}</Typography>
          <InputLabel>{address ? trimAddress(address) : ''}</InputLabel>
          <div className={styles.contentRow}>
            <Box display="flex" alignItems="center">
              <Typography className={styles.contentRowText}>
                {format(time.start, 'HH:mm') + ' - ' + format(time.end, 'HH:mm')}
              </Typography>
              {visitCrossesDayBoundaries && <TimeRangeHint />}
            </Box>
          </div>
          {Boolean(contactsOfPlace.length) && (
            <div className={styles.contentRow}>
              <ContactPersonChips contacts={contactsOfPlace} />
            </div>
          )}
          {comment && (
            <div className={styles.contentRow}>
              <InputLabel>{t('placeVisit.notes')}</InputLabel>
              <Typography className={styles.contentRowText}>{comment}</Typography>
            </div>
          )}
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <IconButton className={styles.editIconButton} onClick={handleEditClick} disabled={isAlreadyEditing}>
          <EditIcon />
        </IconButton>
        <IconButton className={styles.deleteIconButton} onClick={handleDeleteClick} disabled={isAlreadyEditing}>
          <DeleteIcon />
        </IconButton>
        <ConfirmDialog
          open={showDelete}
          handleClose={(): void => setShowDelete(false)}
          dialogTitle={t('placeVisit.deleteTitle')}
          dialogContentText={t('placeVisit.deleteText')}
          agree={{ onAgree: handleDeleteConfirm, text: t('delete') }}
          disagree={{ onDisagree: (): void => setShowDelete(false), text: t('cancel') }}
        />
      </div>
    </div>
  );
};

export default PlaceVisitView;
