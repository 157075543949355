import React, { FunctionComponent, Ref, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Reference from '../reference';

import { IMPRINT_URL } from '../../../configs/urls';

import styles from './header.css';

interface Props {
  references: Map<Reference, Ref<HTMLDivElement>>;
  onLinkClick: () => void;
}

const Navigation: FunctionComponent<Props> = ({ references, onLinkClick }: Props) => {
  const { t } = useTranslation();

  const handleLinkClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      onLinkClick();

      const link = event.target as HTMLAnchorElement;
      const refName = link?.dataset?.refName as Reference;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore current is somehow not available even though it is. Investigate!
      const element = references.get(refName)?.current as HTMLDivElement | undefined;

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [references],
  );

  return (
    <nav>
      <ul>
        <li>
          <a
            href="#aboutApplication"
            title={t('startPage.aboutApplication.title')}
            data-ref-name="aboutApplicationRef"
            onClick={handleLinkClick}
            className={styles.link}
          >
            {t('startPage.aboutApplication.title')}
          </a>
        </li>
        <li>
          <a
            href="#startInput"
            title={t('startPage.startInput.title')}
            data-ref-name="startInputRef"
            onClick={handleLinkClick}
            className={styles.link}
          >
            {t('startPage.startInput.title')}
          </a>
        </li>
        <li>
          <a
            href="#quickGuide"
            title={t('startPage.quickGuide.title')}
            data-ref-name="quickGuideRef"
            onClick={handleLinkClick}
            className={styles.link}
          >
            {t('startPage.quickGuide.title')}
          </a>
        </li>
        <li>
          <a
            href="#privacyPolicy"
            title={t('startPage.privacyPolicy.title')}
            data-ref-name="privacyPolicyRef"
            onClick={handleLinkClick}
            className={styles.link}
          >
            {t('startPage.privacyPolicy.title')}
          </a>
        </li>
        <li>
          <a
            href="#partner"
            title={t('startPage.partner.title')}
            data-ref-name="partnerRef"
            onClick={handleLinkClick}
            className={styles.link}
          >
            {t('startPage.partner.title')}
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={IMPRINT_URL}
            title={t('app.navigation.imprint')}
            data-ref-name="imprintRef"
            onClick={onLinkClick}
            className={styles.link}
          >
            {t('app.navigation.imprint')}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
