import { startOfDay, endOfDay } from 'date-fns';

import Timeline, { TimelineEntry } from '../types/timeline';

function truncateEntryTimes(startTime: Date, endTime: Date): (entry: TimelineEntry) => TimelineEntry {
  return (entry: TimelineEntry): TimelineEntry => {
    const { time } = entry;
    const startOfStartDay = startOfDay(startTime);
    const endOfEndDay = endOfDay(endTime);

    return {
      ...entry,
      time: {
        start: time.start < startOfStartDay ? startOfStartDay : time.start,
        end: time.end > endOfEndDay ? endOfEndDay : time.end,
      },
    };
  };
}

/**
 * Limit the timeline by a date range
 */
export default function limitTimelineByDateRange(
  timeline: Timeline,
  startTime: Date | null,
  endTime: Date | null,
): Timeline {
  if (startTime && endTime) {
    return timeline
      .filter(({ time }) => time.end >= startTime && time.start <= endTime)
      .map(truncateEntryTimes(startTime, endTime));
  }

  return timeline;
}
