import Timeline, { TimelineEntry } from '../types/timeline';
import Person from '../types/person';
import { ContactsState } from '../reducers/contacts-reducer';
import { PredefinedPlacesState } from '../reducers/predefined-places-reducer';
import { RootState } from '../reducers';
import { CaseState } from '../reducers/case-reducer';

export interface StoreSnapshot {
  case: CaseState;
  timeline: Timeline;
  contacts: ContactsState;
  incubation: {
    symptomsDate: Date | null;
    start: Date | null;
    end: Date | null;
  };
  predefinedPlaces: PredefinedPlacesState;
}

export function serializeStoreSnapshot({
  case: caseState,
  timeline,
  contacts,
  incubation,
  predefinedPlaces,
}: StoreSnapshot): string {
  const payload = {
    timeline: timeline.map((entry) => ({
      ...entry,
      time: {
        start: entry.time.start.getTime(),
        end: entry.time.end.getTime(),
      },
    })),
    contacts: contacts.map((contact) => ({ ...contact, birthDate: contact.birthDate?.getTime() })),
    incubation: {
      end: incubation.end?.getTime(),
      start: incubation.start?.getTime(),
      symptomsDate: incubation.symptomsDate?.getTime(),
    },
    predefinedPlaces: [...predefinedPlaces],
    case: caseState,
  };
  return JSON.stringify(payload, null, 2);
}

export function deserializeStoreSnapshot(jsonString: string): StoreSnapshot {
  const { timeline, contacts, incubation, predefinedPlaces, case: caseState } = JSON.parse(jsonString);
  const payload = {
    timeline: timeline.map((entry: TimelineEntry) => ({
      ...entry,
      time: {
        start: new Date(entry.time.start),
        end: new Date(entry.time.end),
      },
    })),
    contacts: contacts.map((contact: Person) => ({
      ...contact,
      ...(contact.birthDate ? { birthDate: new Date(contact.birthDate) } : {}),
    })),
    incubation: {
      end: new Date(incubation.end),
      start: new Date(incubation.start),
      symptomsDate: new Date(incubation.symptomsDate),
    },
    predefinedPlaces: [...predefinedPlaces],
    case: caseState,
  };
  return payload;
}

export function mergeSnaphotWithStore(rootState: RootState, snapshot: StoreSnapshot): RootState {
  const { timeline, case: caseState, contacts, incubation, predefinedPlaces } = snapshot;
  return {
    ...rootState,
    timeline: { ...rootState.timeline, data: timeline, selectedDate: incubation.start },
    contacts,
    incubation: Object.assign({}, rootState.incubation, incubation),
    predefinedPlaces,
    case: caseState,
  };
}
