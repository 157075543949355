import { useEffect, useRef } from 'react';

/**
 * Custom hook to get previous props or state
 * @param value Any prop or state
 *
 * @returns The value previous to the current render
 */
const usePrevious = (value: any): typeof value => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
