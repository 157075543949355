import { nanoid } from 'nanoid';

import * as actionTypes from '../types/actions/contacts-actions';
import Person, { PersonId } from '../types/person';

/**
 * Add a contact person to the list
 */
export function addContactPerson(contactPerson: Omit<Person, 'id'>): actionTypes.AddContactPersonAction {
  return {
    type: actionTypes.ADD_CONTACT_PERSON,
    payload: {
      contactPerson: {
        ...contactPerson,
        id: nanoid(),
      },
    },
  };
}

/**
 * Update a contact persons data
 */
export function updateContactPerson(
  id: PersonId,
  updatedFields: Partial<Person>,
): actionTypes.UpdateContactPersonAction {
  return {
    type: actionTypes.UPDATE_CONTACT_PERSON,
    payload: { id, updatedFields },
  };
}

/**
 * Remove a contact person
 */
export function removeContactPerson(id: PersonId): actionTypes.RemoveContactPersonAction {
  return {
    type: actionTypes.REMOVE_CONTACT_PERSON,
    payload: { id },
  };
}
