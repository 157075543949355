import { isAfter, isEqual } from 'date-fns';
import PlaceVisit from '../types/place-visit';

/**
 * Helper function to check whether date is invalid
 */
const isDateValid = (date: Date): boolean => date.toString() !== 'Invalid Date';

/**
 * Helper function that accesses a nested value or returns undefined
 */
const getValue = (object: Record<string, any>, path: string): string | number | Date | undefined =>
  path.split('.').reduce((obj, prop) => obj && obj[prop], object) as any;

/**
 * Returns a validate function that checks whether a value is erroneous and optionally
 * can return a fallback value
 */
function createValidator(
  entry: Partial<PlaceVisit>,
  errors: string[],
  fallback?: PlaceVisit,
): (path: string) => string | number | Date | undefined {
  return function (path): string | number | Date | undefined {
    const value = getValue(entry, path);

    if (value instanceof Date && !isDateValid(value)) {
      errors.push(path);
    }

    if (!value) {
      errors.push(path);
    }

    if (fallback && errors.includes(path)) {
      return getValue(fallback, path);
    }
    return value;
  };
}

/**
 * Checks whether a place visit has errors and optionally provides a fallback value
 * @param entry The place visit entry to validate
 * @param fallback A place visit which values should be used as a fallback
 */
export function validatePlaceVisitForm(
  entry: Partial<PlaceVisit>,
  fallback?: PlaceVisit,
): { errors: string[]; payload: PlaceVisit } {
  const errors = [] as string[];
  const validate = createValidator(entry, errors, fallback);

  const payload = {
    ...fallback,
    ...entry,
    time: {
      start: validate('time.start'),
      end: validate('time.end'),
    },
    location: {
      latitude: validate('location.latitude'),
      longitude: validate('location.longitude'),
    },
    name: validate('name'),
  } as PlaceVisit;

  if (isAfter(payload.time.start, payload.time.end)) {
    errors.push('time.end', 'time.start');
  }

  return { errors, payload };
}
