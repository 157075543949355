import React, { FunctionComponent, useState, useRef } from 'react';
import LoginDialog from '../login-dialog/login-dialog';

interface Props {
  children: React.ReactNode;
}

const ProtectedRoute: FunctionComponent<Props> = ({ children }) => {
  const isDevMode = useRef(process.env.NODE_ENV === `development`);
  const [isAuthenticated, setIsAuthenticated] = useState(isDevMode.current);

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <LoginDialog
      onAuthenticated={(): void => {
        setIsAuthenticated(true);
      }}
    ></LoginDialog>
  );
};

export default ProtectedRoute;
