import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { NotificationType } from '../../types/notification';
import { removeNotification } from '../../actions/notifications-actions';
import { selectNotificationToShow } from '../../selectors/notifications';

const Notifier: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const notification = useSelector(selectNotificationToShow);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (notification) {
      setOpen(true);
    }
  }, [notification]);

  const remove = useCallback(() => {
    if (notification) {
      dispatch(removeNotification(notification.id));
    }
  }, [notification]);
  const close = useCallback(() => setOpen(false), []);

  if (!notification) {
    return null;
  }

  const isError = notification.type === NotificationType.Error;

  return (
    <Snackbar
      key={notification.id}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      onClose={close}
      onExited={remove}
      autoHideDuration={isError ? null : 5000}
    >
      <Alert onClose={close} severity={notification.type}>
        {t(notification.messageKey)}
      </Alert>
    </Snackbar>
  );
};

export default Notifier;
