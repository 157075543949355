export const getDefaultMarkerIcon = (
  color: string,
): string => `<svg width="34" height="49" viewBox="0 0 34 49" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_f)">
  <ellipse cx="17.1287" cy="44.173" rx="4.49774" ry="0.826921" fill="black" fill-opacity="0.41"/>
  </g>
  <g filter="url(#filter1_d)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.06152 24.8468C2.75138 22.5945 2 19.9699 2 17.1679C2 8.7909 8.71573 2 17 2C25.2843 2 32 8.7909 32 17.1679C32 19.9699 31.2486 22.5945 29.9385 24.8468H29.9465C29.2284 25.9816 28.3162 27.8752 22.9193 32.3814C17.5223 36.8875 19.0328 43.1064 16.9947 43.1064H17.0053C14.9672 43.1064 16.4777 36.8875 11.0807 32.3814C5.68379 27.8752 4.77157 25.9816 4.05351 24.8468H4.06152Z" fill="${color}"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.06152 24.8468C2.75138 22.5945 2 19.9699 2 17.1679C2 8.7909 8.71573 2 17 2C25.2843 2 32 8.7909 32 17.1679C32 19.9699 31.2486 22.5945 29.9385 24.8468H29.9465C29.2284 25.9816 28.3162 27.8752 22.9193 32.3814C17.5223 36.8875 19.0328 43.1064 16.9947 43.1064H17.0053C14.9672 43.1064 16.4777 36.8875 11.0807 32.3814C5.68379 27.8752 4.77157 25.9816 4.05351 24.8468H4.06152Z" stroke="white" stroke-width="2"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0012 23.0669C20.2228 23.0669 22.8345 20.426 22.8345 17.1683C22.8345 13.9106 20.2228 11.2697 17.0012 11.2697C13.7795 11.2697 11.1678 13.9106 11.1678 17.1683C11.1678 20.426 13.7795 23.0669 17.0012 23.0669Z" fill="black" fill-opacity="0.87"/>
  <defs>
  <filter id="filter0_f" x="8.63098" y="39.3461" width="16.9955" height="9.65384" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
  <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
  </filter>
  <filter id="filter1_d" x="0" y="0" width="34" height="45.1064" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="0.5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
  </filter>
  </defs>
  </svg>
  `;
