import Timeline, { isPlaceVisit } from '../types/timeline';
import { PlaceVisitFlat } from '../types/place-visit';
import Contacts from '../types/contacts';
import { PersonFlat } from '../types/person';
import format from 'date-fns/format';
import sha256 from 'crypto-js/sha256';

/**
 * Convert coordinates to fixed precision to avoid Javascript float imprecision
 *
 * @param timeline
 */
const convertCoordinatesToFixedPrecision = (timeline: Timeline): Timeline =>
  timeline.map((entry) => {
    if (isPlaceVisit(entry) && entry.location?.latitude && entry.location?.latitude) {
      return {
        ...entry,
        location: {
          latitude: parseFloat(entry.location.latitude.toFixed(7)),
          longitude: parseFloat(entry.location.longitude.toFixed(7)),
        },
      };
    }
    return entry;
  });

/**
 * Does three things to ensure a uniform shape of every timeline item
 * - Flattens objects
 * - Removes unused (not-exported) properties
 * - Adds property keys for undefined values
 *
 * @param timeline
 */
const createUniformTimeline = (timeline: Timeline): Array<PlaceVisitFlat> => {
  return timeline.filter(isPlaceVisit).map((entry) => {
    const { id, location, time, name, address, comment, contacts } = entry;
    return {
      id,
      'location.latitude': location.latitude,
      'location.longitude': location.longitude,
      'time.start': time.start,
      'time.end': time.end,
      locationName: name,
      address: address,
      comment,
      contacts: contacts && contacts.join(';'),
    };
  });
};

/**
 * Does three things to ensure a uniform shape of every contacts item
 * - Flattens objects
 * - Removes unused (not-exported) properties
 * - Adds property keys for undefined values
 *
 * @param contacts
 */
const createUniformContacts = (contacts: Contacts): Array<PersonFlat> => {
  return contacts.map((contact) => {
    const { id, address, birthDate, familyName, givenName, telephone, reachabilityNote, languageNote, email } = contact;
    return {
      id,
      'address.city': address.city,
      'address.postalCode': address.postalCode,
      'address.street': address.street,
      'address.houseNumber': address.houseNumber,
      'address.additional': address.additional,
      birthDate,
      familyName,
      givenName,
      'telephone.main': telephone.main,
      'telephone.secondary': telephone.secondary,
      reachabilityNote,
      languageNote,
      email,
    };
  });
};

/**
 * Export internal timeline representation to a csv string
 *
 * @param timeline
 */
export const createCsvFromTimeline = (timeline: Timeline): string => {
  // Empty timeline -> empty export
  if (!timeline || timeline.length === 0) {
    return '';
  }

  const convertedCoordinateTimeline = convertCoordinatesToFixedPrecision(timeline);
  const uniformTimeline = createUniformTimeline(convertedCoordinateTimeline);
  // Add CSV header section
  const titles = Object.keys(uniformTimeline[0]).reduce((acc, val) => acc + val + ';', '');

  // Add timeline records
  const records = uniformTimeline.map((entry: PlaceVisitFlat): string => {
    const entryValues = Object.values(entry);
    const entryValuesRecord = entryValues.reduce((accumulator: string, currentValue): string => {
      let value;
      if (currentValue instanceof Date) {
        value = `"${currentValue.toLocaleString('de-DE', { timeZoneName: 'short' })}"`;
      } else if (!currentValue) {
        value = '';
      } else if (typeof currentValue === 'string') {
        value = `"${currentValue}"`;
      } else if (typeof currentValue === 'number') {
        value = String(currentValue);
      }
      return accumulator + value + ';';
    }, '');
    return entryValuesRecord;
  });
  return titles + '\r\n' + records.join('\r\n');
};

/**
 * Export internal contacts representation to a csv string
 *
 * @param contacts
 */
export const createCsvFromContacts = (contacts: Contacts): string => {
  if (!contacts || contacts.length === 0) return '';
  const uniformContacts = createUniformContacts(contacts);

  // Add CSV header section
  const titles = Object.keys(uniformContacts[0]).reduce((acc, val) => acc + val + ';', '');

  // Add contact records
  const records = uniformContacts.map((entry: PersonFlat): string => {
    const entryValues = Object.values(entry);
    const entryValuesRecord = entryValues.reduce((accumulator: string, currentValue, index): string => {
      let value = '';
      if (typeof currentValue === 'string') {
        value = `"${currentValue}"`;
      } else if (currentValue instanceof Date) {
        value = `"${format(currentValue, 'dd.MM.yyyy')}"`;
      }

      // TODO: Remove this hashing function once the application is out of beta
      // Don't hash empty strings for better readability and don't
      // hash contact ID (index 0) (b/c we still need to be able to reference this in the other files)
      if (value !== `""` && index > 0) {
        value = `"${sha256(value).toString()}"`;
      }

      return accumulator + value + ';';
    }, '');
    return entryValuesRecord;
  });
  return titles + '\r\n' + records.join('\r\n');
};
