/**
 * Notification types
 */
export enum NotificationType {
  Success = 'success',
  Info = 'info',
  Error = 'error',
}

/**
 * A notification in our app
 */
export default interface Notification {
  id: string;
  messageKey: string;
  type: NotificationType;
  dontShowNotifier?: boolean;
}

export const importFileErrorNotificationId = 'importFileError';
export const emptyImportNotificationId = 'emptyImportError';
