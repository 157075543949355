import React, { FunctionComponent, useState, useCallback, forwardRef, Ref } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import PoiMarker from './poi-marker/poi-marker';
import OrangeStripe from '../../../../assets/images/start-page/orange-stripe.svg';
import ConfirmDialog from '../../confirm-dialog/confirm-dialog';

import { importStoreFile } from '../../../actions/global-actions';

import styles from './case-start-section.css';
import useQueryString from '../../../hooks/use-query-string';

interface Props {
  ref?: Ref<HTMLDivElement>;
}

const CaseStartSection: FunctionComponent<Props> = forwardRef((_: Props, ref) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isChecked, setCheckbox] = useState(false);
  const { caseId: caseIdFromUrl, date: dateFromUrl } = useQueryString();
  const [caseId, setCaseId] = useState(caseIdFromUrl || '');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCaseId(event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCheckbox(event.target.checked);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    dispatch(importStoreFile(selectedFile));
    history.push('/timeline');
  }, []);

  const { getInputProps } = useDropzone({ onDrop, noClick: true, accept: ['.json', '.zip'], multiple: false });

  const [isDisclaimerDialogOpen, setIsDisclaimerDialogOpen] = useState(false);

  const closeDisclaimerDialog = (): void => setIsDisclaimerDialogOpen(false);

  const disclaimerCheckboxTranslation = t('startPage.startInput.disclaimer.checkboxLabel', { returnObjects: true });

  const forwardUrlParams = Object.entries({ caseId, date: dateFromUrl })
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return (
    <>
      <ConfirmDialog
        open={isDisclaimerDialogOpen}
        handleClose={closeDisclaimerDialog}
        dialogTitle={t('startPage.startInput.disclaimer.dialogTitle')}
        dialogContentText={t('startPage.startInput.disclaimer.dialogContent', { returnObjects: true })}
        agree={{
          onAgree: closeDisclaimerDialog,
          text: t('startPage.startInput.disclaimer.agreeButton'),
        }}
      />
      <Box component="section" className={styles.container} {...({ ref: ref } as Props)}>
        <PoiMarker />
        <section className={styles.caseStart}>
          <div className={styles.intro}>
            <OrangeStripe />
            <Typography variant="h2">{t('startPage.startInput.title')}</Typography>
            <p>{t('startPage.startInput.description')}</p>
          </div>
          <div className={styles.caseImport}>
            <p>{t('startPage.startInput.importHint')}</p>
            <input {...getInputProps({ hidden: true, id: 'file-upload' })} />
            <label htmlFor="file-upload">
              <Button className={styles.importButton} variant="contained" color="primary" component="span">
                {t('startPage.startInput.caseImport')}
              </Button>
            </label>
          </div>
        </section>
        <section className={styles.caseIdContainer}>
          <div className={styles.caseInputContainer}>
            <TextField
              id="idInput"
              label={t('startPage.startInput.label')}
              variant="outlined"
              onChange={handleInputChange}
              placeholder="ID: 123 - 45AB - 68sH - 789 - 253456"
              defaultValue={caseId}
            />
            <Box display="flex">
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} />

              <label className={styles.checkboxLabel}>
                {disclaimerCheckboxTranslation[0]}
                <span className={styles.disclaimerLink} onClick={(): void => setIsDisclaimerDialogOpen(true)}>
                  {disclaimerCheckboxTranslation[1]}
                </span>
                {disclaimerCheckboxTranslation[2]}
              </label>
            </Box>
          </div>
          <div className={styles.startButtonContainer}>
            <Button
              className={styles.startButton}
              disabled={!isChecked || !caseId}
              variant="contained"
              color="primary"
              component={RouterLink}
              to={`/configuration/symptoms-date${forwardUrlParams.length ? `?${forwardUrlParams}` : '/'}`}
            >
              {t('startPage.startInput.begin')}
            </Button>
          </div>
        </section>
        <section className={styles.mobileIntro}>
          <OrangeStripe />
          <Typography variant="h2">{t('startPage.startInput.title')}</Typography>
          <p>{t('startPage.startInput.description')}</p>
        </section>
      </Box>
    </>
  );
});

export default CaseStartSection;
