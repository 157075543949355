import React, { FunctionComponent } from 'react';
import { NavLink, Route } from 'react-router-dom';

import Hidden from '@material-ui/core/Hidden';

import Divider from '@material-ui/core/Divider';

import { useTranslation } from 'react-i18next';

import styles from './navigation-menu.css';

/*
 * component for menu buttons in the header component
 * for tablet or desktop
 */
const MenuButtons: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Route path={['/timeline', '/contacts']}>
      <Hidden xsDown>
        <nav className={styles.navigation}>
          <NavLink to="/timeline" className={styles.menuLink} activeClassName={styles.selected}>
            {t('timelineEntries.locationHistory')}
          </NavLink>
          <Divider
            className={styles.divider}
            classes={{ root: styles.divider }}
            orientation="vertical"
            variant="middle"
          />
          <NavLink exact to="/contacts" className={styles.menuLink} activeClassName={styles.selected}>
            {t('placeVisit.contacts')}
          </NavLink>
        </nav>
      </Hidden>
    </Route>
  );
};

export default MenuButtons;
