/**
 * All actions regarding the timeline
 */

import ErrorAction from './error-action';
import Timeline, { TimelineEntry } from '../timeline';

export const ADD_TIMELINE_ENTRY = 'timeline/ADD_TIMELINE_ENTRY';
export const UPDATE_TIMELINE_ENTRY = 'timeline/UPDATE_TIMELINE_ENTRY';
export const REMOVE_TIMELINE_ENTRY = 'timeline/REMOVE_TIMELINE_ENTRY';
export const SET_SELECTED_TIMELINE_ENTRY = 'timeline/SET_SELECTED_TIMELINE_ENTRY';
export const SET_EDITING_TIMELINE_ENTRY = 'timeline/SET_EDITING_TIMELINE_ENTRY';
export const UPDATE_EDITING_TIMELINE_ENTRY = 'timeline/UPDATE_EDITING_TIMELINE_ENTRY';
export const SET_TIMELINE_DATE = 'timeline/SET_TIMELINE_DATE';
export const IMPORT_TIMELINE = 'timeline/IMPORT_TIMELINE';
export const SET_LOADING = 'timeline/SET_LOADING';

export interface AddTimelineEntryAction {
  type: typeof ADD_TIMELINE_ENTRY;
  payload: {
    timelineEntry: TimelineEntry;
  };
}

export interface UpdateTimelineEntryAction {
  type: typeof UPDATE_TIMELINE_ENTRY;
  payload: {
    id: string;
    updatedFields: Partial<TimelineEntry>;
  };
}

export interface RemoveTimelineEntryAction {
  type: typeof REMOVE_TIMELINE_ENTRY;
  payload: {
    id: string;
  };
}

export interface SetTimelineDateAction {
  type: typeof SET_TIMELINE_DATE;
  payload: {
    date: Date | null;
  };
}

export interface ImportTimelineSuccessAction {
  type: typeof IMPORT_TIMELINE;
  payload: { data: Timeline };
}
export type ImportTimelineAction = ImportTimelineSuccessAction | ErrorAction<typeof IMPORT_TIMELINE>;

export interface SetSelectedTimelineEntryAction {
  type: typeof SET_SELECTED_TIMELINE_ENTRY;
  payload: {
    timelineEntry: Partial<TimelineEntry> | null;
  };
}

export interface SetEditingTimelineEntryAction {
  type: typeof SET_EDITING_TIMELINE_ENTRY;
  payload: {
    timelineEntry: Partial<TimelineEntry> | null;
  };
}

export interface UpdateEditingTimelineEntryAction {
  type: typeof UPDATE_EDITING_TIMELINE_ENTRY;
  payload: {
    updatedFields: Partial<TimelineEntry>;
  };
}

export interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: {
    isLoading: boolean;
  };
}

export type TimelineActionTypes =
  | SetTimelineDateAction
  | AddTimelineEntryAction
  | UpdateTimelineEntryAction
  | RemoveTimelineEntryAction
  | SetSelectedTimelineEntryAction
  | SetEditingTimelineEntryAction
  | UpdateEditingTimelineEntryAction
  | ImportTimelineAction
  | SetLoadingAction;

export default TimelineActionTypes;
