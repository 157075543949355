/**
 * All actions regarding the incubation time
 */

export const SET_SYMPTOMS_DATE = 'incubationTime/SET_SYMPTOMS_DATE';

export interface SetSymptomsDateAction {
  type: typeof SET_SYMPTOMS_DATE;
  payload: { date: Date | null };
}

type IncubationTimeActionTypes = SetSymptomsDateAction;

export default IncubationTimeActionTypes;
