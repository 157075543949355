import { nanoid } from 'nanoid';

import * as actionTypes from '../types/actions/timeline-actions';
import unzipFilterAndMerge from '../utils/zip-helper';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers/index';
import Timeline, { TimelineEntry } from '../types/timeline';
import transformShape from '../utils/google-data-transform';
import limitTimelineByDateRange from '../utils/limit-timeline-by-date-range';

/**
 * Set a date for the timeline
 *
 * @param newDate A new date for the timeline
 */
export function setTimelineDate(newDate: Date | null): actionTypes.SetTimelineDateAction {
  return {
    type: actionTypes.SET_TIMELINE_DATE,
    payload: { date: newDate },
  };
}

/**
 * Add an entry to the timeline
 */
export function addTimelineEntry(timelineEntry: Omit<TimelineEntry, 'id'>): actionTypes.AddTimelineEntryAction {
  return {
    type: actionTypes.ADD_TIMELINE_ENTRY,
    payload: {
      timelineEntry: {
        ...timelineEntry,
        id: nanoid(),
      } as TimelineEntry,
    },
  };
}

/**
 * Update an entry on the timeline
 */
export function updateTimelineEntry(
  id: string,
  updatedFields: Partial<TimelineEntry>,
): actionTypes.UpdateTimelineEntryAction {
  return {
    type: actionTypes.UPDATE_TIMELINE_ENTRY,
    payload: {
      id,
      updatedFields,
    },
  };
}

/**
 * Remove an entry from the timeline
 */
export function removeTimelineEntry(id: string): actionTypes.RemoveTimelineEntryAction {
  return {
    type: actionTypes.REMOVE_TIMELINE_ENTRY,
    payload: { id },
  };
}

/**
 * Set which timeline entry to focus
 */
export function setSelectedTimelineEntry(
  timelineEntry: Partial<TimelineEntry> | null,
): actionTypes.SetSelectedTimelineEntryAction {
  return {
    type: actionTypes.SET_SELECTED_TIMELINE_ENTRY,
    payload: { timelineEntry },
  };
}

/**
 * Set which timeline entry to edit
 */
export function setEditingTimelineEntry(
  timelineEntry: Partial<TimelineEntry> | null,
): actionTypes.SetEditingTimelineEntryAction {
  return {
    type: actionTypes.SET_EDITING_TIMELINE_ENTRY,
    payload: { timelineEntry },
  };
}

/**
 * Update an entry on the timeline
 */
export function updateEditingTimelineEntry(
  updatedFields: Partial<TimelineEntry>,
): actionTypes.UpdateEditingTimelineEntryAction {
  return {
    type: actionTypes.UPDATE_EDITING_TIMELINE_ENTRY,
    payload: {
      updatedFields,
    },
  };
}

/**
 * Set loading state, e.g. to show a spinner
 *
 * @param isLoading New state
 */
export function setLoadingAction(isLoading: boolean): actionTypes.SetLoadingAction {
  return {
    type: actionTypes.SET_LOADING,
    payload: { isLoading },
  };
}

/**
 * Import new timeline data
 */
export function importTimeline(payload: Timeline | Error): actionTypes.ImportTimelineAction {
  if (payload instanceof Error) {
    return {
      type: actionTypes.IMPORT_TIMELINE,
      payload,
      error: true,
    };
  } else {
    return {
      type: actionTypes.IMPORT_TIMELINE,
      payload: {
        data: payload,
      },
    };
  }
}

/**
 * Import a new ZIP file -> Unzip file, read valid timeline data from it
 * and save it to the store.
 *
 * @param isLoading ZIP file
 */
export function importTimelineFile(
  file: File,
): ThunkAction<
  Promise<actionTypes.ImportTimelineAction>,
  RootState,
  null,
  actionTypes.ImportTimelineAction | actionTypes.SetLoadingAction
> {
  return (dispatch, getState): Promise<actionTypes.ImportTimelineAction> => {
    const { start: startTime, end: endTime } = getState().incubation;

    dispatch(setLoadingAction(true));
    return unzipFilterAndMerge(file, startTime, endTime)
      .then((googleData) => transformShape(googleData))
      .then((timeline) => limitTimelineByDateRange(timeline, startTime, endTime))
      .then((timeline) => dispatch(importTimeline(timeline)))
      .catch((error) => dispatch(importTimeline(error)))
      .finally(() => dispatch(setLoadingAction(false)));
  };
}
