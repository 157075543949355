import { FunctionComponent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useGoogleMap } from '@ubilabs/google-maps-react-hooks';
import { RootState } from '../../reducers';

import MarkerClusterer, { ClusterIconInfo, ClusterIconStyle } from '@google/markerclustererplus';
import { Cluster } from '@google/markerclustererplus/dist/cluster';

import getClusterIcon from './place-visit-cluster-icon';

import theme from '../../configs/theme';

import { selectPlaceVisits } from '../../selectors/timeline';

import sortClusterPlaceVisits from '../../utils/sort-cluster-place-visits';

interface Props {
  markers: google.maps.Marker[];
  isSelectedDateCluster: boolean;
  onClusterClick: (markerId: string) => void;
}

const MapMarkerClusterer: FunctionComponent<Props> = (props) => {
  const placeVisits = useSelector(selectPlaceVisits);
  const selectedDate = useSelector((state: RootState) => state.timeline.selectedDate);
  const { markers, isSelectedDateCluster = false, onClusterClick } = props;
  const { map } = useGoogleMap();
  const markerClusterer = useRef<MarkerClusterer>();

  enum ClusterType {
    Lower10 = 1,
    HigherOrEqual10 = 2,
  }

  const getClusterIconInfo = (markers: google.maps.Marker[]): ClusterIconInfo => {
    const markersCount = markers.length;

    const iconStylesIndex = markersCount < 10 ? ClusterType.Lower10 : ClusterType.HigherOrEqual10;

    return {
      text: String(markersCount),
      index: iconStylesIndex,
      title: 'place-visit-cluster',
    };
  };

  // The cluster marker styles for all types and sizes
  const clusterMarkerStyles: ClusterIconStyle[] = [ClusterType.Lower10, ClusterType.HigherOrEqual10].map(
    (type: ClusterType) => ({
      url: isSelectedDateCluster ? getClusterIcon('selected') : getClusterIcon(),
      height: 49,
      width: 34,
      anchorIcon: [40, 20],
      anchorText: [10, type === ClusterType.Lower10 ? 13.5 : 9.5],
      textColor: 'black',
      fontWeight: 'bold',
      textSize: 13,
      fontFamily: theme.typography.fontFamily,
    }),
  );

  // create and update marker clusterer
  useEffect(() => {
    if (map) {
      if (markerClusterer.current) {
        // remove old cluster markers and event listeners
        markerClusterer.current.clearMarkers();
        google.maps.event.clearInstanceListeners(markerClusterer.current);
      }

      const options = {
        gridSize: 20,
        zoomOnClick: false,
        calculator: getClusterIconInfo,
        styles: clusterMarkerStyles,
      };

      // create marker clusterer
      markerClusterer.current = new MarkerClusterer(map, markers, options);

      google.maps.event.addListener(markerClusterer.current, 'clusterclick', (cluster: Cluster) => {
        const currentZoom = map.getZoom();
        const markers = cluster.getMarkers();

        if (markers && markers.length !== 0) {
          const clusterAddresses = markers.map(
            (marker) => placeVisits.find((placeVisit) => placeVisit.id === marker.get('id'))?.address,
          );

          // Check if all addresses of cluster markers are equal
          if (clusterAddresses.every((clusterAddress) => clusterAddress === clusterAddresses[0]) && selectedDate) {
            // select placeVisit with start time closest to selectedDate
            const clusterPlaceVisits = placeVisits.filter((placeVisit) => placeVisit.address === clusterAddresses[0]);
            const sortedClusterPlaceVisits = clusterPlaceVisits.sort(sortClusterPlaceVisits(selectedDate));
            onClusterClick(sortedClusterPlaceVisits[0].id);
          } else if (currentZoom < 20) {
            // zoom into the cluster
            const clusterBounds = cluster.getBounds();
            map.fitBounds(clusterBounds);
          }
        }
      });
    }

    return (): void => {
      if (markerClusterer.current) {
        // remove old cluster markers and event listeners
        markerClusterer.current.clearMarkers();
        google.maps.event.clearInstanceListeners(markerClusterer.current);
      }
    };
  }, [map, placeVisits]);

  // update all clusters and markers on the map
  useEffect(() => {
    if (markerClusterer.current) {
      markerClusterer.current.clearMarkers();
      markerClusterer.current.addMarkers(markers);
    }
  }, [markers]);

  return null;
};

export default MapMarkerClusterer;
