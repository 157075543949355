import * as actionTypes from '../types/actions/global-actions';
import { SetLoadingAction } from '../types/actions/timeline-actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers/index';
import { AnyAction } from 'redux';
import { serializeStoreSnapshot } from '../utils/json-export';
import { createCsvFromTimeline, createCsvFromContacts } from '../utils/csv-export';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { readFile } from '../utils/read-file';
import { deserializeStoreSnapshot, StoreSnapshot } from '../utils/json-export';
import { setLoadingAction } from './timeline-actions';
import { getJsonContentFromZip } from '../utils/zip-helper';
import { getOrCreateCaseId } from '../selectors/case';

const ZIP_MIME_TYPE = 'application/zip';

/**
 * Resets redux store to its initial state
 */
export function resetStore(): actionTypes.ResetStoreAction {
  return {
    type: actionTypes.RESET_STORE,
  };
}

/**
 * Imports a redux store
 */
export function importStore(snapshot: StoreSnapshot): actionTypes.ImportStoreAction {
  return {
    type: actionTypes.IMPORT_STORE,
    payload: { snapshot },
  };
}

/**
 * Export json and csv files and prompt download dialog
 */
export function triggerExport(): ThunkAction<void, RootState, null, AnyAction> {
  return (_, getState): void => {
    const {
      case: { id },
      timeline: { data },
      contacts,
      incubation,
      predefinedPlaces,
    } = getState();

    const exportIdSuffix = id ? `_${id.replace(/\s/gi, '_')}` : '';
    // UTF-8 byte order mark for excel compatibility
    const bom = '\uFEFF'; // big endian
    const timelineCsv = new Blob([data.length != 0 ? bom : '', createCsvFromTimeline(data)], {
      type: 'text/plain;charset=utf-8',
    });
    const contactsCsv = new Blob([contacts.length != 0 ? bom : '', createCsvFromContacts(contacts)], {
      type: 'text/plain;charset=utf-8',
    });
    const json = new Blob(
      [serializeStoreSnapshot({ case: { id }, timeline: data, contacts, incubation, predefinedPlaces })],
      {
        type: 'application/octet-stream',
      },
    );

    const root = JSZip();
    const exportFolder = root.folder(`kadoin_export${exportIdSuffix}`);
    exportFolder?.file(`kadoin_Standortverlauf${exportIdSuffix}.csv`, timelineCsv);
    exportFolder?.file(`kadoin_Kontaktpersonen${exportIdSuffix}.csv`, contactsCsv);
    exportFolder?.file(`kadoin_export${exportIdSuffix}.json`, json);
    root.generateAsync({ type: 'blob' }).then((blob) => {
      saveAs(blob, `kadoin_export${exportIdSuffix}.zip`);
    });
  };
}

/**
 * Returns content from json directly or from zip archive
 * @param file export file
 */
function getJsonContents(file: File): Promise<string> {
  if (file.type === ZIP_MIME_TYPE) {
    return getJsonContentFromZip(file);
  }
  return readFile(file);
}

/**
 * Read a json file of a store snapshot and import it to the store
 */
export function importStoreFile(
  file: File,
): ThunkAction<
  Promise<actionTypes.ImportStoreAction>,
  RootState,
  null,
  actionTypes.ImportStoreAction | SetLoadingAction
> {
  return (dispatch): Promise<actionTypes.ImportStoreAction> => {
    dispatch(setLoadingAction(true));

    return getJsonContents(file)
      .then((jsonString: string): StoreSnapshot => deserializeStoreSnapshot(jsonString))
      .then((snapshot) => dispatch(importStore(snapshot)))
      .finally(() => dispatch(setLoadingAction(false)));
  };
}
