/**
 * All actions regarding contacts
 */

import Person, { PersonId } from '../person';

export const ADD_CONTACT_PERSON = 'contacts/ADD_CONTACT_PERSON';
export const UPDATE_CONTACT_PERSON = 'contacts/UPDATE_CONTACT_PERSON';
export const REMOVE_CONTACT_PERSON = 'contacts/REMOVE_CONTACT_PERSON';

export interface AddContactPersonAction {
  type: typeof ADD_CONTACT_PERSON;
  payload: {
    contactPerson: Person;
  };
}

export interface UpdateContactPersonAction {
  type: typeof UPDATE_CONTACT_PERSON;
  payload: {
    id: PersonId;
    updatedFields: Partial<Person>;
  };
}

export interface RemoveContactPersonAction {
  type: typeof REMOVE_CONTACT_PERSON;
  payload: {
    id: PersonId;
  };
}

type ContactPersonActionTypes = AddContactPersonAction | UpdateContactPersonAction | RemoveContactPersonAction;

export default ContactPersonActionTypes;
