import React, { FunctionComponent, forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import styles from './privacy-section.css';

interface Props {
  ref?: Ref<HTMLDivElement>;
}

const PrivacySection: FunctionComponent<Props> = forwardRef((_: Props, ref) => {
  const { t } = useTranslation();

  return (
    <Box component="section" className={styles.container} {...({ ref: ref } as Props)}>
      <Grid container justify="center" className={styles.sectionContentContainer}>
        <Grid item xs={12} container justify="center">
          <Box display="flex" flexDirection="column" alignItems="center" className={styles.introductionText}>
            <Typography variant="h3" classes={{ root: styles.title }}>
              {t('startPage.privacyPolicy.title')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={styles.contentContainer} display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="h4" classes={{ root: styles.contentTitle }}>
              {t('startPage.privacyPolicy.noPersonalDataStorageTitle')}
            </Typography>
            <Typography classes={{ root: styles.contentText }}>
              {t('startPage.privacyPolicy.noPersonalDataStorageText')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={styles.contentContainer} display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="h4" classes={{ root: styles.contentTitle }}>
              {t('startPage.privacyPolicy.safetyAndEncryptionTitle')}
            </Typography>
            <Typography classes={{ root: styles.contentText }}>
              {t('startPage.privacyPolicy.safetyAndEncryptionText')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={styles.contentContainer} display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="h4" classes={{ root: styles.contentTitle }}>
              {t('startPage.privacyPolicy.noTracingOrAnalysisTitle')}
            </Typography>
            <Typography classes={{ root: styles.contentText }}>
              {t('startPage.privacyPolicy.noTracingOrAnalysisText')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={styles.contentContainer} display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="h4" classes={{ root: styles.contentTitle }}>
              {t('startPage.privacyPolicy.noDataPersistenceTitle')}
            </Typography>
            <Typography classes={{ root: styles.contentText }}>
              {t('startPage.privacyPolicy.noDataPersistenceText')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

PrivacySection.displayName = 'PrivacySection';

export default PrivacySection;
