import PlaceVisit from './place-visit';

export enum PredefinedPlaceType {
  Home = 'home',
  Work = 'work',
}

/**
 * A predefined place that is typically visited multiple days, e.g. the home or work place of a user
 */
type PredefinedPlace = {
  type: PredefinedPlaceType;
  place: Pick<
    PlaceVisit,
    | 'type'
    | 'location'
    | 'name'
    | 'placeId'
    | 'address'
    | 'description'
    | 'confidenceStatus'
    | 'otherPlaceCandidates'
    | 'source'
  > | null;
};
export default PredefinedPlace;
