import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import ContactsListEntry from './contact-list-entry/contact-list-entry';

import { RootState } from '../../../reducers';

import Person from '../../../types/person';

import styles from './contacts-list.css';

/**
 * Component to show a list of all existing contacts
 */
const ContactsList: FunctionComponent = () => {
  const allContacts = useSelector((state: RootState) => state.contacts);

  return (
    <ul className={styles.contactsList}>
      {allContacts.map((contact: Person) => {
        return <ContactsListEntry key={contact.id} contact={contact} />;
      })}
    </ul>
  );
};

export default ContactsList;
