import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import styles from './stepper.css';

interface Props {
  isBlocked?: boolean;
  currentStep?: number;
  configurationSteps?: number;
  onBack?: () => void;
  onProceed?: () => void;
}

/**
 * Steps indicator that allows to navigate back and forth
 */
const Stepper: FunctionComponent<Props> = ({
  isBlocked,
  currentStep,
  configurationSteps,
  onBack,
  onProceed,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <MobileStepper
      variant="dots"
      steps={configurationSteps || 0}
      position="static"
      activeStep={currentStep}
      nextButton={
        <Button className={styles.nextButton} disableRipple size="large" disabled={isBlocked} onClick={onProceed}>
          {t('continue')}
          <KeyboardArrowRight />
        </Button>
      }
      backButton={
        <Button size="large" disableRipple disabled={currentStep === 0} onClick={onBack}>
          <KeyboardArrowLeft />
          {t('back')}
        </Button>
      }
    />
  );
};

export default Stepper;
