import * as actionTypes from '../types/actions/map-actions';

import { ThunkAction } from 'redux-thunk';
import Location from '../types/location';
import { RootState } from '../reducers';
import TimelineState from '../types/timeline';

/**
 * Zoom to a location
 */
export function zoomToLocation(location: Location): actionTypes.ZoomToLocationAction {
  return {
    type: actionTypes.ZOOM_TO_LOCATION,
    payload: { location },
  };
}

/**
 * Update the map bounds
 */
function updateBounds(timeline: TimelineState): actionTypes.UpdateBoundsAction {
  return {
    type: actionTypes.UPDATE_BOUNDS,
    payload: { timeline },
  };
}

/**
 * Pass updated timeline state to update bounds action to enable
 * calculation of bounds
 */
export function updateMapBounds(): ThunkAction<void, RootState, null, actionTypes.UpdateBoundsAction> {
  return (dispatch, getState): Promise<void> => {
    const { timeline } = getState();
    dispatch(updateBounds(timeline.data));
    return Promise.resolve();
  };
}
