/**
 * All actions regarding notifications
 */

import Notification from '../notification';

export const ADD_NOTIFICATION = 'notifications/ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'notifications/REMOVE_NOTIFICATION';

export interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: {
    notification: Notification;
  };
}

export interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION;
  payload: {
    id: string;
  };
}

type NotificationsActionTypes = AddNotificationAction | RemoveNotificationAction;

export default NotificationsActionTypes;
