import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import BorderedButton from '../bordered-button/bordered-button';

import { languages } from '../../i18n/i18n';

/**
 * A component to select the language
 */
const LanguageSelect: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const selectLanguage = (language: string): void => {
    setAnchorEl(null);
    i18n.changeLanguage(language);
  };

  return (
    <>
      <BorderedButton onClick={handleButtonClick}>{i18n.language.toUpperCase()}</BorderedButton>
      <Menu
        id="language-select-choices"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(): void => selectLanguage(i18n.language)}
      >
        {languages.map((language) => (
          <MenuItem key={language} onClick={(): void => selectLanguage(language)}>
            {t(`languages:${language.toLowerCase()}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelect;
