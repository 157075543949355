import React, { FunctionComponent, Fragment } from 'react';

import clsx from 'clsx';

import ActivitySegmentView from './activity-segment-view/activity-segment-view';
import ActivitySegmentEdit from './activity-segment-edit/activity-segment-edit';
import PlaceVisitAdd from '../place-visit-add/place-visit-add';
import { ActivitySegmentStatusType } from '../../types/activity-segment-status';

import styles from './activity-segment.css';
import ActivitySegment from '../../types/activity-segment';

type ActivitySegmentProps = { activitySegment: ActivitySegment; isEditing: boolean };

const ActivitySegment: FunctionComponent<ActivitySegmentProps> = ({ activitySegment, isEditing }) => (
  <Fragment key={activitySegment.id}>
    <div className={clsx(isEditing && styles.activitySegmentIsEditing)}>
      <div className={styles.activitySegment}>
        {isEditing ? (
          <ActivitySegmentEdit selectedActivitySegment={activitySegment} />
        ) : (
          <ActivitySegmentView activitySegment={activitySegment} />
        )}
      </div>
      <div className={styles.activitySegment}>
        <PlaceVisitAdd mode="compact" status={ActivitySegmentStatusType.Active} />
      </div>
    </div>
  </Fragment>
);

export default ActivitySegment;
