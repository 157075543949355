import React, { FunctionComponent } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import styles from './timeline-notification.css';

interface Props {
  title: string;
  message: string | JSX.Element;
  onClose: () => void;
}

/**
 * A message in the timeline
 */
const TimelineNotification: FunctionComponent<Props> = ({ title, message, onClose }: Props) => {
  return (
    <Paper className={styles.container} variant="outlined">
      <InfoOutlinedIcon className={styles.infoIcon} />
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div>{message}</div>
      </div>
      <IconButton className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Paper>
  );
};

export default TimelineNotification;
