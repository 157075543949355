import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import HelpPageStep from '../help-page-step/help-page-step';

import ScreenShotMobile from '../../../../assets/images/help-page/receive-location-history-mobile.png';
import ScreenShot from '../../../../assets/images/help-page/receive-location-history.jpg';

import styles from './receive-location-history-section.css';

const ReceiveLocationHistorySection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box component="section" display="flex" flexDirection="column" className={styles.container}>
      <HelpPageStep
        step={4}
        title={t('helpPage.receiveLocationHistory.title')}
        description={t('helpPage.receiveLocationHistory.description')}
      />
      <picture>
        <source media="(max-width: 749px)" srcSet={`${ScreenShotMobile} 300w`} />
        <source media="(min-width: 750px)" srcSet={`${ScreenShot} 800w`} />
        <img className={styles.image} alt="screenshot of how to receive location history" />
      </picture>
    </Box>
  );
};

export default ReceiveLocationHistorySection;
