import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import AddLocationIcon from '../../../assets/icons/add-location.svg';

import styles from './add-location-button.css';

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

/**
 * A button to add a location
 */
const AddLocationButton: FunctionComponent<Props> = ({ onClick, disabled }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      className={styles.button}
      size="large"
      onClick={onClick}
      disabled={disabled}
      startIcon={<AddLocationIcon className={styles.markerIcon} />}
    >
      <Typography>{t('addLocation')}</Typography>
    </Button>
  );
};

export default AddLocationButton;
