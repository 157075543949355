import JSZip, { JSZipObject } from 'jszip';
import GoogleLocationHistory from '../types/google-location-history';
import { eachMonthOfInterval } from 'date-fns';
import monthNames from '../types/month-names';

/**
 * Extending JSZip interface to annotate files with "MM/YYYY"
 */
declare module 'jszip' {
  interface JSZipObject {
    month: string;
  }
}

/**
 * Will unzip, filter out undesired files and merge remaining files from a ZIP file obtained by Google
 * Takeout into a single object.
 *
 * @param file ZIP file
 * @param startDate Start of desired time range
 * @param endDate End of desired time range
 */
function unzipFilterAndMerge(
  file: File,
  startDate?: Date | null,
  endDate?: Date | null,
): Promise<GoogleLocationHistory> {
  // This RegEx only matches the innermost folder of the Google Takeout archive. All the other, upper folders are language dependent
  const relevantFilesRegex = /Semantic Location History\/(\d{4})\/\d{4}_(JANUARY|FEBRUARY|MARCH|APRIL|MAY|JUNE|JULY|AUGUST|SEPTEMBER|OCTOBER|NOVEMBER|DECEMBER)\.json$/;
  return new Promise((resolve, reject) => {
    const newTimeline: GoogleLocationHistory = [];
    JSZip.loadAsync(file, { createFolders: true })
      // Step 1: Filter out all other files besides monthly json files
      .then((zipContent) => zipContent.file(relevantFilesRegex))
      // Step 2: Annotate files with year and month, so we can filter them easily
      .then((relevantFiles) => {
        if (!relevantFiles.length) {
          reject(new Error('timelineEntries.importFileError.notReadable'));
          return [];
        }

        relevantFiles.forEach((file) => {
          const match = file.name.match(relevantFilesRegex);
          const year = match && match.length > 1 && match[1];
          const month = match && match.length > 2 && monthNames.indexOf(match[2].toLowerCase()) + 1;
          if (year && month) {
            file.month = `${month}/${year}`;
          }
        });
        return relevantFiles;
      })
      // Step 3: Remove files that are outside desired date range
      .then((dateAnnotatedFiles) => {
        let monthsInInterval: Array<string>;
        if (startDate && endDate) {
          monthsInInterval = eachMonthOfInterval({
            start: startDate,
            end: endDate,
          }).map((firstDayOfMonth) => `${firstDayOfMonth.getMonth() + 1}/${firstDayOfMonth.getFullYear()}`);

          const filteredFiles = dateAnnotatedFiles.filter(({ month }) => monthsInInterval.includes(month));
          return filteredFiles;
        }
        return dateAnnotatedFiles;
      })
      // Step 4: Read and concatenate all remaining files, throw error if no data can be extracted
      .then((filteredFiles) => {
        const promises: Promise<void>[] = [];
        filteredFiles.forEach((monthFile) => {
          promises.push(
            monthFile.async('text').then((value) => {
              const { timelineObjects } = JSON.parse(value);
              timelineObjects && newTimeline.push(...timelineObjects);
            }),
          );
        });
        Promise.all(promises).then(() => {
          resolve(newTimeline);
        });
      })
      .catch(reject);
  });
}

/**
 * Returns contents of json inside zip archive as text
 * @param file ZIP File
 */
export function getJsonContentFromZip(file: File): Promise<string> {
  const zip = JSZip();
  return new Promise((resolve, reject) => {
    zip.loadAsync(file).then(({ files }) => {
      const json = Object.values(files).find((file: JSZipObject) => file.name.endsWith('.json'));
      json?.async('text').then(resolve).catch(reject);
    });
  });
}

export default unzipFilterAndMerge;
