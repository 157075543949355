import React, { FunctionComponent, forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

import MHHLogo from '../../../../assets/images/start-page/mhh-logo.svg';
import UbilabsLogo from '../../../../assets/images/start-page/ubilabs-logo.svg';
import BMGLogo from '../../../../assets/images/start-page/bmg-logo.svg';

import styles from './partner-section.css';

interface Props {
  ref?: Ref<HTMLDivElement>;
}

const PartnerSection: FunctionComponent<Props> = forwardRef((_: Props, ref) => {
  const { t } = useTranslation();

  return (
    <section className={styles.container} ref={ref}>
      <Typography className={styles.partnerTitleText}>{t('startPage.partner.title')}</Typography>
      <div className={styles.contentContainer}>
        <div className={styles.partnerContainer}>
          <a className={styles.logoContainer} target="_blank" rel="noopener noreferrer" href="https://www.mhh.de/">
            <MHHLogo className={styles.mhhLogo} />
          </a>
          <a className={styles.logoContainer} target="_blank" rel="noopener noreferrer" href="https://ubilabs.net/">
            <UbilabsLogo className={styles.ubilabsLogo} />
          </a>
        </div>
        <>
          <Typography className={styles.encouragedByText}>{t('startPage.partner.encouragedBy')}</Typography>
          <a
            className={styles.logoContainer}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.bundesgesundheitsministerium.de/"
          >
            <BMGLogo />
          </a>
        </>
      </div>
    </section>
  );
});

PartnerSection.displayName = 'PartnerSection';

export default PartnerSection;
