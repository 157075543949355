import React, { FunctionComponent, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { parse, isSameDay } from 'date-fns';
import de from 'date-fns/locale/de';

import { DesktopDateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import styles from './place-visit-date-time-picker.css';

interface Props {
  label: string;
  ariaLabel: string;
  minTime?: Date;
  value: Date | undefined;
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
}

const PlaceVisitDateTimePicker: FunctionComponent<Props> = ({
  label,
  ariaLabel,
  minTime,
  value,
  selectedDate,
  onChange,
}: Props) => {
  const { incubation } = useSelector((state: RootState) => ({
    incubation: state.incubation,
  }));
  const [inputValue, setInputValue] = useState('');

  /**
   * completes invalid time input values e.g. '12' or '12:' is changed to '12:00'
   */
  const autocompleteInputValue = useCallback((value: string): string => {
    let autocompletedValue = value;
    if (!value) {
      autocompletedValue = '00:00';
    } else if (value.slice(-1) === ':') {
      autocompletedValue = value + '00';
    } else if (value.slice(-2)[0] === ':') {
      autocompletedValue = value + '0';
    } else {
      const numberValue = Number(value);
      if (numberValue <= 9) {
        autocompletedValue = '0' + numberValue + ':00';
      } else if (numberValue <= 24) {
        autocompletedValue = numberValue + ':00';
      }
    }
    return autocompletedValue;
  }, []);

  /**
   * Custom Adapter to allow incomplete time inputs to PlaceVisit form
   */
  class CustomDateFnsAdapter extends DateFnsAdapter {
    parse(value: string, formatString: string): Date {
      return parse(autocompleteInputValue(value), formatString, selectedDate || new Date());
    }
  }

  return (
    <LocalizationProvider locale={de} dateAdapter={CustomDateFnsAdapter}>
      <DesktopDateTimePicker
        ampm={false}
        inputFormat="HH:mm"
        toolbarFormat="dd. MMMM"
        label={label}
        disableHighlightToday
        openTo="hours"
        minTime={minTime && value && isSameDay(minTime, value) ? minTime : null}
        minDate={incubation.start}
        maxDate={incubation.end}
        renderInput={(props): JSX.Element => (
          <TextField
            margin="normal"
            className={styles.timepicker}
            id="place-visit-time-picker"
            {...props}
            onChange={(event): void => {
              setInputValue(event.target.value);
              if (props.onChange) {
                props?.onChange(event);
              }
            }}
            onBlur={(event): void => {
              setInputValue(autocompleteInputValue(inputValue));
              if (props.onBlur) {
                props?.onBlur(event);
              }
            }}
          />
        )}
        value={value}
        onChange={(date): void => {
          onChange(date as Date);
        }}
        openPickerIcon={<AccessTimeIcon />}
        OpenPickerButtonProps={{
          'aria-label': ariaLabel,
        }}
      />
    </LocalizationProvider>
  );
};

export default PlaceVisitDateTimePicker;
