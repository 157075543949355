import ActionTypes from '../types/actions';
import { SET_CASE_ID } from '../types/actions/case-actions';
import { nanoid } from 'nanoid';

export type CaseState = {
  id: string | null;
};

const initialState: CaseState = {
  id: nanoid(),
};

/**
 * Handle the case state
 */
function caseReducer(state: CaseState = initialState, action: ActionTypes): CaseState {
  switch (action.type) {
    case SET_CASE_ID: {
      return { ...state, id: action.payload.id };
    }

    default:
      return state;
  }
}

export default caseReducer;
