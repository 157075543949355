/**
 * Replaces new lines in addresses with commas
 *
 * @param address Address of format e.g. "Heidi-Kabel-Platz\n20099 Hamburg\nDeutschland"
 * @returns Address of format e.g. "Heidi-Kabel-Platz, 20099 Hamburg, Deutschland"
 */

export default function formatAddress(address: string): string {
  return address.split('\n').join(', ');
}

/**
 * Cuts off the last segment of an comma separated address (typically the country name).
 *
 * @param address Address of format e.g. "Heidi-Kabel-Platz, 20099 Hamburg, Deutschland"
 * @returns Address of format e.g. "Heidi-Kabel-Platz, 20099 Hamburg"
 */
export function trimAddress(address: string): string {
  if (address.includes(', ')) {
    return address.split(', ').slice(0, -1).join(', ');
  }
  return address;
}
