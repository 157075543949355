import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';

import { importTimelineFile } from '../../../../actions/timeline-actions';

import Stepper from '../stepper/stepper';
import FileSelector from '../../../file-selector/file-selector';

import styles from './select-location-history.css';
import sidebarStyles from '../../sidebar.css';

interface Props {
  currentConfigurationStep: number;
  configurationSteps: number;
  onOpenOverlay: () => void;
}

type DataSource = 'manual' | 'import';

/**
 * Select a location history source: Google Takeout or manual
 */
const SelectLocationHistory: FunctionComponent<Props> = ({
  configurationSteps,
  currentConfigurationStep,
  onOpenOverlay,
}: Props) => {
  const [value, setValue] = useState<DataSource>('import');
  const [selectedFile, setSelectedFile] = useState<File | ''>('');

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const preventDefault = (event: React.SyntheticEvent): void => event.preventDefault();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue((event.target as HTMLInputElement).value as DataSource);
  };

  const handleClick = (event: React.SyntheticEvent): void => {
    preventDefault(event);
    onOpenOverlay();
  };

  return (
    <>
      <div className={clsx(sidebarStyles.sidebarContent, styles.sidebarContentContainer)}>
        <div>
          <Typography variant="h3">{t('locationHistory.selectTitle')}</Typography>
          <p className={styles.infoText}>{t('locationHistory.selectDescription')}</p>
          <FormControl component="fieldset">
            <FormLabel component="legend" hidden>
              {t('locationHistory.selectTitle')}
            </FormLabel>
            <RadioGroup
              aria-label={t('locationHistory.selectTitle')}
              name={t('locationHistory.selectTitle')}
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="manual"
                control={<Radio className={styles.radioButton} />}
                className={styles.label}
                label={t('locationHistory.sourceManual')}
              />
              <FormControlLabel
                value="import"
                className={styles.label}
                control={<Radio className={styles.radioButton} />}
                label={t('locationHistory.sourceImport')}
              />
            </RadioGroup>
          </FormControl>
          {value === 'import' && (
            <FileSelector
              selectedFile={selectedFile}
              onSelectFile={(selectedFile): void => {
                setSelectedFile(selectedFile);
                if (selectedFile) {
                  dispatch(importTimelineFile(selectedFile));
                  history.push('/timeline');
                }
              }}
            />
          )}
        </div>
        <Link className={styles.link} onClick={handleClick}>
          <InfoOutlinedIcon className={styles.icon} />
          {t('locationHistory.exportGoogleHelp')}
        </Link>
      </div>

      <Stepper
        isBlocked={value === 'import'}
        currentStep={currentConfigurationStep}
        configurationSteps={configurationSteps}
        onBack={(): void => {
          history.push('/configuration/symptoms-date');
        }}
        onProceed={(): void => {
          history.push('/timeline');
        }}
      />
    </>
  );
};

export default SelectLocationHistory;
