import React, { FunctionComponent, Ref, forwardRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { noImport } from '../../../configs/no-import';
import { mediaQueries } from '../../../configs/theme';

import MobileStepper from '@material-ui/core/MobileStepper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SwipeableViews from 'react-swipeable-views';

import NotebookFrame from '../../../../assets/images/start-page/tutorial/notebook_frame.png';
import NotebookScreenStart from '../../../../assets/images/start-page/tutorial/notebook_start.png';
import NotebookScreenTimeline from '../../../../assets/images/start-page/tutorial/notebook_timeline.png';
import NotebookScreenKontakte from '../../../../assets/images/start-page/tutorial/notebook_kontakte.png';
import NotebookScreenExport from '../../../../assets/images/start-page/tutorial/notebook_export.png';
import TabletFrame from '../../../../assets/images/start-page/tutorial/tablet_frame.png';
import TabletScreenStart from '../../../../assets/images/start-page/tutorial/tablet_start.png';
import TabletScreenTimeline from '../../../../assets/images/start-page/tutorial/tablet_timeline.png';
import TabletScreenKontakte from '../../../../assets/images/start-page/tutorial/tablet_kontakte.png';
import SmartphoneFrame from '../../../../assets/images/start-page/tutorial/smartphone_frame.png';
import SmartphoneScreenStart from '../../../../assets/images/start-page/tutorial/smartphone_start.png';
import SmartphoneScreenTimeline from '../../../../assets/images/start-page/tutorial/smartphone_timeline.png';
import SmartphoneScreenKontakte from '../../../../assets/images/start-page/tutorial/smartphone_kontakte.png';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeftOutlined';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRightOutlined';

import styles from './tutorial-segment.css';

interface Props {
  ref?: Ref<HTMLDivElement>;
}

const TutorialSegment: FunctionComponent<Props> = forwardRef((_: Props, ref) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(mediaQueries.mobile);
  const isTablet = useMediaQuery(mediaQueries.tablet);
  const isDesktop = useMediaQuery(mediaQueries.desktop);
  const [activeStep, setActiveStep] = React.useState(0);
  const notebookScreens = [
    NotebookScreenStart,
    NotebookScreenTimeline,
    NotebookScreenKontakte,
    NotebookScreenTimeline,
    NotebookScreenExport,
  ];
  const tabletScreens = [
    TabletScreenStart,
    TabletScreenTimeline,
    TabletScreenKontakte,
    TabletScreenTimeline,
    TabletScreenTimeline,
  ];
  const smartphoneScreens = [
    SmartphoneScreenStart,
    SmartphoneScreenTimeline,
    SmartphoneScreenKontakte,
    SmartphoneScreenTimeline,
    SmartphoneScreenTimeline,
  ];
  const steps = noImport ? 4 : 5;

  function mod(n: number, m: number): number {
    return ((n % m) + m) % m;
  }

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => mod(prevActiveStep + 1, steps));
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => mod(prevActiveStep - 1, steps));
  };

  const getStepContent = (step: number, contentType: 'text' | 'title', noImport: boolean): JSX.Element => {
    // if no-import-build, skip step location history.
    const index = noImport && step > 1 ? step + 1 : step;
    switch (index) {
      case 1:
        return <Trans i18nKey={`startPage.quickGuide.steps.beginOfSymptoms.${contentType}`} />;
      case 2:
        return <Trans i18nKey={`startPage.quickGuide.steps.locationHistory.${contentType}`} />;
      case 3:
        return <Trans i18nKey={`startPage.quickGuide.steps.placesAndContacts.${contentType}`} />;
      case 4:
        return <Trans i18nKey={`startPage.quickGuide.steps.routes.${contentType}`} />;
      case 5:
        return <Trans i18nKey={`startPage.quickGuide.steps.export.${contentType}`} />;
      default:
        return <></>;
    }
  };

  return (
    <Box component="section" className={styles.container} {...({ ref: ref } as Props)}>
      <Box className={styles.imageOverlayContainer}>
        {isDesktop && (
          <>
            <img className={styles.notebookContent} src={notebookScreens[activeStep]} />
            <img className={styles.notebookFrame} src={NotebookFrame} />
          </>
        )}
        {isTablet && (
          <>
            <img className={styles.tabletContent} src={tabletScreens[activeStep]} />
            <img className={styles.tabletFrame} src={TabletFrame} />
          </>
        )}
        {isMobile && (
          <>
            <img className={styles.smartphoneContent} src={smartphoneScreens[activeStep]} />
            <img className={styles.smartphoneFrame} src={SmartphoneFrame} />
          </>
        )}
      </Box>
      <Box className={styles.contentContainer}>
        <SwipeableViews slideClassName={styles.textContainer} index={activeStep} onChangeIndex={setActiveStep}>
          {Array.from({ length: steps }, (_v, k) => k + 1).map((step) => (
            <React.Fragment key={step}>
              <h2>
                {t('startPage.quickGuide.step', { step })}
                {getStepContent(step, 'title', noImport)}
              </h2>
              <p className={styles.highlight}>{getStepContent(step, 'text', noImport)}</p>
            </React.Fragment>
          ))}
        </SwipeableViews>
        <MobileStepper
          className={styles.stepper}
          variant="dots"
          steps={steps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button className={styles.stepperButtonNext} variant="outlined" onClick={handleNext}>
              <KeyboardArrowRight fontSize="large" />
            </Button>
          }
          backButton={
            <Button className={styles.stepperButtonBack} variant="outlined" onClick={handleBack}>
              <KeyboardArrowLeft fontSize="large" />
            </Button>
          }
        />
      </Box>
    </Box>
  );
});

TutorialSegment.displayName = 'TutorialSegment';

export default TutorialSegment;
