import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { updateContactPerson, addContactPerson, removeContactPerson } from '../../actions/contacts-actions';

import ContactPersonForm from '../contact-person-form/contact-person-form';

import Person from '../../types/person';

interface Props {
  contactPerson?: Partial<Person>;
  open: boolean;
  onDone: () => void;
}

/**
 * Component to edit or add a contact person
 */
const ContactPersonEdit: FunctionComponent<Props> = ({ contactPerson, open, onDone }: Props) => {
  const dispatch = useDispatch();
  const isNewPerson = !Boolean(contactPerson && contactPerson.id);

  /**
   * Save the updated contact person
   */
  const handleSave = (updatedContactPerson: Omit<Person, 'id'>, createAnotherAfterSave?: boolean): void => {
    if (isNewPerson) {
      dispatch(addContactPerson(updatedContactPerson));
    } else {
      dispatch(updateContactPerson(contactPerson?.id as string, updatedContactPerson));
    }

    if (!createAnotherAfterSave) {
      onDone();
    }
  };

  /**
   * Handle the cancelling of an edit
   */
  const handleCancel = useCallback((): void => {
    onDone();
  }, []);

  /**
   * Handle the deletion of a contact
   */
  const handleDelete = (): void => {
    dispatch(removeContactPerson(contactPerson?.id as string));
    onDone();
  };

  return (
    <ContactPersonForm
      titleKey={isNewPerson ? 'contactPerson.addContact' : 'contactPerson.editContact'}
      open={open}
      allowToCreateAnother={isNewPerson}
      allowDelete={!isNewPerson}
      onSave={handleSave}
      onCancel={handleCancel}
      {...(contactPerson && { contactPerson, onDelete: handleDelete })}
    />
  );
};

export default ContactPersonEdit;
