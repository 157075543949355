import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose, Middleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducers/index';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import './i18n/i18n';

import App from './components/app';
import StartPage from './components/start-page/start-page';
import HelpPage from './components/help-page/help-page';
import theme from './configs/theme';
import ProtectedRoute from './components/protected-route/protected-route';

const middlewares: Middleware[] = [];
middlewares.push(thunk);

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const store = compose(applyMiddleware(...middlewares))(createStore)(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ProtectedRoute>
            <Switch>
              <Route exact path="/">
                <StartPage />
              </Route>
              <Route path="/help">
                <HelpPage isOverlay={false} />
              </Route>
              <Route>
                <App />
              </Route>
            </Switch>
          </ProtectedRoute>
        </ThemeProvider>
      </StylesProvider>
    </HashRouter>
  </Provider>,
  document.getElementById('app'),
);
