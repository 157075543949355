import React, { FunctionComponent } from 'react';

import IconButton from '@material-ui/core/Button';

import ActivitySegmentLine from '../activity-segment/activity-segment-line/activity-segment-line';

import { ActivitySegmentStatusType } from '../../types/activity-segment-status';

import AddLocationCompact from '../../../assets/icons/add-location.svg';

import styles from './add-location-compact-button.css';

interface Props {
  disabled?: boolean;
  onClick: () => void;
  status: ActivitySegmentStatusType;
}

/**
 * A button to add a location in compact mode
 */
const AddLocationCompactButton: FunctionComponent<Props> = ({ onClick, disabled, status }: Props) => {
  return (
    <>
      <IconButton disableFocusRipple disableRipple className={styles.button} onClick={onClick} disabled={disabled}>
        <AddLocationCompact />
      </IconButton>
      <ActivitySegmentLine status={status} type="short" />
    </>
  );
};

export default AddLocationCompactButton;
