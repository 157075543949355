import React, { useState, useMemo, useEffect, FunctionComponent, ChangeEvent, useCallback } from 'react';
import { useGoogleMap } from '@ubilabs/google-maps-react-hooks';

import TextField from '@material-ui/core/TextField';
import AutocompleteUI from '@material-ui/lab/Autocomplete';

import AutoCompletePredictionOption from './autocomplete-prediction-option/autocomplete-prediction-option';
import PredefinedPlaceOption from './predefined-place-option/predifined-place-option';

import throttle from 'lodash/throttle';

import { initialLocation } from '../../../configs/map';
import Location from '../../../types/location';
import PredefinedPlace from '../../../types/predefined-place';

import styles from './autocomplete.css';

let autocompleteService: google.maps.places.AutocompleteService | null = null;
let placesService: google.maps.places.PlacesService | null = null;

export interface AutocompletePlace {
  location: Location;
  address?: string;
  name: string;
  placeId?: string;
}

const isPredefinedPlace = (
  place: PredefinedPlace | google.maps.places.AutocompletePrediction,
): place is PredefinedPlace => (place as PredefinedPlace).type !== undefined;

interface Props {
  defaultPlace?: AutocompletePlace | null;
  onChange: (place: AutocompletePlace | null) => void;
  placeholder?: string;
  predefinedPlacesOptions?: {
    predefinedPlaces: PredefinedPlace[];
    onEdit: (predefinedPlace: PredefinedPlace) => void;
  };
}

/**
 * A input component to autocomplete Places via Google API
 */
const Autocomplete: FunctionComponent<Props> = ({
  defaultPlace,
  onChange,
  placeholder,
  predefinedPlacesOptions,
}: Props) => {
  const { map } = useGoogleMap();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Array<google.maps.places.AutocompletePrediction | PredefinedPlace>>([]);
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | undefined>(undefined);

  useEffect(() => {
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  }, []);

  const defaultValue = useMemo(() => {
    if (defaultPlace?.address && defaultPlace?.placeId) {
      setInputValue(defaultPlace.address);
      return {
        description: defaultPlace.address,
        // eslint-disable-next-line @typescript-eslint/camelcase
        place_id: defaultPlace.placeId,
      } as google.maps.places.AutocompletePrediction;
    } else {
      return undefined;
    }
  }, [defaultPlace]);

  const handleChange = useCallback(
    (_: ChangeEvent<unknown>, option: google.maps.places.AutocompletePrediction | PredefinedPlace | null): void => {
      if (!option) {
        onChange(null);
        return;
      }
      if (!placesService && google && map) {
        placesService = new google.maps.places.PlacesService(map);
      }
      if (!placesService) {
        return;
      }

      if (isPredefinedPlace(option)) {
        if (option.place) {
          onChange(option.place);
        }
        return;
      }

      placesService.getDetails({ placeId: option.place_id, sessionToken }, (place, status) => {
        // Session token can be a series of .getPlacePredictions and one .getDetails call,
        // so we reset the token after the .getDetails call
        setSessionToken(new google.maps.places.AutocompleteSessionToken());

        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return;
        }

        if (!place.geometry) {
          return;
        }

        onChange({
          name: place.name,
          address: place.formatted_address,
          placeId: place.place_id,
          location: {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          },
        });
      });
    },
    [placesService, google, map],
  );

  const fetchPredictions = useMemo(() => {
    return throttle(
      (
        request: google.maps.places.AutocompletionRequest,
        callback: (results?: google.maps.places.AutocompletePrediction[]) => void,
      ) => {
        autocompleteService?.getPlacePredictions(request, callback);
      },
      200,
    );
  }, []);

  useEffect(() => {
    let active = true;

    if (!autocompleteService && google) {
      autocompleteService = new google.maps.places.AutocompleteService();
    }
    if (!autocompleteService) {
      return;
    }

    if (inputValue === '') {
      setOptions(predefinedPlacesOptions?.predefinedPlaces || []);
      return;
    }

    const lat = defaultPlace?.location?.latitude || initialLocation.lat;
    const lng = defaultPlace?.location?.longitude || initialLocation.lng;
    const request: google.maps.places.AutocompletionRequest = {
      input: inputValue,
      location: new google.maps.LatLng(lat, lng),
      radius: 1000,
      sessionToken,
    };

    fetchPredictions(request, (results?: google.maps.places.AutocompletePrediction[]) => {
      if (active) {
        setOptions([...(predefinedPlacesOptions?.predefinedPlaces || []), ...(results || [])]);
      }
    });

    return (): void => {
      active = false;
    };
  }, [inputValue, fetchPredictions, defaultPlace]);

  useEffect(() => {
    const predictions = options.filter((option) => !isPredefinedPlace(option));
    setOptions([...(predefinedPlacesOptions?.predefinedPlaces || []), ...predictions]);
  }, [predefinedPlacesOptions?.predefinedPlaces]);

  return (
    <AutocompleteUI
      className={styles.autocomplete}
      getOptionLabel={(option: google.maps.places.AutocompletePrediction | PredefinedPlace): string => {
        if (isPredefinedPlace(option)) {
          return option?.place?.address || '';
        } else {
          return typeof option.description === 'string' ? option.description : '';
        }
      }}
      filterOptions={(
        x: Array<google.maps.places.AutocompletePrediction | PredefinedPlace>,
      ): Array<google.maps.places.AutocompletePrediction | PredefinedPlace> => x}
      options={options}
      selectOnFocus
      autoComplete
      freeSolo={!predefinedPlacesOptions}
      includeInputInList
      onChange={(_, option): void =>
        handleChange(_, option as PredefinedPlace | google.maps.places.AutocompletePrediction)
      }
      onInputChange={(_, value): void => setInputValue(value)}
      defaultValue={defaultValue}
      inputValue={inputValue}
      getOptionDisabled={(option): boolean => isPredefinedPlace(option) && !option.place}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          autoFocus
          onFocus={(event): void => event.target.select()}
          className={styles.input}
          placeholder={placeholder}
          variant="standard"
          fullWidth
        />
      )}
      renderOption={(option: google.maps.places.AutocompletePrediction | PredefinedPlace): JSX.Element => {
        if (isPredefinedPlace(option)) {
          return <PredefinedPlaceOption predefinedPlace={option} onEdit={predefinedPlacesOptions?.onEdit} />;
        } else {
          return <AutoCompletePredictionOption prediction={option} />;
        }
      }}
    />
  );
};

export default Autocomplete;
