import { combineReducers } from 'redux';
import ActionTypes from '../types/actions';
import { RESET_STORE, IMPORT_STORE } from '../types/actions/global-actions';
import caseReducer from './case-reducer';
import contacts from './contacts-reducer';
import incubation from './incubation-time-reducer';
import map from './map-reducer';
import notifications from './notifications-reducer';
import timeline from './timeline-reducer';
import privacyDialog from './privacy-dialog-reducer';
import predefinedPlaces from './predefined-places-reducer';
import { mergeSnaphotWithStore } from '../utils/json-export';

const appReducer = combineReducers({
  case: caseReducer,
  contacts,
  incubation,
  map,
  notifications,
  timeline,
  predefinedPlaces,
  privacyDialog,
});

const rootReducer = (state: RootState | undefined, action: ActionTypes): RootState => {
  switch (action.type) {
    case RESET_STORE: {
      return appReducer(undefined, action);
    }

    case IMPORT_STORE: {
      const mergedState = mergeSnaphotWithStore(state as RootState, action.payload.snapshot);
      return appReducer(mergedState, action);
    }

    default:
      return appReducer(state, action);
  }
};

export type RootState = ReturnType<typeof appReducer>;
export default rootReducer;
