import { primaryColor, secondaryColor } from '../../configs/theme';

const colors = {
  default: primaryColor,
  selected: secondaryColor,
};

export type PlaceVisitClusterIconType = 'selected' | 'default';

/**
 * The placeVisit cluster icon
 */
export default (type: PlaceVisitClusterIconType = 'default'): string => {
  const color = colors[type];
  const generateSvg = (color: string): string =>
    `<svg width="34" height="46" viewBox="0 0 34 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.06152 24.8468C2.75138 22.5945 2 19.9699 2 17.1679C2 8.7909 8.71573 2 17 2C25.2843 2 32 8.7909 32 17.1679C32 19.9699 31.2486 22.5945 29.9385 24.8468H29.9465C29.2284 25.9816 28.3162 27.8752 22.9193 32.3814C17.5223 36.8875 19.0328 43.1064 16.9947 43.1064H17.0053C14.9672 43.1064 16.4777 36.8875 11.0807 32.3814C5.68379 27.8752 4.77157 25.9816 4.05351 24.8468H4.06152Z" fill="${color}"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.06152 24.8468C2.75138 22.5945 2 19.9699 2 17.1679C2 8.7909 8.71573 2 17 2C25.2843 2 32 8.7909 32 17.1679C32 19.9699 31.2486 22.5945 29.9385 24.8468H29.9465C29.2284 25.9816 28.3162 27.8752 22.9193 32.3814C17.5223 36.8875 19.0328 43.1064 16.9947 43.1064H17.0053C14.9672 43.1064 16.4777 36.8875 11.0807 32.3814C5.68379 27.8752 4.77157 25.9816 4.05351 24.8468H4.06152Z" stroke="white" stroke-width="2"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1292 29.2884C23.678 29.2884 28.9868 23.9201 28.9868 17.298C28.9868 10.6759 23.678 5.30762 17.1292 5.30762C10.5803 5.30762 5.27148 10.6759 5.27148 17.298C5.27148 23.9201 10.5803 29.2884 17.1292 29.2884Z" fill="white" fill-opacity="0.87"/>
  <defs>
  <filter id="filter0_d" x="0" y="0" width="34" height="45.1064" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="0.5"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
  </filter>
  </defs>
  </svg>  
  `;

  const svg = encodeURIComponent(generateSvg(color));

  return `data:image/svg+xml;utf-8,${svg}`;
};
