import React, { useState, FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import ActivitySegmentLine from '../../activity-segment/activity-segment-line/activity-segment-line';

import EditContacts from '../../contacts/contacts-edit';

import { PersonId } from '../../../types/person';
import { isPlaceVisit, TimelineEntryType } from '../../../types/timeline';
import { ActivitySegmentStatusType } from '../../../types/activity-segment-status';
import ActivitySegment from '../../../types/activity-segment';

import { RootState } from '../../../reducers';

import { updateEditingTimelineEntry } from '../../../actions/timeline-actions';

import styles from './activity-segment-form.css';

interface Props {
  onSave: (activitySegment: Omit<ActivitySegment, 'id'>) => void;
  onCancel?: () => void;
}

/**
 * A form to change or add comments or contact people of an activity segment
 */
const ActivitySegmentForm: FunctionComponent<Props> = ({ onSave, onCancel }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { editingEntry } = useSelector((state: RootState) => ({
    editingEntry: state.timeline.editingEntry,
  }));

  /**
   * Handle change of the comment field
   */
  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(updateEditingTimelineEntry({ comment: event.target.value }));
  };

  /**
   * Handle contacts field input
   */
  const handleContactsChange = (updatedContacts: PersonId[] | null): void => {
    dispatch(updateEditingTimelineEntry({ contacts: updatedContacts || [] }));
  };

  /**
   * Form error handling for input fields
   */

  const [errors, setErrors] = useState(false);
  useEffect(() => {
    if (!editingEntry || editingEntry.type !== TimelineEntryType.ActivitySegment) {
      setErrors(true);
      return;
    }
  }, [editingEntry]);

  /**
   * Handle activity segment on form submit
   */
  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();

    if (errors) {
      return;
    }

    onSave({
      ...editingEntry,
      comment: editingEntry?.comment,
      contacts: editingEntry?.contacts,
    } as Omit<ActivitySegment, 'id'>);
  };

  if (editingEntry && isPlaceVisit(editingEntry)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <ActivitySegmentLine status={ActivitySegmentStatusType.Active} type="default" />

      <form onSubmit={handleSubmit} noValidate autoComplete="off" className={styles.formContainer}>
        <div className={styles.contentContainer}>
          <EditContacts onChange={handleContactsChange} contactIds={editingEntry?.contacts || []} />

          <TextField
            id="notes-field"
            multiline
            rowsMax={4}
            className={styles.commentField}
            label={t('placeVisit.notes')}
            onChange={handleCommentChange}
            value={editingEntry?.comment}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onCancel}>{t('cancel')}</Button>
          <Button className={styles.saveButton} variant="contained" type="submit" disabled={errors}>
            {t('save')}
          </Button>
        </div>
      </form>
    </div>
  );
};

ActivitySegmentForm.displayName = 'ActivitySegmentForm';

export default ActivitySegmentForm;
