import React, { FunctionComponent, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import styles from './export-footer.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../reducers/index';
import { useDispatch } from 'react-redux';
import { resetStore, triggerExport } from '../../actions/global-actions';

import ConfirmDialog from '../confirm-dialog/confirm-dialog';

const ExportFooter: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const timeline = useSelector((state: RootState) => state.timeline.data);
  const exportDisabled = !Boolean(timeline.length);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleConfirmCaseClose = useCallback((): void => {
    history.push('/configuration/symptoms-date');
    dispatch(resetStore());
  }, []);

  return (
    <div className={styles.container}>
      <Button
        onClick={(): void => {
          setShowConfirmDialog(true);
        }}
      >
        {t('exportFooter.buttonCloseCase')}
      </Button>
      <Button
        onClick={(): void => {
          dispatch(triggerExport());
        }}
        variant="contained"
        disableElevation
        disabled={exportDisabled}
        color="primary"
      >
        {t('exportFooter.buttonExport')}
      </Button>

      <ConfirmDialog
        open={showConfirmDialog}
        handleClose={(): void => setShowConfirmDialog(false)}
        dialogTitle={t('caseClosedPrompt.title')}
        dialogContentText={t('caseClosedPrompt.message')}
        agree={{ onAgree: handleConfirmCaseClose, text: t('confirm') }}
        disagree={{ onDisagree: (): void => setShowConfirmDialog(false), text: t('cancel') }}
      />
    </div>
  );
};

export default ExportFooter;
