/**
 * All global actions
 */

import { StoreSnapshot } from '../../utils/json-export';

export const RESET_STORE = 'global/RESET_STORE';
export const IMPORT_STORE = 'global/IMPORT_STORE';

export interface ResetStoreAction {
  type: typeof RESET_STORE;
}

export interface ImportStoreAction {
  type: typeof IMPORT_STORE;
  payload: {
    snapshot: StoreSnapshot;
  };
}

type GlobalActionTypes = ResetStoreAction | ImportStoreAction;

export default GlobalActionTypes;
