import { useState, useCallback } from 'react';

/**
 * Works similar to useState but for text input fields data
 *
 * @param initialValues Initial value is used initially when provided, else emptyValue is used.
 *
 * @returns [
 *   values The values of the form
 *   handleChange The input field onChange event handler. Will update the value assigned to the ID of the input.
 *   reset Resets the values to the initialValues
 * ]
 */
const useTextInputState = <T>(
  initialValues: T,
): [T, (event: React.ChangeEvent<HTMLInputElement>) => void, () => void] => {
  const [fields, setValues] = useState(initialValues);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
    [fields],
  );

  const reset = useCallback(() => setValues(initialValues), [initialValues]);

  return [fields, handleChange, reset];
};

export default useTextInputState;
