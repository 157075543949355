import React, { useCallback, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import styles from './file-selector.css';

interface Props {
  selectedFile: File | '';
  onSelectFile: (file: File | '') => void;
}

/**
 * Render a file select input to select a zip file with timeline data
 * to be imported to the store.
 */
const FileSelector: FunctionComponent<Props> = ({ selectedFile, onSelectFile }: Props) => {
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles) => {
    onSelectFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, accept: '.zip', multiple: false });

  return (
    <>
      <div {...getRootProps({ className: styles.dragAndDropArea })}>
        <CloudDownloadIcon className={styles.icon} />
        {Boolean(selectedFile) ? t('fileSelector.selected') : t('fileSelector.dropHere')}
      </div>
      <input {...getInputProps({ disabled: Boolean(selectedFile), hidden: true, id: 'file-upload' })} />
      <label htmlFor="file-upload">
        <Button
          disabled={Boolean(selectedFile)}
          className={styles.uploadButton}
          variant="contained"
          color="primary"
          component="span"
        >
          {Boolean(selectedFile) ? t('fileSelector.selected') : t('fileSelector.clickHere')}
        </Button>
      </label>
    </>
  );
};

export default FileSelector;
