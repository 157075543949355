import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TimelineNotification from './timeline-notification';

import { removeNotification } from '../../../../actions/notifications-actions';
import { emptyImportNotificationId } from '../../../../types/notification';

/**
 * A message on empty imports
 */
const TimelineNotificationEmptyImport: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const remove = useCallback(() => {
    dispatch(removeNotification(emptyImportNotificationId));
  }, []);

  return (
    <TimelineNotification
      title={t('timelineEntries.emptyImportError.noEntries')}
      message={t('timelineEntries.emptyImportError.continueManually')}
      onClose={remove}
    />
  );
};

export default TimelineNotificationEmptyImport;
