import React, { FunctionComponent, useState, useEffect, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../reducers';

import { LocalizationProvider, StaticDatePicker, PickersDay, PickersDayProps } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { format, isWithinInterval, isSameDay, addDays, subDays, getWeeksInMonth, isToday } from 'date-fns';
import de from 'date-fns/locale/de';

import clsx from 'clsx';

import { setTimelineDate } from '../../../actions/timeline-actions';
import { selectDaysWithEntries } from '../../../selectors/incubation-time';

import { mediaQueries } from '../../../configs/theme';

import styles from './timeline-calendar.css';

const TimelineCalendar: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedDate, incubation, daysWithEntries } = useSelector((state: RootState) => ({
    selectedDate: state.timeline.selectedDate || state.incubation.start,
    incubation: state.incubation,
    daysWithEntries: selectDaysWithEntries(state),
  }));

  const formattedDate = useMemo(() => (selectedDate && format(selectedDate, 'dd.MM.yyyy')) || '', [selectedDate]);
  const isFirstDay = useMemo(
    () => Boolean(selectedDate && incubation.start && isSameDay(selectedDate, incubation.start)),
    [selectedDate, incubation],
  );
  const isLastDay = useMemo(() => Boolean(selectedDate && incubation.end && isSameDay(selectedDate, incubation.end)), [
    selectedDate,
    incubation,
  ]);

  /**
   * Handle the weeks in a month
   */
  const [weeksInMonthClass, setWeeksInMonthClass] = useState('');
  const updateWeeksInMonth = useCallback((date: Date | null): void => {
    if (date) {
      const weeksInMonth = getWeeksInMonth(date, { weekStartsOn: 1 });
      setWeeksInMonthClass((styles as { [key: string]: string })[`weeksInMonth${weeksInMonth}`]);
    } else {
      setWeeksInMonthClass('');
    }
  }, []);
  useEffect(() => updateWeeksInMonth(selectedDate), [selectedDate]);

  // Collapse calendar per default on tablet
  const tabletQuery = window.matchMedia(mediaQueries.tablet);
  const desktopQuery = window.matchMedia(mediaQueries.desktop);
  const isTablet = tabletQuery.matches && !desktopQuery.matches;
  const [isCalendarExpanded, expandCalendar] = useState(!isTablet);
  const stopPropagation = useCallback((event: React.MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onCalendarToggle = (event: React.ChangeEvent<unknown>, expanded: boolean): void => {
    expandCalendar(expanded);
  };

  const handleGoToPreviousDay = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedDate) {
      dispatch(setTimelineDate(subDays(selectedDate, 1)));
    }
  };

  const handleGoToNextDay = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedDate) {
      dispatch(setTimelineDate(addDays(selectedDate, 1)));
    }
  };

  const handleChange = (date: Date | null): void => {
    if (date) {
      dispatch(setTimelineDate(date));
    }
  };

  // Render a single day
  const renderDay = useCallback(
    (day: Date | null, selectedDates: (Date | null)[], dayProps: PickersDayProps<Date>): JSX.Element => {
      const isIncubationDate = Boolean(
        dayProps.inCurrentMonth &&
          day &&
          incubation.start &&
          incubation.end &&
          isWithinInterval(day, { start: incubation.start, end: incubation.end }),
      );
      const hasEntries = day && daysWithEntries.find((dayWithEntries) => isSameDay(dayWithEntries, day));

      const classNames = clsx(
        styles.date,
        isIncubationDate && styles.incubationDate,
        day && incubation.start && isSameDay(day, incubation.start) && styles.firstIncubationDate,
        day && incubation.end && isSameDay(day, incubation.end) && styles.lastIncubationDate,
        hasEntries && styles.incubationDateWithEntries,
        day && isToday(day) && styles.lastIncubationDateToday,
      );

      return <PickersDay {...dayProps} className={classNames} />;
    },
    [incubation, daysWithEntries],
  );

  const collapseText = (
    <div className={styles.collapseButton}>
      <p>{t('locationHistory.calendarCollapse')}</p>
      <ArrowDropUp />
    </div>
  );

  const expandText = (
    <div className={styles.collapseButton}>
      <p>{t('locationHistory.calendarExpand')}</p>
      <ArrowDropDown />
    </div>
  );

  return (
    <div className={styles.calendarContainer}>
      <aside className={clsx(styles.selectedDateAside, !isCalendarExpanded && styles.selectedDateAsideHidden)}>
        <Typography className={styles.selectedDate} variant="h3">
          <span className={styles.displayBlock}>{format(selectedDate || new Date(), 'EEEE,', { locale: de })}</span>
          {format(selectedDate || new Date(), 'do LLLL, yyyy', { locale: de })}
        </Typography>
        <nav>
          <IconButton
            onClick={handleGoToPreviousDay}
            disabled={isFirstDay}
            aria-label={t('locationHistory.goToPreviousDay', { day: formattedDate })}
          >
            <ChevronLeftIcon />
          </IconButton>

          <IconButton
            onClick={handleGoToNextDay}
            disabled={isLastDay}
            aria-label={t('locationHistory.goToNextDay', { day: formattedDate })}
          >
            <ChevronRightIcon />
          </IconButton>
        </nav>
      </aside>
      <Accordion className={styles.container} square defaultExpanded={!isTablet} onChange={onCalendarToggle}>
        <AccordionSummary
          onClick={stopPropagation}
          className={styles.summary}
          expandIcon={isCalendarExpanded ? collapseText : expandText}
          aria-label={t('locationHistory.toggleCalendar')}
        >
          {(!desktopQuery.matches || !isCalendarExpanded) && (
            <>
              <IconButton
                onClick={handleGoToPreviousDay}
                disabled={isFirstDay}
                aria-label={t('locationHistory.goToPreviousDay', { day: formattedDate })}
              >
                <ChevronLeftIcon />
              </IconButton>

              <Typography className={styles.selectedDate} variant="h3">
                {desktopQuery.matches
                  ? format(selectedDate || new Date(), 'EEEE, do LLLL', { locale: de })
                  : formattedDate}
              </Typography>

              <IconButton
                onClick={handleGoToNextDay}
                disabled={isLastDay}
                aria-label={t('locationHistory.goToNextDay', { day: formattedDate })}
              >
                <ChevronRightIcon />
              </IconButton>
            </>
          )}
        </AccordionSummary>

        <AccordionDetails className={styles.calendar}>
          <LocalizationProvider locale={de} dateAdapter={DateFnsAdapter}>
            <StaticDatePicker
              className={weeksInMonthClass}
              showToolbar={false}
              minDate={incubation.start}
              maxDate={incubation.end}
              value={selectedDate}
              renderDay={renderDay}
              onChange={(date): void => {
                handleChange(date as Date);
              }}
              onMonthChange={updateWeeksInMonth}
              views={['date']}
              renderInput={(props): JSX.Element => <TextField {...props} />}
            />
          </LocalizationProvider>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TimelineCalendar;
