import React, { useState, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import Hidden from '@material-ui/core/Hidden';

import { GoogleMapProvider } from '@ubilabs/google-maps-react-hooks';
import Header from './header/header';
import Sidebar from './sidebar/sidebar';
import MapCanvas from './map/canvas';
import MapView from './map/map-view';
import ActivitySegments from './map/activity-segments';
import PlaceVisitMarkers from './map/place-visit-markers';
import PlaceVisitEditingMarker from './map/place-visit-editing-marker';
import SidebarAndMap from './sidebar-and-map/sidebar-and-map';
import ContactsListView from './contacts-list-view/contacts-list-view';
import HelpPage from './help-page/help-page';
import InfoPage from './info-page/info-page';
import CookieDisclaimer, { cookieConsent } from './cookie-disclaimer/cookie-disclaimer';
import Notifier from './notifier/notifier';
import { RootState } from '../reducers';

import { setEditingTimelineEntry } from '../actions/timeline-actions';

import { GOOGLE_MAPS_API_KEY } from '../configs/main';
import { mapOptions, initialBounds, mapStyles } from '../configs/map';
import { noImport } from '../configs/no-import';
import styles from './app.css';
import PrivacyDialog from './privacy-dialog/privacy-dialog';

function App(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [mapContainer, setMapContainer] = useState(null);
  const caseId = useSelector((state: RootState) => state.case.id);
  const mapRef = useCallback((node) => {
    node && setMapContainer(node);
  }, []);
  const [cookiesConsent, setCookiesConsent] = useState<boolean>(cookieConsent);

  const toggleOverlay = (): void => {
    const target = `/configuration/location-history/${location.pathname.includes('help') ? '' : 'help'}`;
    history.push(target);
  };

  const renderAppContent = (): JSX.Element => (
    <div className={styles.pageContainer}>
      <Header />
      <Notifier />
      {/* Redirect to configuration start after reloads */}
      {!caseId && <Redirect to="/configuration/symptoms-date" />}
      <PrivacyDialog />
      <Switch>
        <Route
          path="/contacts"
          render={(): JSX.Element => {
            // Make sure there is no editing entry, so the button to add a new timeline entry will be activated when returning to the timeline view.
            dispatch(setEditingTimelineEntry(null));
            return <ContactsListView />;
          }}
        ></Route>
        <Route>
          <SidebarAndMap>
            <Sidebar onOpenOverlay={toggleOverlay} />
            <Route path="/configuration/location-history/help">
              <HelpPage isOverlay={true} onClose={toggleOverlay} />
            </Route>
            <Route path="/configuration/symptoms-date/info">
              <InfoPage
                onClose={history.goBack}
                content={t(`infoPage.${noImport ? 'symptomsDateNoImport' : 'symptomsDate'}`)}
              />
            </Route>
            <Route path="/configuration/location-history/info">
              <InfoPage onClose={history.goBack} content={t('infoPage.locationHistory')} />
            </Route>
            <Route path="/timeline/info">
              <InfoPage
                onClose={history.goBack}
                content={<Trans i18nKey={`infoPage.${noImport ? 'timelineNoImport' : 'timeline'}`} />}
              />
            </Route>
            {cookiesConsent && (
              <>
                <MapCanvas ref={mapRef} />
                <Hidden xsDown>
                  <MapView />
                  <ActivitySegments />
                  <PlaceVisitMarkers />
                  <PlaceVisitEditingMarker />
                </Hidden>
              </>
            )}
          </SidebarAndMap>
        </Route>
      </Switch>
      {!cookiesConsent && (
        <CookieDisclaimer onAccept={(): void => setCookiesConsent(true)} onDecline={(): void => history.push('/')} />
      )}
    </div>
  );

  return !cookiesConsent ? (
    renderAppContent()
  ) : (
    <GoogleMapProvider
      googleMapsAPIKey={GOOGLE_MAPS_API_KEY}
      mapContainer={mapContainer}
      options={{ ...mapOptions, styles: mapStyles }}
      libraries={['places']}
      onLoad={(map): void => map.fitBounds(initialBounds)}
    >
      {renderAppContent()}
    </GoogleMapProvider>
  );
}

export default App;
