import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import BorderedButton from '../bordered-button/bordered-button';

import { IMPRINT_URL } from '../../configs/urls';

import styles from './navigation.css';
import { setPrivacyDialogVisible } from '../../actions/privacy-dialog-actions';

const Navigation: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  return (
    <>
      <BorderedButton onClick={openDrawer} className={styles.menuButton}>
        <MenuIcon />
      </BorderedButton>
      <Drawer anchor="top" open={isDrawerOpen} onClose={closeDrawer} PaperProps={{ className: styles.drawer }}>
        <BorderedButton onClick={closeDrawer} className={styles.closeButton}>
          <CloseIcon />
        </BorderedButton>
        <nav>
          <ul>
            <li
              title={t('app.navigation.privacyPolicy')}
              className={styles.link}
              onClick={(): void => {
                dispatch(setPrivacyDialogVisible(true));
              }}
            >
              {t('app.navigation.privacyPolicy')}
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={IMPRINT_URL}
                title={t('app.navigation.imprint')}
                className={styles.link}
              >
                {t('app.navigation.imprint')}
              </a>
            </li>
          </ul>
        </nav>
      </Drawer>
    </>
  );
};

export default Navigation;
