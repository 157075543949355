import React, { FunctionComponent, useState, ChangeEvent } from 'react';
import { NavLink, Route, useLocation } from 'react-router-dom';

import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useTranslation } from 'react-i18next';

import styles from './navigation-menu-tabs.css';

/*
 * Tabs for the top bar menu (contact mode / editing mode) in mobile version
 */
const MenuTabs: FunctionComponent = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const pathnameState = pathname.replace('/', '');

  const [selected, setSelected] = useState<string>(pathnameState);

  const handleAlignment = (_event: ChangeEvent<unknown>, value: string): void => {
    setSelected(value);
  };

  return (
    <Route path={['/timeline', '/contacts']}>
      {Boolean(selected) && (
        <Hidden smUp>
          <Paper square classes={{ root: styles.menuTabs }}>
            <Tabs
              value={selected}
              classes={{ root: styles.menuTabs }}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleAlignment}
              aria-label="menu-tabs"
            >
              <Tab
                className={styles.menuTab}
                classes={{ root: styles.menuTab, disabled: styles.disabled }}
                value="timeline"
                label={
                  <NavLink to="/timeline" className={styles.menuLink} activeClassName={styles.selected}>
                    {t('timelineEntries.locationHistory')}
                  </NavLink>
                }
                disabled={pathname.includes('/timeline')}
              />
              <Tab
                className={styles.menuTab}
                classes={{ root: styles.menuTab, disabled: styles.disabled }}
                value="contacts"
                label={
                  <NavLink exact to="/contacts" className={styles.menuLink} activeClassName={styles.selected}>
                    {t('placeVisit.contacts')}
                  </NavLink>
                }
                disabled={pathname.includes('/contacts')}
              />
            </Tabs>
          </Paper>
        </Hidden>
      )}
    </Route>
  );
};

export default MenuTabs;
