import PlaceVisit from './place-visit';
import ActivitySegment from './activity-segment';

/**
 * The possible types of a timeline entry
 */
export enum TimelineEntryType {
  PlaceVisit = 'PLACE_VISIT',
  ActivitySegment = 'ACTIVITY_SEGMENT',
}

/**
 * An entry of the timeline
 */
export type TimelineEntry = PlaceVisit | ActivitySegment;

/**
 * The timeline
 */
type Timeline = TimelineEntry[];

export default Timeline;

/**
 * Whether the timeline entry is a place visit
 */
export function isPlaceVisit(timelineEntry: Partial<TimelineEntry>): timelineEntry is PlaceVisit {
  return (timelineEntry as PlaceVisit).type === TimelineEntryType.PlaceVisit;
}

/**
 * Whether the timeline entry is an activity segment
 */
export function isActivitySegment(timelineEntry: TimelineEntry): timelineEntry is ActivitySegment {
  return (timelineEntry as ActivitySegment).type === TimelineEntryType.ActivitySegment;
}
