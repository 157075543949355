import React, { FunctionComponent } from 'react';

import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function CircularProgressIndicator(props: CircularProgressProps & { value: number }): JSX.Element {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  fieldValues: string[];
}

const FormFillingIndicator: FunctionComponent<Props> = ({ fieldValues }: Props) => {
  const filledFieldValuesCount = fieldValues.filter(Boolean).length;
  const progressInPercent = filledFieldValuesCount / fieldValues.length * 100;

  return <CircularProgressIndicator value={progressInPercent} />;
};

export default FormFillingIndicator;
