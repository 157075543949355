import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationDe from './de.json';
// import translationEn from './en.json';
import translationLanguages from './languages.json';

// the translations
const resources: Resource = {
  de: {
    translation: translationDe,
    languages: translationLanguages,
  },
  // TODO: Re-enable english language, once translation is done
  // en: {
  //   translation: translationEn,
  //   languages: translationLanguages,
  // },
};

export const languages = Object.keys(resources).sort();

i18n.use(initReactI18next).init({
  resources,
  lng: 'de',
  fallbackLng: ['de'],
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
  },
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span', 'u'],
  },
});

export default i18n;
