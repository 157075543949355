import React, { FunctionComponent } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

const PlaceVisitSkeleton: FunctionComponent = (): JSX.Element => {
  return (
    <Box display="flex">
      <Box display="flex" style={{ paddingTop: '8px' }}>
        <Skeleton variant="circle" width={30} height={30} />
      </Box>
      <Box display="flex" style={{ paddingLeft: '8px' }} flexDirection="column" flex="1">
        <Skeleton component="div" variant="text" width={120} height={40} />
        <Skeleton component="div" variant="text" width={180} />
        <Box display="flex" justifyContent="space-between">
          <Skeleton component="div" variant="text" width={120} height={60} />
          <Skeleton component="div" variant="text" width={120} height={60} />
        </Box>
        <Skeleton component="div" variant="text" height={60} />
      </Box>
    </Box>
  );
};

export default PlaceVisitSkeleton;
