import React, { FunctionComponent, createRef, Ref } from 'react';

import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

import Header from './header/header';
import HeaderSegment from './header-segment/header-segment';
import TutorialSegment from './tutorial-segment/tutorial-segment';
import PrivacySection from './privacy-section/privacy-section';
import CaseStartSection from './case-start-section/case-start-section';
import PartnerSection from './partner-section/partner-section';

import GreenAreaSvg from '../../../assets/images/start-page/green-area.svg';
import GreenAreaSmallSvg from '../../../assets/images/start-page/green-area-small.svg';
import GreenAreaMobileSvg from '../../../assets/images/start-page/green-area-mobile.svg';

import Reference from './reference';

import styles from './start-page.css';

const StartPage: FunctionComponent = () => {
  const references = new Map<Reference, Ref<HTMLDivElement>>([
    [Reference.aboutApplication, createRef<HTMLDivElement>()],
    [Reference.quickGuide, createRef<HTMLDivElement>()],
    [Reference.startInput, createRef<HTMLDivElement>()],
    [Reference.privacyPolicy, createRef<HTMLDivElement>()],
    [Reference.partner, createRef<HTMLDivElement>()],
  ]);

  return (
    <Box className={styles.container} display="flex" flexDirection="column">
      <Header references={references} />
      <HeaderSegment ref={references.get(Reference.aboutApplication)} />
      <main className={styles.mainContainer}>
        <TutorialSegment ref={references.get(Reference.quickGuide)} />
        <CaseStartSection ref={references.get(Reference.startInput)} />
        {/* Currently not needed
        <CaseSection ref={references.get(Reference.whatIsACase)} />*/}
        <PrivacySection ref={references.get(Reference.privacyPolicy)} />
        <PartnerSection ref={references.get(Reference.partner)} />
      </main>
      <Hidden mdDown>
        <GreenAreaSvg className={styles.leftBackgroundImage} />
        <GreenAreaSmallSvg className={styles.rightBackgroundImage} />
      </Hidden>
      <Hidden mdUp>
        <GreenAreaMobileSvg className={styles.leftBackgroundImage} />
      </Hidden>
    </Box>
  );
};

export default StartPage;
