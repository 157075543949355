import React, { FunctionComponent } from 'react';

import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

import ActivityType from '../../../types/activity-type';
import { getActivitySegmentLabel } from '../../../utils/get-activity-segment-type-label';

import styles from './activity-segment-type-select.css';

interface Props {
  onClose: () => void;
  anchorEl: null | HTMLElement;
  onSelect: (activity: ActivityType | null) => void;
}

/**
 * Displays the menu to select or remove an activity segment
 */
const ActivitySegmentTypeSelect: FunctionComponent<Props> = ({ onSelect, anchorEl, onClose }: Props) => {
  /**
   * Handle the selection menu
   */
  const activities = [null, ActivityType.Car, ActivityType.PublicTransport, ActivityType.CyclingWalking];

  /**
   * Handle the selection of an activity segment
   */
  const handleSelect = (activity: ActivityType | null): void => {
    onSelect(activity);
    onClose();
  };

  /**
   * Get the icon for the activity segment in the menu
   */
  const getActivitySegmentIcon = (activityType: ActivityType | null): JSX.Element | null => {
    switch (activityType) {
      case ActivityType.Car:
        return <DirectionsCarIcon />;
      case ActivityType.PublicTransport:
        return <DirectionsBusIcon />;
      case ActivityType.CyclingWalking:
        return <DirectionsWalkIcon />;
      default:
        return null;
    }
  };

  return (
    <Menu id="activity-select-choices" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
      {activities.map((activity, index) => {
        return (
          <MenuItem key={index} onClick={(): void => handleSelect(activity)}>
            <Typography className={styles.selectActivityItem}>
              {getActivitySegmentIcon(activity)} {getActivitySegmentLabel(activity)}
            </Typography>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default ActivitySegmentTypeSelect;
