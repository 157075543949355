import { RootState } from '../reducers';
import Person, { PersonId } from '../types/person';

/**
 * Get the contacts matching given contactIds
 */
export function getContactsByIds(state: RootState, contactIds: PersonId[]): Person[] {
  const { contacts } = state;

  if (!contacts) {
    return [];
  }

  return contacts.filter((contact: Person) => contactIds?.includes(contact.id));
}
