enum Reference {
  aboutApplication = 'aboutApplicationRef',
  startInput = 'startInputRef',
  quickGuide = 'quickGuideRef',
  whatIsACase = 'whatIsACaseRef',
  privacyPolicy = 'privacyPolicyRef',
  partner = 'partnerRef',
}

export default Reference;
