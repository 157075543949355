import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import OrangeStripe from '../../../../assets/images/start-page/orange-stripe.svg';

import styles from './help-page-step.css';

interface Props {
  step: number;
  title: string;
  description: string;
}

const HelpPageStep: FunctionComponent<Props> = ({ step, title, description }: Props) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" className={styles.container}>
      <OrangeStripe />
      <Typography variant="h2" className={styles.title}>{`${t('helpPage.step')} ${step}. ${title}.`}</Typography>
      <Typography className={styles.content}>{description}</Typography>
    </Box>
  );
};

export default HelpPageStep;
