import { addDays, startOfDay, isBefore } from 'date-fns';

import { RootState } from '../reducers';

import { selectTimelineEntriesByDate } from './timeline';

/**
 * Get all days of the incubation period which actually have an entry associated with them
 */
export function selectDaysWithEntries(state: RootState): Date[] {
  const { timeline, incubation } = state;

  if (!incubation.start || !incubation.end || timeline.data.length === 0) {
    return [];
  }

  const days: Date[] = [];
  let currentDay = startOfDay(incubation.start);
  while (isBefore(currentDay, incubation.end)) {
    if (selectTimelineEntriesByDate(state, currentDay).length > 0) {
      days.push(currentDay);
    }
    currentDay = addDays(currentDay, 1);
  }

  return days;
}
