import React, { FunctionComponent } from 'react';

import Chip from '@material-ui/core/Chip';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';

import Person from '../../types/person';
import styles from './contact-person.css';

interface Props {
  contactPerson: Person;
  getTagProps: AutocompleteGetTagProps;
  index: number;
}

/**
 * Component to show a contact person chip in edit mode
 */
const ContactPersonEditChip: FunctionComponent<Props> = ({ contactPerson, getTagProps, index }: Props) => (
  <Chip
    component="span"
    className={styles.contactEntry}
    classes={{
      root: styles.contactEntry,
      deleteIcon: styles.contactEntryDeleteIcon,
    }}
    key={contactPerson?.id}
    label={`${contactPerson?.givenName} ${contactPerson?.familyName}`}
    {...getTagProps({ index })}
  />
);

export default ContactPersonEditChip;
