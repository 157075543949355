import Timeline, { isPlaceVisit } from '../types/timeline';
import Bounds from '../types/bounds';

/**
 * Get the bounds of all place visits of a timeline
 */
export default function getBoundsFromTimeline(timeline: Timeline): Bounds | null {
  if (!timeline.length) {
    return null;
  }

  const placeVisits = timeline.filter(isPlaceVisit);

  if (!placeVisits.length) {
    return null;
  }

  const latitudes = placeVisits.map(({ location }) => location.latitude);
  const longitudes = placeVisits.map(({ location }) => location.longitude);

  const bounds: Bounds = {
    north: Math.max(...latitudes),
    east: Math.max(...longitudes),
    south: Math.min(...latitudes),
    west: Math.min(...longitudes),
  };

  return bounds;
}
