import React, { FunctionComponent, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setPredefinedPlace } from '../../../actions/predefined-places-actions';

import PredefinedPlace, { PredefinedPlaceType } from '../../../types/predefined-place';
import Source from '../../../types/source';
import ConfidenceStatus from '../../../types/confidence-status';
import { TimelineEntryType } from '../../../types/timeline';

import Autocomplete, { AutocompletePlace } from '../autocomplete/autocomplete';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import styles from './predefined-place-dialog.css';

interface Props {
  handleClose: () => void;
  handleSave: (place: AutocompletePlace) => void;
  predefinedPlace: PredefinedPlace;
}

const PredefinedPlaceDialog: FunctionComponent<Props> = ({ handleClose, handleSave, predefinedPlace }: Props) => {
  const [selectedPlace, setSelectedPlace] = useState<AutocompletePlace | null>(predefinedPlace.place);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const title = useMemo(() => {
    switch (predefinedPlace.type) {
      case PredefinedPlaceType.Home:
        return t('placeVisit.setHomeAddress');
      case PredefinedPlaceType.Work:
        return t('placeVisit.setWorkAddress');
      default:
        return '';
    }
  }, [predefinedPlace.type]);

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="predefined-place-dialog-title"
      aria-describedby="predefined-place-dialog-description"
    >
      <DialogTitle id="predefined-place-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div className={styles.container} id="predefined-place-dialog-description">
          <Autocomplete
            defaultPlace={selectedPlace}
            placeholder={t('placeVisit.exampleStreet')}
            onChange={setSelectedPlace}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          onClick={(): void => {
            if (selectedPlace && predefinedPlace) {
              dispatch(
                setPredefinedPlace({
                  type: predefinedPlace.type,
                  place: {
                    type: TimelineEntryType.PlaceVisit,
                    source: Source.User,
                    confidenceStatus: ConfidenceStatus.UserConfirmed,
                    ...selectedPlace,
                  },
                }),
              );
              handleSave(selectedPlace);
            }
          }}
          color="primary"
          disabled={!selectedPlace}
          autoFocus
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PredefinedPlaceDialog;
