import React, { FunctionComponent, ReactNode } from 'react';

import styles from './sidebar-and-map.css';

type Props = {
  children: ReactNode;
};

const SidebarAndMap: FunctionComponent<Props> = (props) => <div className={styles.pageContent}>{props.children}</div>;

export default SidebarAndMap;
