/**
 * All actions regarding the map
 */
import Location from '../location';
import TimelineState from '../timeline';

export const UPDATE_BOUNDS = 'map/UPDATE_BOUNDS';
export const ZOOM_TO_LOCATION = 'map/ZOOM_TO_LOCATION';

export interface ZoomToLocationAction {
  type: typeof ZOOM_TO_LOCATION;
  payload: {
    location: Location;
  };
}

export interface UpdateBoundsAction {
  type: typeof UPDATE_BOUNDS;
  payload: {
    timeline: TimelineState;
  };
}

type MapActionTypes = ZoomToLocationAction | UpdateBoundsAction;

export default MapActionTypes;
