import React, { useState, FunctionComponent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isToday, set } from 'date-fns';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import ActivitySegmentLine from '../activity-segment/activity-segment-line/activity-segment-line';

import LocationIcon from '../../../assets/icons/location.svg';

import Autocomplete, { AutocompletePlace } from './autocomplete/autocomplete';
import EditContacts from '../contacts/contacts-edit';
import PredefinedPlaceDialog from './predefined-place-dialog/predefined-place-dialog';
import { validatePlaceVisitForm } from '../../utils/validate-forms';

import { PersonId } from '../../types/person';
import PlaceVisit from '../../types/place-visit';
import ConfidenceStatus from '../../types/confidence-status';
import { isPlaceVisit, TimelineEntryType } from '../../types/timeline';
import PredefinedPlace from '../../types/predefined-place';
import Source from '../../types/source';
import { ActivitySegmentStatusType } from '../../types/activity-segment-status';

import { RootState } from '../../reducers';

import { updateEditingTimelineEntry } from '../../actions/timeline-actions';
import { zoomToLocation } from '../../actions/map-actions';

import styles from './place-visit-form.css';
import PlaceVisitDateTimePicker from './place-visit-date-time-picker/place-visit-date-time-picker';

interface Props {
  onSave: (placeVisit: Omit<PlaceVisit, 'id'>) => void;
  onCancel: () => void;
  status: ActivitySegmentStatusType;
  scrollIntoViewOnMount?: boolean;
}

/**
 * A form to add or change a place visit
 */
const PlaceVisitForm: FunctionComponent<Props> = ({
  onSave,
  onCancel,
  status,
  scrollIntoViewOnMount = false,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const elementRef = useRef<HTMLDivElement>(null);

  const [editPredefinedPlace, setEditPredefinedPlace] = useState<PredefinedPlace | null>(null);

  const { selectedDate, editingEntry, predefinedPlaces } = useSelector((state: RootState) => ({
    selectedDate: state.timeline.selectedDate,
    editingEntry: state.timeline.editingEntry,
    predefinedPlaces: state.predefinedPlaces,
  }));

  /**
   * Handle change of the selected start time
   */
  const handleSelectedStartTimeChange = (startTime: Date | null): void => {
    if (!startTime) {
      return;
    }

    if (isToday(startTime)) {
      startTime = set(startTime, {
        year: selectedDate?.getFullYear(),
        month: selectedDate?.getMonth(),
        date: selectedDate?.getDate(),
      });
    }

    dispatch(
      updateEditingTimelineEntry({
        time: {
          start: startTime,
          end: editingEntry?.time?.end || startTime,
        },
      }),
    );
  };

  /**
   * Handle change of the selected end time
   */
  const handleSelectedEndTimeChange = (endTime: Date | null): void => {
    if (!endTime) {
      return;
    }

    if (isToday(endTime)) {
      endTime = set(endTime, {
        year: editingEntry?.time?.end.getFullYear(),
        month: editingEntry?.time?.end.getMonth(),
        date: editingEntry?.time?.end.getDate(),
      });
    }

    dispatch(
      updateEditingTimelineEntry({
        time: {
          start: editingEntry?.time?.start || endTime,
          end: endTime,
        },
      }),
    );
  };

  /**
   * Handle change of the comment field
   */
  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(updateEditingTimelineEntry({ comment: event.target.value }));
  };

  /**
   * Handle autocomplete field input
   */
  const handleAutocompleteChange = (place: AutocompletePlace | null): void => {
    dispatch(
      updateEditingTimelineEntry({
        name: place?.name,
        address: place?.address,
        location: place?.location,
        placeId: place?.placeId,
      }),
    );
    if (place?.location) {
      dispatch(zoomToLocation(place?.location));
    }
  };

  /**
   * Handle contacts field input
   */
  const handleContactsChange = (updatedContacts: PersonId[] | null): void => {
    dispatch(updateEditingTimelineEntry({ contacts: updatedContacts || [] }));
  };

  /**
   * Form error handling for input fields
   */
  const [errors, setErrors] = useState(false);

  // Scroll form into view when mounted
  useEffect(() => {
    if (scrollIntoViewOnMount) {
      elementRef.current?.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (!editingEntry || !isPlaceVisit(editingEntry)) {
      setErrors(true);
      return;
    }
    const { errors: validationErrors } = validatePlaceVisitForm(editingEntry);
    setErrors(Boolean(validationErrors.length));
  }, [editingEntry]);

  /**
   * Handle place visit on form submit
   */
  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();

    if (errors || !editingEntry || !isPlaceVisit(editingEntry)) {
      return;
    }

    onSave({
      type: TimelineEntryType.PlaceVisit,
      location: editingEntry?.location,
      time: {
        end: editingEntry?.time?.end,
        start: editingEntry?.time?.start,
      },
      name: editingEntry?.name,
      placeId: editingEntry?.placeId,
      address: editingEntry?.address,
      comment: editingEntry?.comment,
      description: editingEntry?.description,
      confidenceStatus: ConfidenceStatus.UserConfirmed,
      source: Source.User,
      contacts: editingEntry?.contacts,
    } as Omit<PlaceVisit, 'id'>);
  };

  if (editingEntry && !isPlaceVisit(editingEntry)) {
    return null;
  }

  return (
    <div className={styles.container} ref={elementRef}>
      <LocationIcon className={styles.markerIcon} />
      <ActivitySegmentLine status={status} type="long" />

      <form onSubmit={handleSubmit} noValidate autoComplete="off" className={styles.formContainer}>
        <div className={styles.location}>
          <Autocomplete
            defaultPlace={editingEntry}
            onChange={handleAutocompleteChange}
            placeholder={t('placeVisit.searchPlace')}
            predefinedPlacesOptions={{
              predefinedPlaces: predefinedPlaces,
              onEdit: (predefinedPlace): void => setEditPredefinedPlace(predefinedPlace),
            }}
          />

          <div className={styles.contentContainer}>
            <Grid container justify="space-between">
              <PlaceVisitDateTimePicker
                label={t('placeVisit.timeStart')}
                ariaLabel={t('placeVisit.selectTimeStart')}
                value={editingEntry?.time?.start}
                selectedDate={selectedDate}
                onChange={handleSelectedStartTimeChange}
              />
              <PlaceVisitDateTimePicker
                label={t('placeVisit.timeEnd')}
                ariaLabel={t('placeVisit.selectTimeEnd')}
                minTime={editingEntry?.time.start}
                value={editingEntry?.time?.end}
                selectedDate={selectedDate}
                onChange={handleSelectedEndTimeChange}
              />
            </Grid>
            <EditContacts onChange={handleContactsChange} contactIds={editingEntry?.contacts || []} />

            <TextField
              id="notes-field"
              multiline
              rowsMax={4}
              className={styles.commentField}
              label={t('placeVisit.notes')}
              onChange={handleCommentChange}
              value={editingEntry?.comment}
            />
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onCancel}>{t('cancel')}</Button>
          <Button className={styles.saveButton} variant="contained" type="submit" disabled={errors}>
            {t('save')}
          </Button>
        </div>
      </form>
      {editPredefinedPlace && (
        <PredefinedPlaceDialog
          handleClose={(): void => setEditPredefinedPlace(null)}
          handleSave={(place): void => {
            handleAutocompleteChange(place);
            setEditPredefinedPlace(null);
          }}
          predefinedPlace={editPredefinedPlace}
        />
      )}
    </div>
  );
};

export default PlaceVisitForm;
