import { differenceInMinutes, startOfDay } from 'date-fns';
import { TimelineEntry } from '../types/timeline';

/**
 * Sort function for sorting placeVisits by temporal distance to selectedDate
 */
export default function sortClusterPlaceVisits(selectedDate: Date) {
  return (placeA: TimelineEntry, placeB: TimelineEntry): number => {
    const differencePlaceA = Math.abs(differenceInMinutes(placeA.time.start, startOfDay(selectedDate)));
    const differencePlaceB = Math.abs(differenceInMinutes(placeB.time.start, startOfDay(selectedDate)));

    if (differencePlaceA > differencePlaceB) return 1;
    if (differencePlaceA < differencePlaceB) return -1;
    return 0;
  };
}
