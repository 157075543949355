import React, { FunctionComponent } from 'react';

import IconButton from '@material-ui/core/Button';

import ActivitySegmentLine from '../activity-segment-line/activity-segment-line';

import { ActivitySegmentStatusType } from '../../../types/activity-segment-status';

import ActivitySegmentNotSelected from '../../../../assets/icons/activitySegment-notSelected.svg';

import styles from './add-activity-segment-button.css';

interface Props {
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/**
 * A button to add an activity segment
 */
const AddActivitySegmentButton: FunctionComponent<Props> = ({ onClick, disabled }: Props) => {
  return (
    <>
      <IconButton disableFocusRipple disableRipple className={styles.button} disabled={disabled} onClick={onClick}>
        <ActivitySegmentNotSelected />
      </IconButton>
      <ActivitySegmentLine status={ActivitySegmentStatusType.Inactive} type="short" />
    </>
  );
};

export default AddActivitySegmentButton;
