import { createMuiTheme } from '@material-ui/core/styles';
import * as fonts from './fonts';

const defaultFontFamily = '"Muli", sans-serif';

// TODO: Remove those imports when updating the theme with design 2.0
export const primaryColor = '#4CB5AB';
export const secondaryColor = '#FFA000';
export const lightColor = '#FFFFFF';

export const mediaQueries = {
  mobile: 'screen and (max-width: 750px)',
  tablet: 'screen and (min-width: 751px) and (max-width: 1024px)',
  desktop: 'screen and (min-width: 1025px)',
};

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#FFFFFF',
      main: '#4CB5AB',
      dark: 'rgba(0, 0, 0, 0.6)',
    },
    secondary: {
      /* Secondary colors can be adjusted later,
       * currently used for overriding radio buttons checked colors
       */
      light: '#FFFFFF',
      main: '#4CB5AB',
      dark: 'rgba(0, 0, 0, 0.6)',
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: defaultFontFamily,
    h1: {
      fontFamily: defaultFontFamily,
      fontSize: '2.25rem',
      fontWeight: 'bold',
      lineHeight: '2.8rem',
      color: lightColor,
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: defaultFontFamily,
      fontSize: '1.125rem',
      lineHeight: '1.3125rem',
      fontWeight: 300,
      color: lightColor,
    },
    h3: {
      fontFamily: defaultFontFamily,
      fontSize: '0.875rem',
      letterSpacing: '0.25px',
      fontWeight: 'bold',
      color: '#212121',
    },
    h4: {
      fontFamily: defaultFontFamily,
      fontSize: '0.75rem',
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: defaultFontFamily,
      fontSize: '1rem',
      lineHeight: '1.3',
      letterSpacing: '0.15px',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [fonts.muli, fonts.muliItalic],
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.875rem',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '0.875rem',
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        bottom: '-1.2rem',
      },
    },
    MuiButton: {
      contained: {
        fontWeight: 600,
      },
      outlined: {
        fontWeight: 600,
      },
      text: {
        fontWeight: 600,
      },
    },
    MuiPickersDay: {
      day: {
        fontWeight: 400,
        '&:focus': {
          '&$daySelected': {
            backgroundColor: secondaryColor,
          },
        },
      },
      daySelected: {
        fontWeight: 400,
        backgroundColor: secondaryColor,
        '&:hover': {
          backgroundColor: secondaryColor,
        },
        '&:focus': {
          backgroundColor: secondaryColor,
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontSize: '0.75rem',
      },
    },
  },
} as any);

export default theme;
