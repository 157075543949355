import React, { FunctionComponent, useState, useEffect } from 'react';
import { useGoogleMap } from '@ubilabs/google-maps-react-hooks';
import { useSelector, useDispatch } from 'react-redux';

import { isSameDay } from 'date-fns';

import { RootState } from '../../reducers';

import MapMarkerClusterer from './map-marker-clusterer';

import getMarkerIcon, { PlaceVisitMarkerIconType } from './place-visit-marker-icon';
import { setTimelineDate, setSelectedTimelineEntry } from '../../actions/timeline-actions';
import { selectTimelineEntryIdsBySelectedDate, selectPlaceVisits } from '../../selectors/timeline';
import { trimAddress } from '../../utils/format-address';

/**
 * Markers for the placeVisits from the timeline
 */
const Markers: FunctionComponent = () => {
  const { map } = useGoogleMap();
  const dispatch = useDispatch();
  const [markers, setMarkers] = useState<{ [_id: string]: google.maps.Marker }>({});
  const timelineEntryIdsOfSelectedDate = useSelector(selectTimelineEntryIdsBySelectedDate);
  const placeVisits = useSelector(selectPlaceVisits);
  const { selectedDate, selectedEntryId, editingEntryId, predefinedPlaces } = useSelector((state: RootState) => ({
    selectedDate: state.timeline.selectedDate,
    selectedEntryId: state.timeline.selectedEntry?.id,
    editingEntryId: state.timeline.editingEntry?.id,
    predefinedPlaces: state.predefinedPlaces,
  }));

  // Set the marker as editing when the marker gets clicked
  const handleMarkerClick = (id: string): void => {
    const placeVisit = placeVisits.find((timelineEntry) => timelineEntry.id === id);

    if (placeVisit) {
      if (!selectedDate || !isSameDay(placeVisit.time.start, selectedDate)) {
        dispatch(setTimelineDate(placeVisit.time.start));
      }
      dispatch(setSelectedTimelineEntry(placeVisit));
    }
  };

  // Updates markers for placeVisits from the timeline
  useEffect(() => {
    if (!map) {
      return;
    }

    const newMarkers = { ...markers };

    // remove old markers
    const placeVisitIds = placeVisits.map(({ id }) => id);
    const markerIdsToRemove = Object.keys(markers).filter((id) => !placeVisitIds.includes(id));
    markerIdsToRemove.forEach((id) => {
      const marker = newMarkers[id];
      marker.setMap(null);
      google.maps.event.clearListeners(marker, 'click');
      delete newMarkers[id];
    });

    placeVisits.forEach(({ id, location, address }) => {
      const position: google.maps.LatLngLiteral = {
        lat: location.latitude,
        lng: location.longitude,
      };
      const isEditing = id === editingEntryId;
      const ofSelectedDate = timelineEntryIdsOfSelectedDate.includes(id);
      const selectedType = selectedEntryId === id ? 'selected' : 'ofSelectedDate';
      const iconType: PlaceVisitMarkerIconType = ofSelectedDate ? selectedType : 'default';
      const zIndex = ofSelectedDate ? 1 : 0;
      const predefinedPlaceType = predefinedPlaces.find(
        (predefinedPlace) =>
          predefinedPlace.place?.address &&
          address &&
          trimAddress(predefinedPlace.place.address) === trimAddress(address),
      )?.type;

      // add new markers
      if (!newMarkers[id] && !isEditing) {
        const markerOptions: google.maps.MarkerOptions = {
          map,
          position,
          optimized: false,
          icon: getMarkerIcon(iconType, predefinedPlaceType),
          draggable: false,
          clickable: true,
          zIndex,
        };
        const marker = new google.maps.Marker(markerOptions);
        marker.set('iconType', iconType);
        marker.set('predefinedPlaceType', predefinedPlaceType);
        marker.set('id', id);
        marker.addListener('click', () => handleMarkerClick(id));

        newMarkers[id] = marker;
        return;
      }

      // update already existing markers
      newMarkers[id].setMap(map);
      newMarkers[id].setVisible(!isEditing); // We show an edit marker with drag & drop when editing
      newMarkers[id].setPosition(position);
      newMarkers[id].setZIndex(zIndex);
      google.maps.event.clearListeners(newMarkers[id], 'click');
      newMarkers[id].addListener('click', () => handleMarkerClick(id));
      if (
        newMarkers[id].get('iconType') !== iconType ||
        newMarkers[id].get('predefinedPlaceType') !== predefinedPlaceType
      ) {
        newMarkers[id].setIcon(getMarkerIcon(iconType, predefinedPlaceType));
        newMarkers[id].set('iconType', iconType);
        newMarkers[id].set('predefinedPlaceType', predefinedPlaceType);
      }
    });

    setMarkers(newMarkers);
  }, [map, placeVisits, timelineEntryIdsOfSelectedDate, selectedEntryId, editingEntryId, predefinedPlaces]);

  // Removes markers from the map and removes marker event listeners
  // when component unmounts
  useEffect(
    () => (): void => {
      if (!map) {
        return;
      }

      Object.values(markers).forEach((marker) => {
        google.maps.event.clearListeners(marker, 'click');
        marker.setMap(null);
      });
    },
    [],
  );

  const markersOfSelectedDate = Object.keys(markers)
    .filter((id) => timelineEntryIdsOfSelectedDate.includes(id))
    .map((id) => markers[id]);
  const markersOfOtherDates = Object.values(markers).filter((marker) => !markersOfSelectedDate.includes(marker));

  return (
    <>
      <MapMarkerClusterer
        markers={markersOfOtherDates}
        isSelectedDateCluster={false}
        onClusterClick={handleMarkerClick}
      ></MapMarkerClusterer>
      <MapMarkerClusterer
        markers={markersOfSelectedDate}
        isSelectedDateCluster={true}
        onClusterClick={handleMarkerClick}
      ></MapMarkerClusterer>
    </>
  );
};

export default Markers;
