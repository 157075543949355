import ActionTypes from '../types/actions';
import { SET_PRIVACY_DIALOG_VISIBLE } from '../actions/privacy-dialog-actions';

/**
 * Handle the privacy dialog state
 */
function privacyDialogReducer(state = false, action: ActionTypes): boolean {
  switch (action.type) {
    case SET_PRIVACY_DIALOG_VISIBLE: {
      return action.payload.visible;
    }

    default:
      return state;
  }
}

export default privacyDialogReducer;
