import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Wave from '../../wave/wave';

import MapBackground from '../../../../assets/images/start-page/map.png';

import FlyingMarkerBox from '../../../../assets/images/help-page/flying-marker-box.svg';
import FlyingMarkerBoxMobile from '../../../../assets/images/help-page/flying-marker-box-mobile.svg';

import styles from './header-segment.css';

const HeaderSegment: FunctionComponent = () => {
  const { t } = useTranslation();

  const mapBackgroundImage = {
    backgroundImage: `url(${MapBackground})`,
  };

  return (
    <header>
      <div style={mapBackgroundImage} className={styles.layeredImg}>
        <Box display="flex" flexDirection="column" className={styles.headerContainer}>
          <Box display="flex" justifyContent="space-between" className={styles.heroContainer}>
            <Box display="flex" flexDirection="column" className={styles.heroTextContainer}>
              <Typography variant="h2" classes={{ h2: styles.subtitle }}>
                {t('helpPage.title')}
              </Typography>

              <p className={styles.content}>{t('helpPage.headerText')}</p>
            </Box>
          </Box>
        </Box>
        <div className={styles.hiddenOnMobile}>
          <Wave />
          <div className={styles.heroSvgContainer}>
            {/* container to size the svg image responsive in all browsers */}
            <div className={styles.svgFrame}>
              <FlyingMarkerBox
                className={styles.heroSvg}
                role="img"
                aria-labelledby="boxWithPoiMarkers boxWithPoiMarkersDesc"
              />
            </div>
          </div>
        </div>
        <div className={styles.hiddenOnDesktop}>
          <div className={styles.heroSvgContainer}>
            {/* container to size the svg image responsive in all browsers */}
            <div className={styles.svgFrame}>
              <FlyingMarkerBoxMobile
                className={styles.heroSvg}
                role="img"
                aria-labelledby="boxWithPoiMarkers boxWithPoiMarkersDesc"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

HeaderSegment.displayName = 'HeaderSegment';

export default HeaderSegment;
