import React, { FunctionComponent } from 'react';

import { ActivitySegmentStatusType } from '../../../types/activity-segment-status';

import clsx from 'clsx';
import styles from './activity-segment-line.css';

interface Props {
  type: 'short' | 'long' | 'default';
  status: ActivitySegmentStatusType;
}

/**
 * Displays the line of an activity segment
 */
const ActivitySegmentLine: FunctionComponent<Props> = ({ status, type }: Props) => {
  const statusIsActive = status === ActivitySegmentStatusType.Active;
  const isShortLine = type === 'short';
  const isLongLine = type === 'long';

  const classNames = clsx(styles.line, statusIsActive ? styles.dashedOrange : styles.dashedGrey);

  if (status === ActivitySegmentStatusType.None) {
    return null;
  }

  return <div className={clsx(classNames, isShortLine && styles.shortLine, isLongLine && styles.longLine)} />;
};

export default ActivitySegmentLine;
