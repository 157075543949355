import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';

import EditIcon from '@material-ui/icons/EditOutlined';

import ActivitySegmentType from '../activity-segment-type/activity-segment-type';
import ActivitySegmentLine from '../activity-segment-line/activity-segment-line';
import ContactPersonChips from '../../contacts/contact-person-chips';

import { RootState } from '../../../reducers';
import { getContactsByIds } from '../../../selectors/contacts';

import { setEditingTimelineEntry } from '../../../actions/timeline-actions';
import { ActivitySegmentStatusType } from '../../../types/activity-segment-status';
import ActivitySegment from '../../../types/activity-segment';

import styles from './activity-segment-view.css';

interface Props {
  activitySegment: ActivitySegment;
}

/**
 * Displays an activity segment to change or remove an activity segment
 */
const ActivitySegmentView: FunctionComponent<Props> = ({ activitySegment }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { comment, contacts } = activitySegment;
  const { isAlreadyEditing, contactsOfActivitySegment } = useSelector((state: RootState) => ({
    isAlreadyEditing: Boolean(state.timeline.editingEntry),
    contactsOfActivitySegment: getContactsByIds(state, contacts),
  }));
  const hasContacts = contactsOfActivitySegment.length > 0;
  const hasAdditionalInfo = Boolean(comment) || hasContacts;

  /**
   * Handle edit activity segment
   */
  const handleEditClick = (): void => {
    dispatch(setEditingTimelineEntry(activitySegment));
  };

  return (
    <>
      <Box component="div" display="flex" alignItems="flex-start" justifyContent="space-between">
        <ActivitySegmentType activitySegment={activitySegment} />
        <IconButton onClick={handleEditClick} disabled={isAlreadyEditing}>
          <EditIcon />
        </IconButton>
      </Box>

      <div className={styles.container}>
        <ActivitySegmentLine status={ActivitySegmentStatusType.Active} type={hasAdditionalInfo ? 'default' : 'short'} />

        {hasAdditionalInfo && (
          <Box component="div" display="flex" alignItems="center" className={styles.contentContainer}>
            {hasContacts && (
              <div className={styles.contentRow}>
                <ContactPersonChips contacts={contactsOfActivitySegment} />
              </div>
            )}
            {comment && (
              <div className={styles.contentRow}>
                <InputLabel shrink={true}>{t('placeVisit.notes')}</InputLabel>
                <Typography className={styles.contentRowText}>{comment}</Typography>
              </div>
            )}
          </Box>
        )}
      </div>
    </>
  );
};

export default ActivitySegmentView;
