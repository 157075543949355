import React, { FunctionComponent, Ref, useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import BorderedButton from '../../bordered-button/bordered-button';
import LanguageSelect from '../../language-select/language-select';
import Navigation from './navigation';
import Reference from '../reference';

import styles from './header.css';

interface Props {
  references: Map<Reference, Ref<HTMLDivElement>>;
}

const Header: FunctionComponent<Props> = ({ references }: Props) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showBackground, setShowBackground] = useState(false);
  const containerClasses = useMemo(() => clsx(styles.container, showBackground && styles.containerBackground), [
    showBackground,
  ]);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  useEffect(() => {
    const onScroll = (): void => setShowBackground(window.scrollY > 40);
    onScroll();
    window.addEventListener('scroll', onScroll);
    return (): void => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Box display="flex" justifyContent="space-between" className={containerClasses}>
      <Box display="flex">
        <Typography variant="h1" className={styles.title}>
          {t('app.title')}
        </Typography>
        <Hidden mdDown>
          <Navigation references={references} onLinkClick={closeDrawer} />
        </Hidden>
      </Box>
      <Box display="flex" alignItems="center">
        <LanguageSelect />

        <Hidden lgUp>
          <BorderedButton onClick={openDrawer} className={styles.menuButton}>
            <MenuIcon />
          </BorderedButton>

          <Drawer anchor="top" open={isDrawerOpen} onClose={closeDrawer} PaperProps={{ className: styles.drawer }}>
            <BorderedButton onClick={closeDrawer} className={styles.closeButton}>
              <CloseIcon />
            </BorderedButton>

            <Navigation references={references} onLinkClick={closeDrawer} />
          </Drawer>
        </Hidden>
      </Box>
    </Box>
  );
};

export default Header;
