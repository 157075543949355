import React, { FunctionComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';

import SymptomsDate from './configuration/symptoms-date/symptoms-date';
import SelectLocationHistory from './configuration/select-location-history/select-location-history';
import TimelineCalendar from './timeline-calendar/timeline-calendar';
import TimelineEntries from './timeline-entries/timeline-entries';
import ExportFooter from '../export-footer/export-footer';
import MenuTabs from '../select-view-menu/navigation-menu-tabs/navigation-menu-tabs';

import styles from './sidebar.css';

interface Props {
  onOpenOverlay: () => void;
}

const Sidebar: FunctionComponent<Props> = ({ onOpenOverlay }: Props) => {
  return (
    <Drawer
      className={styles.sidebar}
      variant="permanent"
      classes={{
        paper: styles.sidebar,
      }}
    >
      <MenuTabs />
      <Switch>
        <Route path="/configuration/symptoms-date">
          <SymptomsDate configurationSteps={2} currentConfigurationStep={0} />
        </Route>
        <Route path="/configuration/location-history">
          <SelectLocationHistory configurationSteps={2} currentConfigurationStep={1} onOpenOverlay={onOpenOverlay} />
        </Route>
        <Route path="/timeline">
          <>
            <TimelineCalendar />
            <TimelineEntries />
            <ExportFooter />
          </>
        </Route>
        <Redirect to="/configuration/symptoms-date" />
      </Switch>
    </Drawer>
  );
};

export default Sidebar;
