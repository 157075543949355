import { isEqual } from 'date-fns';
import { nanoid } from 'nanoid';

import ActionTypes from '../types/actions';
import {
  IMPORT_TIMELINE,
  SET_TIMELINE_DATE,
  ADD_TIMELINE_ENTRY,
  UPDATE_TIMELINE_ENTRY,
  REMOVE_TIMELINE_ENTRY,
  SET_SELECTED_TIMELINE_ENTRY,
  SET_EDITING_TIMELINE_ENTRY,
  UPDATE_EDITING_TIMELINE_ENTRY,
  SET_LOADING,
} from '../types/actions/timeline-actions';
import { ADD_CONTACT_PERSON, REMOVE_CONTACT_PERSON } from '../types/actions/contacts-actions';
import { SET_SYMPTOMS_DATE } from '../types/actions/incubation-time-actions';
import ActivitySegment from '../types/activity-segment';
import Timeline, { TimelineEntry, isPlaceVisit, isActivitySegment } from '../types/timeline';
import PlaceVisit from '../types/place-visit';
import Location from '../types/location';

import sortTimelineEntries from '../utils/sort-timeline-entries';
import { createIncubationTimeState } from '../utils/incubation-time';

export type TimelineState = {
  data: Timeline;
  selectedDate: Date | null;
  selectedEntry: Partial<TimelineEntry> | null;
  editingEntry: Partial<TimelineEntry> | null;
  isLoading: boolean;
};

const initialState = {
  data: [],
  selectedDate: null,
  selectedEntry: null,
  editingEntry: null,
  isLoading: false,
};

/**
 * Check whether to locations are the same
 */
const isSameLocation = (a: Location, b: Location): boolean => {
  return a.latitude === b.latitude && a.longitude === b.longitude;
};

/**
 *  Returns the new timeline state after an element has been added
 */
function addTimelineEntry(timeline: Timeline, entry: TimelineEntry): Timeline {
  const updatedSortedTimeline = [...timeline, entry].sort(sortTimelineEntries);

  if (isActivitySegment(entry)) {
    return updatedSortedTimeline;
  }

  const entryIndex = updatedSortedTimeline.findIndex(({ id }) => entry.id === id);
  const prevEntry = updatedSortedTimeline[entryIndex - 1];
  const nextEntry = updatedSortedTimeline[entryIndex + 1];

  if (prevEntry && isActivitySegment(prevEntry)) {
    prevEntry.time.end = entry.time.start;
    prevEntry.endLocation = entry.location;

    // if next entry is a place visit add activity segment terminating at its location
    if (nextEntry && isPlaceVisit(nextEntry)) {
      const trailingActivitySegment: ActivitySegment = {
        ...prevEntry,
        id: nanoid(),
        startLocation: entry.location,
        endLocation: nextEntry.location,
        time: {
          start: entry.time.end,
          end: nextEntry.time.start,
        },
      };
      return [...updatedSortedTimeline, trailingActivitySegment].sort(sortTimelineEntries);
    }
  }

  return updatedSortedTimeline;
}

/**
 *  Returns the new timeline state after an element has been removed
 */
function removeTimelineEntry(timeline: Timeline, id: string): Timeline {
  const updatedTimeline = [...timeline];
  const entryIndex = updatedTimeline.findIndex((entry) => entry.id === id);

  const nextEntry = updatedTimeline[entryIndex + 1];
  const prevEntry = updatedTimeline[entryIndex - 1];
  const isPreviousEntryActivitySegment = prevEntry && isActivitySegment(prevEntry);

  // if next entry is an activity segment update its start time and location
  if (isPreviousEntryActivitySegment && nextEntry) {
    const newEndLocation = isPlaceVisit(nextEntry) ? nextEntry.location : nextEntry.startLocation;
    const previousActivitySegment = prevEntry as ActivitySegment;

    previousActivitySegment.time.end = nextEntry.time.start;
    previousActivitySegment.endLocation = newEndLocation;
  }

  return updatedTimeline.filter((entry) => entry.id !== id);
}

/**
 *  Returns the new timeline state after an element has been updated
 */
function updateTimelineEntry(
  timeline: Timeline,
  id: string,
  updatedFields: Partial<PlaceVisit> | Partial<ActivitySegment>,
): Timeline {
  const updatingEntry = timeline.find((entry) => entry.id === id);

  const updatedTimeline = timeline
    .map((entry) => {
      if (entry.id === id) {
        return {
          ...entry,
          ...updatedFields,
        };
      }

      if (isActivitySegment(entry) && updatingEntry && isPlaceVisit(updatingEntry)) {
        const updatedActivitySegment = { ...entry };
        const locationToUpdate = (updatedFields as Partial<PlaceVisit>).location || false;

        if (isEqual(entry.time.start, updatingEntry.time.end)) {
          // When the activitySegment starts at the end of a placeVisit, update it, too.
          if (updatedFields.time) {
            updatedActivitySegment.time = { ...updatedActivitySegment.time, start: updatedFields.time.end };
          }

          // When the activitySegment has the same startLocation as the placeVisit at the same times, update it, too.
          if (locationToUpdate && isSameLocation(entry.startLocation, updatingEntry.location)) {
            updatedActivitySegment.startLocation = { ...locationToUpdate };
          }
        }

        if (isEqual(entry.time.end, updatingEntry.time.start)) {
          // When the activitySegment ends at the start of a placeVisit, update it, too.
          if (updatedFields.time) {
            updatedActivitySegment.time = { ...updatedActivitySegment.time, end: updatedFields.time.start };
          }

          // When the activitySegment has the same endLocation as the placeVisit at the same times, update it, too.
          if (locationToUpdate && isSameLocation(entry.endLocation, updatingEntry.location)) {
            updatedActivitySegment.endLocation = { ...locationToUpdate };
          }
        }

        return updatedActivitySegment;
      }

      return entry;
    })
    .sort(sortTimelineEntries);

  // Update ActivitySegments in timeline, because updated PlaceVisit may have changed order of entries.
  return updatedTimeline.map((timelineEntry) => {
    const entryIndex = updatedTimeline.findIndex((entry) => entry.id === timelineEntry.id);
    const leadingEntry = updatedTimeline[entryIndex - 1];
    const trailingEntry = updatedTimeline[entryIndex + 1];

    if (isActivitySegment(timelineEntry)) {
      let updatedActivitySegment = { ...timelineEntry };
      if (leadingEntry && isPlaceVisit(leadingEntry)) {
        updatedActivitySegment = {
          ...updatedActivitySegment,
          time: { ...updatedActivitySegment.time, start: leadingEntry.time.end },
          startLocation: leadingEntry.location,
        };
      }
      if (trailingEntry && isPlaceVisit(trailingEntry)) {
        updatedActivitySegment = {
          ...updatedActivitySegment,
          time: { ...updatedActivitySegment.time, end: trailingEntry.time.start },
          endLocation: trailingEntry.location,
        };
      }
      return updatedActivitySegment;
    }
    return timelineEntry;
  });
}

/**
 * Handle the timeline state
 */
function timelineReducer(state: TimelineState = initialState, action: ActionTypes): TimelineState {
  switch (action.type) {
    case IMPORT_TIMELINE: {
      if (action.payload instanceof Error) {
        return state;
      }
      return { ...state, data: action.payload.data.sort(sortTimelineEntries), editingEntry: initialState.editingEntry };
    }

    case SET_SYMPTOMS_DATE: {
      const { start } = createIncubationTimeState(action.payload.date);
      return { ...state, selectedDate: start };
    }

    case SET_TIMELINE_DATE: {
      return { ...state, selectedDate: action.payload.date, editingEntry: initialState.editingEntry };
    }

    case ADD_TIMELINE_ENTRY: {
      return { ...state, data: addTimelineEntry(state.data, action.payload.timelineEntry) };
    }

    case UPDATE_TIMELINE_ENTRY: {
      const { id, updatedFields } = action.payload;
      return {
        ...state,
        data: updateTimelineEntry(state.data, id, updatedFields),
      };
    }

    case REMOVE_TIMELINE_ENTRY: {
      const idToRemove = action.payload.id;
      return {
        ...state,
        data: removeTimelineEntry(state.data, idToRemove),
      };
    }

    case SET_SELECTED_TIMELINE_ENTRY: {
      const selectedEntry = action.payload.timelineEntry;
      return {
        ...state,
        editingEntry: selectedEntry ? null : state.editingEntry,
        selectedEntry,
      };
    }

    case SET_EDITING_TIMELINE_ENTRY: {
      const editingEntry = action.payload.timelineEntry;
      return {
        ...state,
        editingEntry,
        selectedEntry: editingEntry ? null : state.selectedEntry,
      };
    }

    case UPDATE_EDITING_TIMELINE_ENTRY: {
      if (!state.editingEntry) {
        return state;
      }

      return {
        ...state,
        editingEntry: {
          ...state.editingEntry,
          ...action.payload.updatedFields,
        },
      };
    }

    case ADD_CONTACT_PERSON: {
      if (!state.editingEntry) {
        return state;
      }

      const newContactId = action.payload.contactPerson.id;

      return {
        ...state,
        editingEntry: {
          ...state.editingEntry,
          contacts: state.editingEntry.contacts ? [...state.editingEntry.contacts, newContactId] : [newContactId],
        },
      };
    }

    case REMOVE_CONTACT_PERSON: {
      return {
        ...state,
        data: state.data.map((entry) => {
          return {
            ...entry,
            contacts: entry.contacts.filter((contactId) => contactId !== action.payload.id),
          };
        }),
      };
    }

    case SET_LOADING: {
      return { ...state, isLoading: action.payload.isLoading };
    }

    default:
      return state;
  }
}

export default timelineReducer;
