import MuliWoff2 from '../../assets/fonts/muli.woff2';
import MuliWoff from '../../assets/fonts/muli.woff';
import MuliItalicWoff2 from '../../assets/fonts/muli-italic.woff2';
import MuliItalicWoff from '../../assets/fonts/muli-italic.woff';

export const muli = {
  fontFamily: 'Muli',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '200 900',
  src: `
    local('Muli'),
    url(${MuliWoff2}) format('woff2'),
    url(${MuliWoff}) format('woff')
  `,
};

export const muliItalic = {
  fontFamily: 'Muli',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: '200 900',
  src: `
    local('Muli'),
    url(${MuliItalicWoff2}) format('woff2'),
    url(${MuliItalicWoff}) format('woff')
  `,
};
