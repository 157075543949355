import React, { useState, FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AUTH_SECRET_HASH } from '../../configs/main';

import sha256 from 'crypto-js/sha256';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import styles from './login-dialog.css';

interface Props {
  onAuthenticated(): void;
}

const LoginDialog: FunctionComponent<Props> = ({ onAuthenticated }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [helperText, setHelperText] = useState('');
  const [password, setPassword] = useState('');

  const isPasswordValid = useCallback(() => {
    const hashDigest = sha256(password);
    return hashDigest.toString() === AUTH_SECRET_HASH;
  }, [password]);

  return (
    <Dialog open fullWidth maxWidth="xs" aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('login.title')}</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <TextField
          margin="normal"
          id="name"
          label={t('login.password')}
          error={helperText != ''}
          helperText={helperText}
          type="password"
          onChange={(event): void => {
            setHelperText('');
            setPassword(event.currentTarget.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => {
            if (isPasswordValid()) {
              onAuthenticated();
            } else {
              setHelperText(t('login.wrongPassword'));
            }
          }}
          color="primary"
        >
          {t('login.login')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
