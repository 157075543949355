/**
 * All actions regarding predefined places
 */

export const SET_PREDEFINED_PLACE = 'predefinedPlaces/SET_PREDEFINED_PLACE';
import PredefinedPlace from '../predefined-place';

export interface SetPredefinedPlaceAction {
  type: typeof SET_PREDEFINED_PLACE;
  payload: {
    predefinedPlace: PredefinedPlace;
  };
}

type PredefinedPlaceActionTypes = SetPredefinedPlaceAction;

export default PredefinedPlaceActionTypes;
