/**
 * All actions regarding a case
 */

export const SET_CASE_ID = 'case/SET_CASE_ID';

export interface SetCaseIdAction {
  type: typeof SET_CASE_ID;
  payload: {
    id: string;
  };
}

type CaseActionTypes = SetCaseIdAction;

export default CaseActionTypes;
