import React, { FunctionComponent } from 'react';

import Person from '../../types/person';

import ContactPersonEditChip from './contact-person-edit-chip';
import ContactPersonChip from './contact-person-chip';

import Chip from '@material-ui/core/Chip';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';

import styles from './contact-person.css';

interface Props {
  contacts: Person[];
  getTagProps?: AutocompleteGetTagProps;
  isEdit?: boolean;
}

const ContactPersonChips: FunctionComponent<Props> = ({
  contacts,
  getTagProps,
  isEdit = false,
}: Props): JSX.Element => {
  const MAX_CHIPS = 2;
  return (
    <>
      {contacts.slice(0, MAX_CHIPS).map((contact: Person, index: number) => {
        if (isEdit && getTagProps) {
          return (
            <ContactPersonEditChip key={contact.id} contactPerson={contact} getTagProps={getTagProps} index={index} />
          );
        } else {
          return <ContactPersonChip key={contact.id} contactPerson={contact} />;
        }
      })}
      {contacts.length > MAX_CHIPS && (
        <Chip className={styles.contactEntry} label={'+' + (contacts.length - MAX_CHIPS)} />
      )}
    </>
  );
};

export default ContactPersonChips;
