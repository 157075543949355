import React, { FunctionComponent, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddActivitySegmentButton from '../add-activity-segment-button/add-activity-segment-button';
import ActivitySegmentTypeSelect from '../activity-segment-type-select/activity-segment-type-select';

import { addTimelineEntry, setEditingTimelineEntry } from '../../../actions/timeline-actions';

import ActivityType from '../../../types/activity-type';
import { TimelineEntryType, isActivitySegment, TimelineEntry } from '../../../types/timeline';
import ActivitySegment from '../../../types/activity-segment';
import Source from '../../../types/source';

import { RootState } from '../../../reducers';
import { nanoid } from 'nanoid';

interface Props {
  previousTimelineEntry: TimelineEntry;
  nextTimelineEntry: TimelineEntry;
}

/**
 * Component to add an activity segment
 */
const ActivitySegmentAdd: FunctionComponent<Props> = ({ previousTimelineEntry, nextTimelineEntry }: Props) => {
  const dispatch = useDispatch();

  const { selectedDate, editingEntry, editingEntryId } = useSelector((state: RootState) => ({
    selectedDate: state.timeline.selectedDate,
    editingEntry: state.timeline.editingEntry as Partial<ActivitySegment>,
    editingEntryId: state.timeline.editingEntry?.id,
  }));

  const bareActivitySegment = useMemo(
    () => ({
      type: TimelineEntryType.ActivitySegment,
      id: nanoid(),
      startLocation:
        previousTimelineEntry && isActivitySegment(previousTimelineEntry)
          ? previousTimelineEntry?.endLocation
          : previousTimelineEntry?.location,
      endLocation:
        nextTimelineEntry && isActivitySegment(nextTimelineEntry)
          ? nextTimelineEntry?.startLocation
          : nextTimelineEntry?.location,
      time: {
        start: previousTimelineEntry?.time.end ?? (selectedDate as Date),
        end: nextTimelineEntry?.time.start ?? (selectedDate as Date),
      },
    }),
    [selectedDate],
  );

  const addingIsDisabled = useMemo(() => {
    return editingEntryId ? editingEntryId !== bareActivitySegment.id : false;
  }, [editingEntryId, bareActivitySegment]);

  /**
   * Handle the selection menu
   */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = (): void => {
    setAnchorEl(null);
    dispatch(setEditingTimelineEntry(null));
  };

  /**
   * Save changes of an activity segment
   */
  const handleSave = (activitySegment: Omit<ActivitySegment, 'id'>): void => {
    dispatch(addTimelineEntry(activitySegment));
  };

  /**
   * Handle changes of an activity segment and the cancelling of an edit
   */
  const handleChange = (activity: ActivityType | null): void => {
    handleClose();

    if (!activity) {
      return;
    }

    handleSave({
      type: TimelineEntryType.ActivitySegment,
      startLocation: editingEntry?.startLocation,
      endLocation: editingEntry?.endLocation,
      time: {
        start: editingEntry?.time?.start,
        end: editingEntry?.time?.end,
      },
      activityType: activity,
      waypoints: [],
      source: Source.User,
      comment: '',
      contacts: [],
    } as Omit<ActivitySegment, 'id'>);
  };

  /**
   * Handle the start of an edit
   */
  const handleAddClick = (): void => {
    dispatch(setEditingTimelineEntry(bareActivitySegment));
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    handleAddClick();
  };

  return (
    <>
      <AddActivitySegmentButton disabled={addingIsDisabled} onClick={handleButtonClick} />
      <ActivitySegmentTypeSelect anchorEl={anchorEl} onSelect={handleChange} onClose={handleClose} />
    </>
  );
};

export default ActivitySegmentAdd;
