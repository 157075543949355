import { nanoid } from 'nanoid';
import formatAddress from './format-address';

import Timeline, { TimelineEntry, TimelineEntryType, isActivitySegment, isPlaceVisit } from '../types/timeline';
import GoogleLocationHistory, { PlaceVisit, ActivitySegment } from '../types/google-location-history';
import ActivityType from '../types/activity-type';
import Source from '../types/source';

/**
 * Multiplies a given number by 10E-7 to re-introduce missing decimal point
 *
 * @param coordinateE7 Integer coordinate in E7 format (w/o decimal point)
 */
const parseE7Coordinate = (coordinateE7: number): number => Number((coordinateE7 * 10 ** -7).toFixed(7));

/**
 * Checks whether a Google Takeout Location History entry is a place visit
 *
 * @param entry Google Takeout Location History entry
 */
const isLocationHistoryPlaceVisit = (entry: PlaceVisit | ActivitySegment): entry is PlaceVisit =>
  (entry as PlaceVisit).placeVisit !== undefined;

/**
 * Checks whether a Google Takeout Location History entry is an activity segment
 *
 * @param entry Google Takeout Location History entry
 */
const isLocationHistoryActivitySegment = (entry: PlaceVisit | ActivitySegment): entry is ActivitySegment =>
  (entry as ActivitySegment).activitySegment !== undefined;

/**
 * Filter function for Google Takeout Location History
 *
 * @param entry Google Takeout Location History entry
 */
const isRelevantType = (entry: PlaceVisit | ActivitySegment): boolean =>
  isLocationHistoryPlaceVisit(entry) || isLocationHistoryActivitySegment(entry);

/**
 * Reduce activity type to one of our relevant activities or null
 *
 * @param type Google Takeout Location History activity type
 */
export function reduceActivityType(type: string): ActivityType | null {
  switch (type) {
    case 'IN_BUS':
    case 'IN_SUBWAY':
    case 'IN_TRAIN':
    case 'IN_TRAM':
    case 'IN_FERRY':
    case 'FLYING':
      return ActivityType.PublicTransport;
    case 'IN_VEHICLE':
    case 'IN_PASSENGER_VEHICLE':
      return ActivityType.Car;
    case 'WALKING':
    case 'CYCLING':
    case 'RUNNING':
      return ActivityType.CyclingWalking;
    default:
      return null;
  }
}

/**
 * Transform a Google Takeout Location History place visit to our shape
 *
 * @param timelineEntry Place Visit entry in Google Takeout Location History
 */
function transformPlaceVisitShape(timelineEntry: PlaceVisit): TimelineEntry {
  const { placeVisit } = timelineEntry;
  const { duration, location } = placeVisit;

  return {
    type: TimelineEntryType.PlaceVisit,
    id: nanoid(),
    location: {
      latitude: location?.latitudeE7 && parseE7Coordinate(location?.latitudeE7),
      longitude: location?.longitudeE7 && parseE7Coordinate(location?.longitudeE7),
    },
    time: {
      start: new Date(Number(duration?.startTimestampMs)),
      end: new Date(Number(duration?.endTimestampMs)),
    },
    name: location?.name,
    placeId: location?.placeId,
    address: location?.address && formatAddress(location.address),
    confidenceStatus: placeVisit.placeConfidence,
    otherPlaceCandidates: placeVisit?.otherCandidateLocations?.map((candidate) => {
      return {
        location: {
          latitude: parseE7Coordinate(candidate.latitudeE7),
          longitude: parseE7Coordinate(candidate.longitudeE7),
        },
        placeId: candidate.placeId,
        confidence: candidate.locationConfidence,
      };
    }),
    source: Source.LocationHistory,
    contacts: [],
  };
}

/**
 * Transform a Google Takeout Location History activity segment to our shape
 *
 * @param timelineEntry Activity Segment entry in Google Takeout Location History
 */
function transformActivitySegmentShape(timelineEntry: ActivitySegment): TimelineEntry {
  const { activitySegment } = timelineEntry;
  const { duration, activityType, startLocation, endLocation } = activitySegment;
  const waypoints = activitySegment.waypointPath?.waypoints || [];
  return {
    type: TimelineEntryType.ActivitySegment,
    id: nanoid(),
    startLocation: {
      latitude: parseE7Coordinate(startLocation?.latitudeE7),
      longitude: parseE7Coordinate(startLocation?.longitudeE7),
    },
    endLocation: {
      latitude: parseE7Coordinate(endLocation?.latitudeE7),
      longitude: parseE7Coordinate(endLocation?.longitudeE7),
    },
    time: {
      start: new Date(Number(duration.startTimestampMs)),
      end: new Date(Number(duration.endTimestampMs)),
    },
    activityType: reduceActivityType(activityType),
    waypoints: waypoints.map(({ latE7, lngE7 }) => ({
      latitude: parseE7Coordinate(latE7),
      longitude: parseE7Coordinate(lngE7),
    })),
    source: Source.LocationHistory,
    contacts: [],
  };
}

/**
 * Asynchronously transform Google Takeout Location History shape to internal timeline shape
 *
 * @param timeline Content of monthly Google Location History objects
 */
function transformShape(timeline: GoogleLocationHistory): Promise<Timeline> {
  return new Promise((resolve) => {
    resolve(
      timeline
        .filter(isRelevantType)
        .map(
          (entry: PlaceVisit | ActivitySegment): TimelineEntry => {
            if (isLocationHistoryPlaceVisit(entry)) {
              return transformPlaceVisitShape(entry);
            } else {
              return transformActivitySegmentShape(entry);
            }
          },
        )
        // Remove those that have no activity type
        .filter((entry) => !(isActivitySegment(entry) && !entry.activityType))
        // Remove Place visits without a Location
        .filter((entry) => !(isPlaceVisit(entry) && (!entry.location.latitude || !entry.location.longitude))),
    );
  });
}

export default transformShape;
