import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import Chip from '@material-ui/core/Chip';

import Person from '../../types/person';
import styles from './contact-person.css';

interface Props {
  contactPerson: Person;
}

/**
 * Component to display a contact person chip
 */
const ContactPersonChip: FunctionComponent<Props> = ({ contactPerson }: Props) => (
  <Chip
    component="span"
    className={clsx(styles.contactEntry, styles.savedContactEntry)}
    classes={{
      root: styles.contactEntry,
      deleteIcon: styles.hideContactEntryDeleteIcon,
    }}
    key={contactPerson?.id}
    label={`${contactPerson?.givenName} ${contactPerson?.familyName}`}
  />
);

export default ContactPersonChip;
