import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import HelpPageStep from '../help-page-step/help-page-step';

import styles from './visit-takeout-website-section.css';

const VisitTakeoutWebsiteSection: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box component="section" display="flex" flexDirection="column" className={styles.container}>
      <HelpPageStep
        step={1}
        title={t('helpPage.visitTakeoutWebsite.title')}
        description={t('helpPage.visitTakeoutWebsite.description')}
      />
      <div className={styles.linkContainer}>
        <Link
          target="_blank"
          href="https://takeout.google.com/settings/takeout/custom/location_history"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {t('helpPage.visitTakeoutWebsite.button')}
        </Link>
        <Typography className={styles.copyLinkText}>{t('helpPage.visitTakeoutWebsite.copyLinkText')}</Typography>
        <Link
          component="p"
          variant="body2"
        >{`https://takeout.google.com/settings/takeout/custom/location_history`}</Link>
      </div>
    </Box>
  );
};

export default VisitTakeoutWebsiteSection;
