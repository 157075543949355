import { useLocation } from 'react-router-dom';

/**
 * Custom hook to return search parameters from the current URL location
 */

const useQueryString = (): Record<string, string> => {
  const searchParams = new URLSearchParams(useLocation().search);
  const result: Record<string, string> = {};
  for (const [key, value] of searchParams) {
    result[key] = value;
  }
  return result;
};

export default useQueryString;
