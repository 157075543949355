import React, { FunctionComponent } from 'react';
import PoiMarkerSvg from '../../../../../assets/images/start-page/poi-marker.svg';

import styles from './poi-marker.css';

const PoiMarker: FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <PoiMarkerSvg />
    </div>
  );
};

export default PoiMarker;
