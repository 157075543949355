import { FunctionComponent, useState, useEffect } from 'react';
import { useGoogleMap } from '@ubilabs/google-maps-react-hooks';
import { useSelector } from 'react-redux';

import { selectTimelineEntriesBySelectedDate } from '../../selectors/timeline';
import { isActivitySegment } from '../../types/timeline';
import { polylineOptions } from '../../configs/map';

/**
 * Activity segments on the map
 */
const ActivitySegments: FunctionComponent = () => {
  const { map } = useGoogleMap();
  const [polylines, setPolylines] = useState<{ [_id: string]: google.maps.Polyline }>({});
  const timelineEntries = useSelector(selectTimelineEntriesBySelectedDate);
  const activitySegments = timelineEntries.filter(isActivitySegment);

  // Put the activity segment polylines on the map
  useEffect(() => {
    if (!map) {
      return;
    }

    const updatedPolylines = { ...polylines };

    // Remove obsolete polylines
    const activitySegmentIds = activitySegments.map(({ id }) => id);
    Object.keys(updatedPolylines)
      .filter((id) => !activitySegmentIds.includes(id))
      .forEach((id) => {
        updatedPolylines[id].setMap(null);
        delete updatedPolylines[id];
      });

    // Update existing polylines / Add new polylines
    activitySegments.forEach(({ id, waypoints, startLocation, endLocation }) => {
      const path = [startLocation, ...waypoints, endLocation].map(
        ({ latitude, longitude }) => new google.maps.LatLng(latitude, longitude),
      );

      if (updatedPolylines[id]) {
        updatedPolylines[id].setPath(path);
      } else {
        const polyline = new google.maps.Polyline({
          ...polylineOptions,
          path,
        });

        polyline.setMap(map);
        updatedPolylines[id] = polyline;
      }
    });

    setPolylines(updatedPolylines);
  }, [map, timelineEntries]);

  // Cleanup polylines when component unmounts
  useEffect(
    () => (): void => {
      if (!map) {
        return;
      }

      Object.values(polylines).forEach((polyline) => {
        polyline.setMap(null);
      });
    },
    [],
  );

  return null;
};

export default ActivitySegments;
