import React, { FunctionComponent } from 'react';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface DirectProps {
  dialogTitle: string;
  dialogContentText?: string | string[];
  agree: {
    onAgree: () => void;
    text: string;
  };
  disagree?: {
    onDisagree: () => void;
    text: string;
  };
  handleClose: () => void;
  open: boolean;
  children?: React.ReactNode;
}

type Props = DirectProps & DialogProps;

/**
 * Component opening a dialog to confirm something
 */
const ConfirmDialog: FunctionComponent<Props> = ({
  agree,
  disagree,
  dialogTitle,
  dialogContentText,
  handleClose,
  open: isOpen = false,
  children,
  ...rest
}: Props) => {
  return (
    <Dialog
      {...rest}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      {children ? (
        children
      ) : (
        <DialogContent>
          {Array.isArray(dialogContentText) ? (
            dialogContentText.map((paragraph, index) => (
              <DialogContentText key={`paragraph-${index}`} id={`alert-dialog-description-${index}`}>
                {paragraph}
              </DialogContentText>
            ))
          ) : (
            <DialogContentText id="alert-dialog-description">{dialogContentText}</DialogContentText>
          )}
        </DialogContent>
      )}
      <DialogActions>
        {Boolean(disagree) && (
          <Button onClick={disagree?.onDisagree} color="secondary">
            {disagree?.text}
          </Button>
        )}
        <Button onClick={agree.onAgree} color="primary" autoFocus>
          {agree.text}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
