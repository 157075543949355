import ActionTypes from '../types/actions';
import { ADD_CONTACT_PERSON, UPDATE_CONTACT_PERSON, REMOVE_CONTACT_PERSON } from '../types/actions/contacts-actions';
import Contacts from '../types/contacts';

export type ContactsState = Contacts;

const initialState: ContactsState = [];

/**
 * Handle the contacts state
 */
function contactsReducer(state: ContactsState = initialState, action: ActionTypes): ContactsState {
  switch (action.type) {
    case ADD_CONTACT_PERSON: {
      return [...state, action.payload.contactPerson];
    }

    case UPDATE_CONTACT_PERSON: {
      const { id, updatedFields } = action.payload;
      return state.map((contactPerson) => {
        if (contactPerson.id === id) {
          return {
            ...contactPerson,
            ...updatedFields,
          };
        }

        return contactPerson;
      });
    }

    case REMOVE_CONTACT_PERSON: {
      const idToRemove = action.payload.id;
      return state.filter(({ id }) => id !== idToRemove);
    }

    default:
      return state;
  }
}

export default contactsReducer;
