import { nanoid } from 'nanoid';

import * as actionTypes from '../types/actions/notifications-actions';
import Notification from '../types/notification';

/**
 * Add a notification
 */
export function addNotification(notification: Omit<Notification, 'id'>): actionTypes.AddNotificationAction {
  return {
    type: actionTypes.ADD_NOTIFICATION,
    payload: {
      notification: {
        ...notification,
        id: nanoid(),
      },
    },
  };
}

/**
 * Remove a notification
 */
export function removeNotification(id: string): actionTypes.RemoveNotificationAction {
  return {
    type: actionTypes.REMOVE_NOTIFICATION,
    payload: { id },
  };
}
