import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';

import styles from './bordered-button.css';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
}

/**
 * A square button with a white border
 */
const BorderedButton: FunctionComponent<Props> = ({ children, onClick, className }: Props) => {
  return (
    <Button className={clsx(styles.button, className)} variant="outlined" onClick={onClick}>
      {children}
    </Button>
  );
};

export default BorderedButton;
