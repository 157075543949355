import React, { FunctionComponent } from 'react';

import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './info-page.css';

interface Props {
  onClose?: () => void;
  content: JSX.Element | string;
}

const InfoPage: FunctionComponent<Props> = ({ onClose, content }: Props) => {
  return (
    <Box display="flex" flexDirection="column" className={styles.container}>
      <header className={styles.header}>
        <IconButton className={styles.closeButton} onClick={(): void => onClose && onClose()} aria-label="close">
          <CloseIcon />
        </IconButton>
      </header>
      <p className={styles.content}>{content}</p>
    </Box>
  );
};

export default InfoPage;
