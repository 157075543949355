import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import LanguageSelect from '../../language-select/language-select';
import Navigation from '../../header/navigation';

import styles from './header.css';

const Header: FunctionComponent = () => {
  const { t } = useTranslation();
  const [showBackground, setShowBackground] = useState(false);
  const containerClasses = useMemo(() => clsx(styles.container, showBackground && styles.containerBackground), [
    showBackground,
  ]);

  useEffect(() => {
    const onScroll = () => setShowBackground(window.scrollY > 40);
    onScroll();
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Box display="flex" justifyContent="space-between" className={containerClasses}>
      <Link to="/configuration/symptoms-date" className={styles.link}>
        <ArrowRightAltIcon className={styles.arrowIcon} />
        <Typography variant="h2">{t('helpPage.backToApplication')}</Typography>
      </Link>
      <div>
        <LanguageSelect />
        <Navigation />
      </div>
    </Box>
  );
};

export default Header;
